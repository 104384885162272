const openToTravelOptions = [
  {
    name: "0",

    label: "Não Especificado",
  },

  {
    name: "1",

    label: "0%",
  },

  {
    name: "2",

    label: "25%",
  },

  {
    name: "3",

    label: "50%",
  },

  {
    name: "4",

    label: "75%",
  },

  {
    name: "5",

    label: "100%",
  },
];

const educationLevelOptions = [
  {
    name: "-1",

    label: "Indefinido",
  },

  {
    name: "0",

    label: "Menos que escola",
  },

  {
    name: "1",

    label: "Diploma do ensino médio ou equivalente",
  },

  {
    name: "2",

    label: "Um pouco de faculdade, sem diploma",
  },

  {
    name: "3",

    label: "Prêmio pós-secundário sem título",
  },

  {
    name: "4",

    label: "título de associado",
  },

  {
    name: "5",

    label: "Bacharelado",
  },

  {
    name: "6",

    label: "Maestria",
  },

  {
    name: "7",

    label: "PhD ou título profissional",
  },
];

const genderOptions = [
  {
    name: "0",

    label: "Feminino",
  },

  {
    name: "1",

    label: "Masculino",
  },

  {
    name: "3",

    label: "Outro",
  },

  {
    name: "4",

    label: "Prefiro não dizer",
  },
];

const desideredEmploymentTypeOptions = [
  {
    name: "0",

    label: "Não especificado",
  },

  {
    name: "1",

    label: "Estágio",
  },

  {
    name: "2",

    label: "Contrato",
  },

  {
    name: "3",

    label: "Permanente",
  },

  {
    name: "4",

    label: "Contrato para Permanente",
  },

  {
    name: "5",

    label: "Consultor (1099)",
  },

  {
    name: "6",

    label: " Nível Básico",
  },

  {
    name: "7",

    label: "Tempo integral",
  },

  {
    name: "8",

    label: "Concertado",
  },

  {
    name: "9",

    label: "Temporário (por exemplo, 15 dias, dois meses, um ano)",
  },

  {
    name: "10",

    label: "Eventual (Serviços ou Trabalho, Eventual, Substituição)",
  },
];

const industryOptions = [
  { name: "Contabilidade", label: "Finanças" },

  { name: "Acomodação", label: "Acomodação" },

  { name: "Administração da Justiça", label: "Administração da Justiça" },

  {
    name: "Serviços administrativos e de apoio",

    label: "Serviços administrativos e de apoio",
  },

  { name: "Serviços de Publicidade", label: "Serviços de publicidade" },

  { name: "Agricultura", label: "Agricultura" },

  {
    name: "Gestão do Programa de Ar, Água e Resíduos",

    label: "Programas de gestão de ar, água e resíduos",
  },

  { name: "Companhias Aéreas/Aviação", label: "Companhias Aéreas/Aviação" },

  {
    name: "Resolução Alternativa de Litígios",

    label: "Resolução Alternativa de Conflitos",
  },

  { name: "Medicina Alternativa Medicine", label: "Medicina Alternativa" },

  { name: "Serviços de Ambulância", label: "Serviços de Ambulância" },

  {
    name: "Parques de diversões e fliperamas",

    label: "Parques de diversões e fliperamas ",
  },

  { name: "Animação", label: "Animação" },

  { name: "Vestuário e Moda", label: "Roupas e Moda" },

  { name: "Fabricação de Vestuário", label: "Fabricação de Vestuário" },

  { name: "Arquitetura e Planejamento", label: "Arquitetura e Planejamento" },

  { name: "Forças Armadas", label: "Forças Armadas" },

  { name: "Artes e Ofícios", label: "Artes e Ofícios" },

  { name: "Artistas e Escritores", label: "Artistas e Escritores" },

  { name: "Automotivo", label: "Automotivo" },

  { name: "Aviação e Aeroespacial", label: "Aviação e Aeroespacial" },

  { name: "Banco", label: "Banco" },

  {
    name: "Fabricação de Produtos de Panificação",

    label: "Fabricação de Produtos de Panificação",
  },

  {
    name: "Bares, Tabernas e Discotecas",

    label: "Bares, Tabernas e Discotecas",
  },

  {
    name: "Bed-and-Breakfasts, Hostels, Homestays",

    label: "Bed-and-Breakfasts, Hostels, Homestays",
  },

  { name: "Fabricação de bebidas", label: "Fabricação de bebidas" },

  {
    name: "Geração de Energia Elétrica a Biomassa",

    label: "Geração de Energia Elétrica a Biomassa",
  },

  { name: "Biotecnologia", label: "Biotecnologia" },

  { name: "Blockchain Services", label: "Blockchain Services" },

  { name: "Blogs", label: "Blogs" },

  {
    name: "Fabricação de Caldeiras, Tanques e Contêineres",

    label: "Fabricação de Caldeiras, Tanques e Contêineres",
  },

  {
    name: "Publicação de Livros e Periódicos",

    label: "Edição de livros e jornais",
  },

  { name: "Cervejarias", label: "Cervejarias" },

  { name: "Broadcast Media", label: "Broadcast Media" },

  { name: "Construção de edifícios", label: "Construção de edifícios" },

  { name: "Materiais de construção", label: "Materiais de construção" },

  {
    name: "Consultoria e Serviços Empresariais",

    label: "Consultoria e Serviços Empresariais",
  },

  { name: "Conteúdo comercial", label: "Conteúdo comercial" },

  {
    name: "Plataformas de Business Intelligence",

    label: "Plataformas de Business Intelligence",
  },

  {
    name: "Suprimentos e Equipamentos Comerciais",

    label: "Suprimentos e Equipamentos Comerciais",
  },

  {
    name: "Programação de Cabo e Satélite",

    label: "Programação de Cabo e Satélite",
  },

  { name: "Mercado de Capitais", label: "Mercado de Capitais" },

  { name: "Serviços de bufê", label: "Serviços de bufê" },

  { name: "Produtos Químicos", label: "Produtos Químicos" },

  { name: "Serviços de creche infantil", label: "Serviços de creche infantil" },

  { name: "Quiropráticas", label: "Quiropráticas" },

  { name: "Circos e shows de mágica", label: "Circos e shows de mágica" },

  {
    name: "Ajuste de Sinistros, Serviços Atuariais",

    label: " Ajuste de Sinistros, Serviços Atuariais",
  },

  {
    name: "Fabricação de Argila e Produtos Refratários",

    label: "Fabricação de Argila e Produtos Refratários",
  },

  { name: "Organização Cívica e Social", label: "Organização Cívica e Social" },

  { name: "Engenharia Civil", label: "Engenharia Civil" },

  { name: "Mineração de carvão", label: "Mineração de carvão" },

  { name: "Agências de cobrança", label: "Agências de cobrança" },

  { name: "Imóveis Comerciais", label: "Imóveis Comerciais" },

  {
    name: "Aluguel de Equipamentos Comerciais e Industriais",

    label: "Aluguel de Equipamentos Comerciais e Industriais",
  },

  {
    name: "Manutenção de Máquinas Comerciais e Industriais",

    label: "Manutenção de Máquinas Comerciais e Industriais",
  },

  {
    name: "Desenvolvimento Comunitário e Planejamento Urbano",

    label: "Desenvolvimento Comunitário e Planejamento Urbano",
  },

  { name: "Serviços Comunitários", label: "Serviços Comunitários" },

  {
    name: "Segurança de computadores e redes",
    label: "Segurança de computadores e redes",
  },

  { name: "Jogos de Computador", label: "Jogos de Computador" },

  { name: "Hardware do Computador", label: "Hardware do Computador" },

  {
    name: "Fabricação do Hardware do Computador",

    label: "Fabricação do Hardware do Computador",
  },

  { name: "Networking de Computador", label: "Networking de Computador" },

  {
    name: "Produtos de Networking de Computador",

    label: "Produtos de Networking de Computador",
  },

  { name: "Software de Computador", label: "Software de Computador" },

  {
    name: "Manufatura de Computadores e Eletrônicos",

    label: "Manufatura de Computadores e Eletrônicos",
  },

  { name: "Programas de Conservação", label: "Programas de Conservação" },

  { name: "Construção", label: "Construção" },

  {
    name: "Fabricação de Hardware para Construção ",

    label: "Fabricação de Hardware para Construção",
  },

  { name: "Eletrônicos de Consumo", label: "Eletrônicos de Consumo" },

  { name: "Bens de Consumo", label: "Bens de Consumo" },

  { name: "Serviços ao Consumidor", label: "Serviços ao Consumidor" },

  { name: "Instituições Correcionais", label: "Instituições Correcionais" },

  { name: "Cosméticos", label: "Cosméticos" },

  { name: "Tribunais", label: "Tribunais" },

  { name: "Intermediação de Crédito", label: "Intermediação de Crédito" },

  { name: "Laticínios", label: "Laticínios" },

  { name: "Companhias de Dança", label: "Companhias de Dança" },

  {
    name: "Infraestrutura de dados e análises",

    label: "Infraestrutura de dados e análises",
  },

  {
    name: "Produtos de software de segurança de dados",

    label: "Produtos de software de segurança de dados",
  },

  { name: "Defesa e Espaço", label: "Defesa e Espaço" },

  { name: "Dentistas", label: "Dentistas" },

  { name: "Design", label: "Design" },

  {
    name: "Produtos de software de computação de desktop",

    label: "Produtos de software de computação de desktop",
  },

  { name: "Destilarias", label: "Destilarias" },

  { name: "E-learning", label: "E-learning" },

  { name: "Gestão Educacional", label: "Gestão Educacional" },

  {
    name: "Manufatura Elétrica e Eletrônica",

    label: "Manufatura Elétrica e Eletrônica",
  },

  { name: "Entretenimento", label: "Entretenimento" },

  {
    name: "Serviços de aluguel de equipamentos",
    label: "Serviços de aluguel de equipamentos",
  },

  { name: "Serviços Ambientais", label: "Serviços Ambientais" },

  { name: "Serviços de Eventos", label: "Serviços de Eventos" },

  { name: "Escritório Executivo", label: "Escritório Executivo" },

  {
    name: "Serviços de Pesquisa Executiva",
    label: "Serviços de Pesquisa Executiva",
  },

  {
    name: "Produtos de metal fabricados",
    label: "Produtos de metal fabricados",
  },

  { name: "Serviços de Instalações", label: "Serviços de Instalações" },

  {
    name: "Centros de Planejamento Familiar",
    label: "Centros de Planejamento Familiar",
  },

  { name: "Agricultura", label: "Agricultura" },

  {
    name: "Agricultura, Pecuária, Silvicultura",
    label: "Agricultura, Pecuária, Silvicultura",
  },

  {
    name: "Fabricação de Acessórios de Moda",

    label: "Fabricação de Acessórios de Moda",
  },

  { name: "Serviços Financeiros", label: "Serviços Financeiros" },

  { name: "Proteção contra incêndio", label: "Proteção contra incêndio" },

  { name: "Belas Artes", label: "Belas Artes" },

  { name: "Treinamento de Voo", label: "Treinamento de Voo" },

  { name: "Pesca", label: "Pesca" },

  { name: "Alimentos e Bebidas", label: "Alimentos e Bebidas" },

  { name: "Produção de Alimentos", label: "Produção de Alimentos" },

  { name: "Fabricação de Calçados", label: "Fabricação de Calçados" },

  {
    name: "Floresta e extração de madeira",
    label: "Floresta e extração de madeira",
  },

  {
    name: "Combustível Fóssil Geração de Energia Elétrica",

    label: "Combustível Fóssil Geração de Energia Elétrica",
  },

  {
    name: "Transporte de carga e pacotes",

    label: "Transporte de carga e pacotes",
  },

  {
    name: "Fabricação de Conservas de Frutas e Vegetais",

    label: "Fabricação de Conservas de Frutas e Vegetais",
  },

  { name: "Arrecadação de fundos", label: "Arrecadação de fundos" },

  { name: "Fundos e Fundos", label: "Fundos e Fundos" },

  { name: "Móveis", label: "Móveis" },

  { name: "Jogos de azar e cassinos", label: "Jogos de azar e cassinos" },

  {
    name: "Geração de Energia Elétrica Geotérmica",

    label: "Geração de Energia Elétrica Geotérmica",
  },

  { name: "Vidro, Cerâmica e Concreto", label: "Glass, Ceramics & Concrete" },

  {
    name: "Campos de Golfe & Country Clubs",

    label: "Campos de Golfe & Country Clubs",
  },

  { name: "Administração do Governo", label: "Administração do Governo" },

  { name: "Relações Governamentais", label: "Relações Governamentais" },

  { name: "Design Gráfico", label: "Design Gráfico" },

  {
    name: "Transporte Terrestre de Passageiros",

    label: "Transporte Terrestre de Passageiros",
  },

  {
    name: "Saúde, bem-estar e condicionamento físico",
    label: "Saúde, bem-estar e condicionamento físico",
  },

  { name: "Ensino Superior", label: "Ensino Superior" },

  {
    name: "Construção de Rodovias, Ruas e Pontes ",

    label: "Construção de Rodovias, Ruas e Pontes ",
  },

  { name: "Locais Históricos", label: "Locais Históricos" },

  { name: "Holding Companies", label: "Holding Companies" },

  { name: "Horticultura", label: "Horticultura" },

  { name: "Hospital & Health Care", label: "Hospital & Health Care" },

  {
    name: "Recursos humanos de hotelaria",
    label: "Recursos humanos de hospitalidade",
  },

  { name: "Hotéis e motéis", label: "Hotéis e motéis" },

  { name: "Serviços domésticos", label: "Serviços domésticos" },

  {
    name: "Habitação e Desenvolvimento Comunitário",

    label: "Habitação e Desenvolvimento Comunitário",
  },

  { name: "Programas de Habitação", label: "Programas de Habitação" },

  {
    name: "Geração de Energia Hidrelétrica",

    label: "Geração de Energia Hidrelétrica",
  },

  { name: "Importar e exportar", label: "Importar e exportar" },

  {
    name: "Serviços Individuais e Familiares",

    label: "Serviços Individuais e Familiares",
  },

  { name: "Automação Industrial", label: "Automação Industrial" },

  {
    name: "Fabricação de Máquinas Industriais",

    label: "Fabricação de Máquinas Industriais",
  },

  { name: "Associações da Indústria", label: "Associações da Indústria" },

  { name: "Serviços de Informação", label: "Serviços de Informação" },

  {
    name: "Tecnologia da Informação e Serviços",

    label: "Tecnologia da Informação e Serviços",
  },

  { name: "Seguros", label: "Seguros" },

  { name: "Design de interiores", label: "Design de interiores" },

  { name: "Assuntos Internacionais", label: "Assuntos Internacionais" },

  {
    name: "Comércio e Desenvolvimento Internacional",

    label: "Comércio e Desenvolvimento Internacional",
  },

  { name: "Internet", label: "Internet" },

  {
    name: "Serviços de Ônibus Interurbanos e Rurais",

    label: "Serviços de Ônibus Interurbanos e Rurais",
  },

  { name: "Assessoria de Investimento", label: "Assessoria de Investimento" },

  { name: "Banco de Investimento", label: "Banco de Investimento" },

  { name: "Gestão de Investimentos", label: "Gestão de Investimentos" },

  {
    name: "Serviços de TI e Consultoria de TI",
    label: "Serviços de TI e Consultoria de TI",
  },

  {
    name: "Desenvolvimento de software personalizado para sistemas de TI",

    label: "Desenvolvimento de software personalizado para sistemas de TI",
  },

  {
    name: "Serviços de dados do sistema de TI",
    label: "Serviços de dados do sistema de TI",
  },

  {
    name: "Serviços de design de sistemas de TI",
    label: "Serviços de design de sistemas de TI",
  },

  {
    name: "Instalação e descarte de sistemas de TI",

    label: "Instalação e descarte de sistemas de TI",
  },

  {
    name: "Operações e manutenção do sistema de TI",

    label: "Operações e manutenção do sistema de TI",
  },

  {
    name: "Teste e avaliação do sistema de TI",

    label: "Teste e avaliação do sistema de TI",
  },

  {
    name: "Treinamento e suporte do sistema de TI",

    label: "Treinamento e suporte do sistema de TI",
  },

  { name: "Serviços de limpeza", label: "Serviços de limpeza" },

  { name: " Judiciário", label: "Judiciário" },

  { name: "Serviços de paisagismo", label: "Serviços de paisagismo" },

  { name: "Escolas de idiomas", label: "Escolas de idiomas" },

  {
    name: "Serviços de Lavandaria e Limpeza a Seco",

    label: "Serviços de Lavandaria e Limpeza a Seco",
  },

  { name: "Aplicação da Lei", label: "Aplicação da Lei" },

  { name: "Prática Jurídica", label: "Prática Jurídica" },

  {
    name: "Locação de Imóveis Residenciais/Não Residenciais",

    label: "Locação de Imóveis Residenciais/Não Residenciais",
  },

  { name: "Serviços Jurídicos", label: "Serviços Jurídicos" },

  { name: "Escritório Legislativo", label: "Escritório Legislativo" },

  { name: "Lazer, Viagens e Turismo", label: "Lazer, Viagens e Turismo" },

  { name: "Bibliotecas", label: "Bibliotecas" },

  { name: "Corretores de Empréstimos", label: "Corretores de Empréstimos" },

  {
    name: "Logística e Cadeia de Suprimentos",
    label: "Logística e Cadeia de Suprimentos",
  },

  { name: "Artigos de luxo e joias", label: "Artigos de luxo e joias" },

  { name: "Máquinas", label: "Máquinas" },

  { name: "Consultoria de Gestão", label: "Consultoria de Gestão" },

  { name: "Fabricação", label: "Fabricação" },

  { name: "Marítimo", label: "Marítimo" },

  { name: "Pesquisa de Mercado", label: "Pesquisa de Mercado" },

  { name: "Marketing e Publicidade", label: "Marketing e Publicidade" },

  {
    name: "Engenharia Mecânica ou Industrial",

    label: "Engenharia Mecânica ou Industrial",
  },

  {
    name: "Produção de Mídia Produção Médica",

    label: "Produção de Mídia Produção Médica",
  },

  { name: "Mídia e Telecomunicações", label: "Mídia e Telecomunicações" },

  { name: "Dispositivo Médico", label: "Dispositivo Médico" },

  { name: "Prática Médica", label: "Prática Médica" },

  {
    name: "Laboratórios Médicos e de Diagnóstico",

    label: "Laboratórios Médicos e de Diagnóstico",
  },

  { name: "Cuidados de saúde mental", label: "Cuidados de saúde mental" },

  { name: "Tratamentos de Metal", label: "Tratamentos de Metal" },

  {
    name: "Fabricação de máquinas para usinagem de metais",

    label: "Fabricação de máquinas para usinagem de metais",
  },

  { name: "Militar", label: "Militar" },

  { name: "Mineração e Metais", label: "Mineração e Metais" },

  {
    name: "Produtos de Software de Computação Móvel",

    label: "Produtos de Software de Computação Móvel",
  },

  {
    name: "Serviços de alimentação móvel",
    label: "Serviços de alimentação móvel",
  },

  { name: "Jogos para celular", label: "Jogos para celular" },

  { name: "Motion Pictures & Film", label: "Motion Pictures & Film" },

  {
    name: "Fabricação de veículos motorizados",
    label: "Fabricação de veículos motorizados",
  },

  { name: "Museus & Instituições", label: "Museus & Instituições" },

  { name: "Música", label: "Música" },

  { name: "Nanotecnologia", label: "Nanotecnologia" },

  {
    name: "Extração e Distribuição de Gás Natural",

    label: "Extração e Distribuição de Gás Natural",
  },

  { name: "Jornais", label: "Jornais" },

  {
    name: "Mineração de minerais não metálicos",
    label: "Mineração de minerais não metálicos",
  },

  {
    name: "Gestão de organização sem fins lucrativos",

    label: "Gestão de organização sem fins lucrativos",
  },

  {
    name: "Construção de Edifícios Não Residenciais",

    label: "Construção de Edifícios Não Residenciais",
  },

  {
    name: "Geração de Energia Elétrica Nuclear",

    label: "Geração de Energia Elétrica Nuclear",
  },

  {
    name: "Lares de Enfermagem e Instalações de Cuidados Residenciais",

    label: "Lares de Enfermagem e Instalações de Cuidados Residenciais",
  },

  { name: "Administração do escritório", label: "Administração do escritório" },

  {
    name: "Fabricação de Móveis e Acessórios para Escritório",

    label: "Fabricação de Móveis e Acessórios para Escritório",
  },

  { name: "Petróleo e Energia", label: "Petróleo e Energia" },

  { name: "Mídia Online", label: "Mídia Online" },

  {
    name: "Varejo on-line e por correspondência",
    label: "Varejo on-line e por correspondência",
  },

  { name: "Consultoria de Operações", label: "Consultoria de Operações" },

  { name: "Optometristas", label: "Optometristas" },

  {
    name: "Centros de Atendimento Ambulatorial",
    label: "Centros de Atendimento Ambulatorial",
  },

  { name: "Outsourcing/offshoring", label: "Outsourcing/offshoring" },

  { name: "Pacote/Entrega de Frete", label: "Pacote/Entrega de Frete" },

  { name: "Embalagens e Contêineres", label: "Embalagens e Contêineres" },

  {
    name: "Fabricação de tintas, revestimentos e adesivos",

    label: "Fabricação de tintas, revestimentos e adesivos",
  },

  { name: "Papel e produtos florestais", label: "Papel e produtos florestais" },

  { name: "Fundos de Pensão", label: "Fundos de Pensão" },

  { name: "Artes Cênicas", label: "Artes Cênicas" },

  { name: "Publicação Periódica", label: "Publicação Periódica" },

  {
    name: "Serviços pessoais e de lavanderia",
    label: "Serviços pessoais e de lavanderia",
  },

  {
    name: "Fabricação de Produtos de Cuidados Pessoais",

    label: "Fabricação de Produtos de Cuidados Pessoais",
  },

  {
    name: "Serviços de cuidados pessoais",
    label: "Serviços de cuidados pessoais",
  },

  { name: "Serviços de Mascotas", label: "Serviços de Mascotas " },

  { name: "Farmacêuticos", label: "Farmacêuticos" },

  {
    name: "Fabricação Farmacêutica",

    label: "Fabricação Farmacêutica",
  },

  { name: "Filantropia", label: "Filantropia" },

  { name: "Fotografia", label: "Fotografia" },

  { name: "Médicos", label: " Médicos" },

  { name: "Transporte de dutos", label: "Transporte de dutos" },

  { name: "Plásticos", label: "Plásticos" },

  { name: "Organização Política", label: "Organização Política" },

  { name: "Serviços Postais", label: "Serviços Postais" },

  {
    name: "Manufatura de Metais Primários",
    label: "Manufatura de Metais Primários",
  },

  {
    name: "Educação Primária/Secundária",
    label: "Educação Primária/Secundária",
  },

  { name: "Imprimindo", label: "Imprimindo" },

  { name: "Organizações Profissionais", label: "Organizações Profissionais" },

  { name: "Serviços Profissionais", label: "Serviços Profissionais" },

  {
    name: "Formação & Coaching Profissional",

    label: "Formação & Coaching Profissional",
  },

  { name: "Desenvolvimento do Programa", label: "Desenvolvimento do Programa" },

  {
    name: "Programas de Assistência Pública",
    label: "Programas de Assistência Pública",
  },

  { name: "Saúde Pública", label: "Saúde Pública" },

  { name: "Política Pública", label: "Política Pública" },

  {
    name: "Relações Públicas e Comunicação",

    label: "Relações Públicas e Comunicação",
  },

  { name: "Segurança Pública", label: "Segurança Pública" },

  { name: "Publicação", label: "Publicação" },

  { name: "Pistas de corrida", label: "Pistas de corrida" },

  {
    name: "Transmissão de rádio e televisão",

    label: "Transmissão de rádio e televisão",
  },

  { name: "Fabricação Ferroviária", label: "Fabricação Ferroviária" },

  { name: "Fazenda", label: "Fazenda" },

  { name: "Imóveis", label: "Imóveis" },

  {
    name: "Instalações e Serviços Recreativos",

    label: "Instalações e Serviços Recreativos",
  },

  { name: "Instituições Religiosas", label: "Instituições Religiosas" },

  { name: "Renováveis e Meio Ambiente", label: "Renováveis Meio Ambiente" },

  { name: "Reparação e Manutenção", label: "Reparação e Manutenção" },

  { name: "Pesquisa", label: "Pesquisa" },

  {
    name: "Construção de Edifícios Residenciais",

    label: "Construção de Edifícios Residenciais",
  },

  { name: "Restaurantes", label: "Restaurantes" },

  { name: "Retail", label: "Retail" },

  {
    name: "Fabricação de Produtos de Borracha",

    label: "Fabricação de Produtos de Borracha",
  },

  {
    name: "Telecomunicações por Satélite",

    label: "Telecomunicações por Satélite",
  },

  { name: "Instituições de Poupança", label: "Instituições de Poupança" },

  {
    name: "Fabricação de Produtos do Mar",

    label: "Fabricação de Produtos do Mar",
  },

  { name: "Escolas de Secretariado", label: " Escolas de Secretariado" },

  {
    name: "Bolsas de Valores Mobiliários e Mercadorias",

    label: "Bolsas de Valores Mobiliários e Mercadorias",
  },

  {
    name: "Serviços de Segurança e Patrulha",

    label: "Serviços de Segurança e Patrulha",
  },

  {
    name: "Serviços de sistemas de segurança",
    label: "Serviços de sistemas de segurança",
  },

  { name: "Segurança e investigações", label: "Segurança e investigações" },

  { name: "Semicondutores", label: "Semicondutores" },

  {
    name: "Serviços para Idosos e Deficientes",

    label: "Serviços para Idosos e Deficientes",
  },

  { name: "Construção naval", label: "Construção naval" },

  {
    name: "Ônibus e serviços de transporte para necessidades especiais",

    label: "Ônibus e serviços de transporte para necessidades especiais",
  },

  {
    name: "Plataformas de redes sociais",
    label: "Plataformas de redes sociais",
  },

  { name: "Desenvolvimento de Software", label: "Desenvolvimento de Software" },

  {
    name: "Geração de Energia Elétrica Solar",

    label: "Geração de Energia Elétrica Solar",
  },

  { name: "Gravação de som", label: "Gravação de som" },

  {
    name: "Pesquisa e Tecnologia Espacial",
    label: "Pesquisa e Tecnologia Espacial",
  },

  {
    name: "Empreiteiros de Comércio Especializado",
    label: "Empreiteiros de Comércio Especializado",
  },

  { name: "Esportes para Espetadores", label: "Esportes para Espetadores" },

  { name: "Artigos Esportivos", label: "Artigos Esportivos" },

  { name: "Esportes", label: "Esportes" },

  {
    name: "Fabricação de produtos de mola e arame",

    label: "Fabricação de produtos de mola e arame",
  },

  { name: "Recrutamento e Seleção", label: "Recrutamento e Seleção" },

  {
    name: "Fornecimento de Vapor e Ar Condicionado",

    label: "Fornecimento de Vapor e Ar Condicionado",
  },

  {
    name: "Serviços de Gestão Estratégica",

    label: "Serviços de Gestão Estratégica",
  },

  { name: "Supermercados", label: "Supermercados" },

  {
    name: "Formação Técnica e Profissional",

    label: "Formação Técnica e Profissional",
  },

  {
    name: "Tecnologia, Informação, Media & Internet",

    label: "Tecnologia, Informação, Media & Internet",
  },

  { name: "Telecomunicações", label: "Telecomunicações" },

  {
    name: "Centrais de Atendimento Telefônico",
    label: "Centrais de Atendimento Telefônico",
  },

  {
    name: "Serviços de Ajuda Temporários",
    label: "Serviços de Ajuda Temporários",
  },

  { name: "Têxteis", label: "Têxteis" },

  { name: "Companhias de Teatro", label: "Companias de Teatro" },

  { name: "Think Tanks", label: "Think Tanks" },

  { name: "Tabaco", label: "Tabaco" },

  { name: "Tradução e localização", label: "Tradução e localização" },

  {
    name: "Transporte/Caminhões/Ferrovia",

    label: "Transporte/Caminhões/Ferrovia",
  },

  { name: "Arranjos de viagem", label: "Arranjos de viagem" },

  { name: "Transporte de Caminhão", label: "Transporte de Caminhão" },

  { name: "Fundos e Propiedades", label: "Fundos e Propiedades" },

  { name: "Serviços de Trânsito Urbano", label: "Serviços de Trânsito Urbano" },

  { name: "Utilitários", label: "Utilitários" },

  {
    name: "Reparação e Manutenção de Veículos",

    label: "Reparação e Manutenção de Veículos",
  },

  {
    name: "Venture Capital & Private Equity",

    label: "Venture Capital & Private Equity",
  },

  { name: "Veterinário", label: "Veterinário" },

  {
    name: "Serviços de Reabilitação Profissional",

    label: "Serviços de Reabilitação Profissional ",
  },

  { name: "Warehousing", label: "Warehousing" },

  { name: "Warehousing & Armazenamento", label: "Warehousing & Armazenamento" },

  {
    name: "Recolha, Tratamento e Eliminação de Resíduos",

    label: "Recolha, Tratamento e Eliminação de Resíduos",
  },

  {
    name: "Sistemas de Abastecimento de Água e Irrigação",

    label: "Sistemas de Abastecimento de Água e Irrigação",
  },

  {
    name: "Serviços de bem-estar e condicionamento físico",
    label: "Serviços de bem-estar e condicionamento físico",
  },

  { name: "Atacado", label: "Atacado" },

  {
    name: "Geração de Energia Elétrica Eólica",

    label: "Geração de Energia Elétrica Eólica",
  },

  { name: "Vinhos e Destilados", label: "Vinhos e Destilados" },

  { name: "Wireless", label: "Wireless" },

  {
    name: "Fabricação de produtos de madeira",
    label: "Fabricação de produtos de madeira",
  },

  { name: "Escrita e Edição ", label: "Escrita e Edição" },

  {
    name: "Zoológicos e jardins botânicos",
    label: "Zoológicos e jardins botânicos",
  },

  { name: "Outro", label: "Outro" },
];

const employmentTypeOptions = [
  {
    name: "0",

    label: "Tempo Integral",
  },

  {
    name: "1",

    label: "Tempo Parcial",
  },

  {
    name: "2",

    label: "Autônomo",
  },

  {
    name: "3",

    label: "Freelance",
  },

  {
    name: "4",

    label: "Contrato",
  },

  {
    name: "5",

    label: "Estágio",
  },

  {
    name: "6",

    label: "Aprendizagem",
  },

  {
    name: "7",

    label: "Sazonal",
  },
];

const languagesOptions = [
  {
    name: "Afrikaans",

    label: "Afrikaans",
  },

  {
    name: "Albanês",

    label: "Albanês",
  },

  {
    name: "Árabe",

    label: "Árabe",
  },

  {
    name: "Armenio",

    label: "Armenio",
  },

  {
    name: "Basco",

    label: "Basco",
  },

  {
    name: "Bengali",

    label: "Bengali",
  },

  {
    name: "Búlgaro",

    label: "Búlgaro",
  },

  {
    name: "Catalão",

    label: "Catalão",
  },

  {
    name: "Cambojano",

    label: "Cambojano",
  },

  {
    name: "Chinês",

    label: "Chinês",
  },

  {
    name: "Croata",

    label: "Croata",
  },

  {
    name: "Checa",

    label: "Checa",
  },

  {
    name: "Dinamarquês",

    label: "Dinamarquês",
  },

  {
    name: "Holandês",

    label: "Holandês",
  },

  {
    name: "Inglês",

    label: "Inglês",
  },

  {
    name: "Estoniano",

    label: "Estoniano",
  },

  {
    name: "Fiji",

    label: "Fiji",
  },

  {
    name: "Finlandês",

    label: "Finlandês",
  },

  {
    name: "Francês",

    label: "Francês",
  },

  {
    name: "Georgiano",

    label: "Georgiano",
  },

  {
    name: "Alemão",

    label: "Alemão",
  },

  {
    name: "Grego",

    label: "Grego",
  },

  {
    name: "Gujarati",

    label: "Gujarati",
  },

  {
    name: "Hebraico",

    label: "Hebraico",
  },

  {
    name: "Hindi",

    label: "Hindi",
  },

  {
    name: "Húngaro",

    label: "Húngaro",
  },

  {
    name: "Islandês",

    label: "Islandês",
  },

  {
    name: "Indonésio",

    label: "Indonésio",
  },

  {
    name: "Irlandês",

    label: "Irlandês",
  },

  {
    name: "Italiano",

    label: "Italiano",
  },

  {
    name: "Japonês",

    label: "Japonês",
  },

  {
    name: "Javanês",

    label: "Javanês",
  },

  {
    name: "Coreano",

    label: "Coreano",
  },

  {
    name: "Latino",

    label: "Latino",
  },

  {
    name: "Letão",

    label: "Letão",
  },

  {
    name: "Lituano",

    label: "Lituano",
  },

  {
    name: "Macedônio",

    label: "Macedônio",
  },

  {
    name: "Malaio",

    label: "Malaio",
  },

  {
    name: "Malaia",

    label: "Malaia",
  },

  {
    name: "Maltês",

    label: "Maltês",
  },

  {
    name: "Maori",

    label: "Maori",
  },

  {
    name: "Marati",

    label: "Marati",
  },

  {
    name: "Mongol",

    label: "Mongol",
  },

  {
    name: "Nepalês",

    label: "Nepalês",
  },

  {
    name: "Norueguês",

    label: "Norueguês",
  },

  {
    name: "Persa",

    label: "Persa",
  },

  {
    name: "Polonês",

    label: "Polonês",
  },

  {
    name: "Português",

    label: "Português",
  },

  {
    name: "Punjabi",

    label: "Punjabi",
  },

  {
    name: "Quechua",

    label: "Quechua",
  },

  {
    name: "Romeno",

    label: "Romeno",
  },

  {
    name: "Russo",

    label: "Russo",
  },

  {
    name: "Samoano",

    label: "Samoano",
  },

  {
    name: "Sérvio",

    label: "Sérvio",
  },

  {
    name: "Eslovaco",

    label: "Eslovaco",
  },

  {
    name: "Esloveno",

    label: "Esloveno",
  },

  {
    name: "Espanhol",

    label: "Espanhol",
  },

  {
    name: "Suaíli",

    label: "Suaíli",
  },

  {
    name: "Sueco",

    label: "Sueco",
  },

  {
    name: "Tamil",

    label: "Tamil",
  },

  {
    name: "Tártaro",

    label: "Tártaro",
  },

  {
    name: "Télugo",

    label: "Télugo",
  },

  {
    name: "Tailandês",

    label: "Tailandês",
  },

  {
    name: "Tibetano",

    label: "Tibetano",
  },

  {
    name: "Tonga",

    label: "Tonga",
  },

  {
    name: "Turco",

    label: "Turco",
  },

  {
    name: "Ucraniano",

    label: "Ucraniano",
  },

  {
    name: "Urdu",

    label: "Urdu",
  },

  {
    name: "Uzbeque",

    label: "Uzbeque",
  },

  {
    name: "Vietnamita",

    label: "Vietnamita",
  },

  {
    name: "Galês",

    label: "Galês",
  },

  {
    name: "Xhosa",

    label: "Xhosa",
  },
];

const numberOfEmployeesOptions = [
  {
    name: "1",

    label: "0 - 5",
  },

  {
    name: "2",

    label: "6 - 10",
  },

  {
    name: "3",

    label: "11 - 50",
  },

  {
    name: "4",

    label: "51 - 200",
  },

  {
    name: "5",

    label: "201 - 500",
  },

  {
    name: "6",

    label: "501 - 1.000",
  },

  {
    name: "7",

    label: "1.001 - 5.000",
  },

  {
    name: "8",

    label: "5.001 - 10.000",
  },

  {
    name: "9",

    label: "10.001 (+)",
  },
];

const revenueRangeOptions = [
  {
    name: "1",

    label: "$0-$1M",
  },

  {
    name: "2",

    label: "$1M-$10M",
  },

  {
    name: "3",

    label: "$10M-$50M",
  },

  {
    name: "4",

    label: "$50M-$100M",
  },

  {
    name: "5",

    label: "$100M-$250M",
  },

  {
    name: "6",

    label: "$250M-$500M",
  },

  {
    name: "7",

    label: "$500M-$1Bs",
  },

  {
    name: "8",

    label: "$1B-$10B",
  },

  {
    name: "9",

    label: "$10B+",
  },
];

const jobDurationOptions = [
  {
    name: "Dias",

    label: "Dias",
  },

  {
    name: "Meses",

    label: "Meses",
  },

  {
    name: "Anos",

    label: "Anos",
  },

  {
    name: "Indefinido",

    label: "Indefinido",
  },
];

const peronalityTypesList = [
  {
    label: "Inspector - ISTJ",

    name: "À primeira vista, os ISTJs são intimidadores. Eles parecem sérios, formais e adequados. Eles também amam as tradições e os valores da velha escola que defendem a paciência, o trabalho duro, a honra e a responsabilidade social e cultural. Eles são reservados, calmos, quieto e ereto. Esses traços resultam da combinação de I, S, T e J, um tipo de personalidade que muitas vezes é incompreendido.",
  },

  {
    label: "Conselheiro - INFJ",

    name: "INFJs são visionários e idealistas que exalam imaginação criativa e ideias brilhantes. Eles têm uma maneira diferente e geralmente mais profunda de ver o mundo. Eles têm uma substância e profundidade na maneira como pensam, nunca pegando nada na superfície nível ou aceitar as coisas como elas são. Outros podem, às vezes, percebê-las como estranhas ou divertidas por causa de suas diferentes perspectivas de vida.",
  },

  {
    label: "Mastermind - INTJ",

    name: "INTJs, como introvertidos, são quietos, reservados e confortáveis estando sozinhos. Eles geralmente são autossuficientes e preferem trabalhar sozinhos do que em grupo. Socializar drena a energia de um introvertido, fazendo com que eles precisem recarregar. INTJs estão interessados em ideias e teorias. Ao observar o mundo estão sempre questionando por que as coisas acontecem do jeito que acontecem. Eles se destacam no desenvolvimento de planos e estratégias e não gostam de incertezas.",
  },

  {
    label: "Doador - ENFJ",

    name: "ENFJs são indivíduos focados nas pessoas. Eles são extrovertidos, idealistas, carismáticos, francos, altamente íntegros e éticos, e geralmente sabem como se conectar com os outros, independentemente de sua origem ou personalidade. Confiando principalmente na intuição e nos sentimentos, eles tendem a vivem em sua imaginação e não no mundo real. Em vez de se concentrar em viver no “agora” e no que está acontecendo atualmente, os ENFJs tendem a se concentrar no abstrato e no que poderia acontecer no futuro.",
  },

  {
    label: "Artesão - ISTP",

    name: "ISTPs são pessoas misteriosas que geralmente são muito racionais e lógicas, mas também bastante espontâneas e entusiasmadas. Seus traços de personalidade são menos facilmente reconhecíveis do que os de outros tipos, e mesmo as pessoas que os conhecem bem nem sempre podem antecipar suas reações. No fundo, ISTPs são indivíduos espontâneos e imprevisíveis, mas eles escondem essas características do mundo exterior, muitas vezes com muito sucesso.",
  },

  {
    label: "Prestador - ESFJ",

    name: "ESFJs são os extrovertidos estereotipados. Eles são borboletas sociais, e sua necessidade de interagir com os outros e fazer as pessoas felizes geralmente acaba tornando-os populares. O ESFJ geralmente tende a ser o líder de torcida ou o herói do esporte no ensino médio e na faculdade. Mais tarde na vida, eles continuam a se divertir no centro das atenções e estão focados principalmente na organização de eventos sociais para suas famílias, amigos e comunidades. ESFJ é um tipo de personalidade comum e apreciado por muitas pessoas.",
  },

  {
    label: "Idealista - INFP",

    name: "INFPs, como a maioria dos introvertidos, são quietos e reservados. Eles preferem não falar sobre si mesmos, especialmente no primeiro encontro com uma nova pessoa. Eles gostam de passar o tempo sozinhos em lugares tranquilos onde possam entender o que está acontecendo ao redor Eles adoram analisar signos e símbolos, e os consideram como metáforas que têm significados mais profundos relacionados à vida. Eles se perdem em sua imaginação e devaneios, sempre afogados nas profundezas de seus pensamentos, fantasias e ideias.",
  },

  {
    label: "Performer - ESFP",

    name: "ESFPs têm uma personalidade Extrovertida, Observadora, Sentidora e Perceptiva, e são comumente vistos como Artistas. Nascidos para estar na frente dos outros e para capturar o palco, os ESFPs adoram os holofotes. eles aprendem com os outros. ESFPs são pessoas sociáveis' com fortes habilidades interpessoais. Eles são animados e divertidos e gostam de ser o centro das atenções. Eles são calorosos, generosos e amigáveis, simpáticos e preocupados com o bem-estar de outras pessoas.",
  },

  {
    label: "Campeão - ENFP",

    name: "ENFPs têm uma personalidade Extrovertida, Intuitiva, Sensível e Perceptiva. Este tipo de personalidade é altamente individualista e os Campeões se esforçam para criar seus próprios métodos, aparência, ações, hábitos e ideias - eles não gostam de pessoas que usam biscoitos e odeiam quando eles são forçados a viver dentro de uma caixa. Eles gostam de estar perto de outras pessoas e têm uma forte natureza intuitiva quando se trata de si mesmos e dos outros. Eles operam a partir de seus sentimentos na maior parte do tempo e são altamente perspicazes e atenciosos.",
  },

  {
    label: "Fazedor - ESTP",

    name: "ESTPs têm uma personalidade extrovertida, sensível, pensante e perceptiva. ESTPs são regidos pela necessidade de interação social, sentimentos e emoções, processos lógicos e raciocínio, juntamente com a necessidade de liberdade. Teoria e resumos não mantêm ESTP's interessados ​​por muito tempo. Os ESTPs saltam antes de olhar, corrigindo seus erros à medida que avançam, em vez de ficarem ociosos ou preparando planos de contingência.",
  },

  {
    label: "Supervisor - ESTJ",

    name: "ESTJs são organizados, honestos, dedicados, dignos, tradicionais e acreditam muito em fazer o que acreditam ser certo e socialmente aceitável. Embora os caminhos para o bom e o certo sejam difíceis, eles ficam felizes em seguir seus lugar como os líderes do bando. Eles são o epítome da boa cidadania. As pessoas procuram os ESTJs em busca de orientação e conselho, e os ESTJs estão sempre felizes por serem procurados para obter ajuda.",
  },

  {
    label: "Comandante - ENTJ",

    name: "O modo de vida primário de um ENTJ se concentra em aspectos externos e todas as coisas são tratadas de forma racional e lógica. Seu modo de operação secundário é interno, onde a intuição e o raciocínio entram em vigor. ENTJs são líderes natos entre os 16 tipos de personalidade e afins estar no comando. Eles vivem em um mundo de possibilidades e muitas vezes veem desafios e obstáculos como grandes oportunidades para se impulsionarem. Eles parecem ter um dom natural para liderança, tomar decisões e considerar opções e ideias com rapidez, mas com cuidado. Eles são assuma o comando pessoas que não gostam de ficar paradas.",
  },

  {
    label: "Pensador - INTP",

    name: "INTPs são bem conhecidos por suas teorias brilhantes e lógica implacável, o que faz sentido, já que eles são indiscutivelmente os mais lógicos de todos os tipos de personalidade. Eles adoram padrões, têm um olho aguçado para detectar discrepâncias e uma boa habilidade ler as pessoas, o que torna uma má ideia mentir para um INTP. Pessoas com esse tipo de personalidade não estão interessadas em atividades e manutenção práticas do dia-a-dia, mas quando encontram um ambiente onde seu gênio criativo e potencial podem ser expresso, não há limite para o tempo e a energia que os INTPs gastarão no desenvolvimento de uma solução perspicaz e imparcial.",
  },

  {
    label: "Nutridor - ISFJ",

    name: "ISFJs são filantropos e estão sempre prontos para retribuir e devolver generosidade com ainda mais generosidade. As pessoas e coisas em que acreditam serão mantidas e apoiadas com entusiasmo e altruísmo. ISFJs são calorosos e de bom coração. Eles valorizam a harmonia e cooperação, e provavelmente são muito sensíveis aos sentimentos de outras pessoas. As pessoas valorizam o ISFJ por sua consideração e consciência, e sua capacidade de trazer o melhor para os outros.",
  },

  {
    label: "Visionário - ENTP",

    name: "Aqueles com a personalidade ENTP são alguns dos mais raros do mundo, o que é completamente compreensível. Embora sejam extrovertidos, eles não gostam de conversa fiada e podem não prosperar em muitas situações sociais, especialmente aquelas que envolvem pessoas que são muito diferente do ENTP. Os ENTPs são inteligentes e experientes, precisam ser constantemente estimulados mentalmente. Eles têm a capacidade de discutir teorias e fatos em detalhes extensos. Eles são lógicos, racionais e objetivos em sua abordagem de informações e argumentos.",
  },

  {
    label: "Compositor - ISFP",

    name: "ISFPs são introvertidos que não parecem introvertidos. É porque, mesmo que tenham dificuldades em se conectar com outras pessoas no início, eles se tornam calorosos, acessíveis e amigáveis ​​eventualmente. Eles são divertidos e muito espontâneos, o que os torna eles são o amigo perfeito para acompanhar em qualquer atividade, independentemente se planejada ou não planejada. ISFPs querem viver sua vida ao máximo e abraçar o presente, então eles se certificam de que estão sempre fora para explorar coisas novas e descobrir novas experiências. É na experiência que eles encontram sabedoria, então eles veem mais valor em conhecer novas pessoas do que outros introvertidos.",
  },
];
const CommunicationStatusOptions = [
  {
    name: "0",

    label: "Contacted by Company",
  },

  {
    name: "1",

    label: "Talent Applied to Job",
  },

  {
    name: "2",

    label: "Conversation in Progress",
  },

  {
    name: "3",

    label: "Hired",
  },

  {
    name: "4",

    label: "Process Closed",
  },
];

const TrainingProgressStatusOptions = [
  {
    name: "0",

    label: "Treinamento em andamento",
  },

  {
    name: "1",

    label: "Treinamento concluído",
  },
];

const SecurityLevelOptions = [
  {
    name: "0",

    label: "Confidencial",
  },

  {
    name: "1",

    label: "Secreto",
  },

  {
    name: "2",

    label: "Top Secret",
  },
];

const SecurityClearanceRenewalOptions = [
  {
    name: "0",

    label: "<1 year",
  },

  {
    name: "1",

    label: "<= 2 years",
  },

  {
    name: "2",

    label: "<= 3 years",
  },

  {
    name: "2",

    label: "<= 4 years",
  },

  {
    name: "2",

    label: "<= 5 years",
  },
];
const trainingLevelList = [
  {
    name: "New to Cyber",

    label: "Novo no cyber",
  },
  {
    name: "Foundations",

    label: "Fundamentos",
  },

  {
    name: "Advanced",

    label: "Avançado",
  },
  {
    name: "Intermediate",

    label: "Intermediário",
  },

  {
    name: "Introductory",

    label: "Introdutório",
  },
];
const typeTrainingList = [
  {
    name: "Training",
    label: "Treinamento",
  },

  {
    name: "Certification",
    label: "Certificação",
  },

  {
    name: "Awareness",
    label: "Conscientização",
  },

  {
    name: "Education",
    label: "Educação",
  },
];
const modality = [
  {
    name: "Remote",
    label: "Remoto",
  },

  {
    name: "Hybrid",
    label: "Híbrido",
  },

  {
    name: "On-site",
    label: "No local",
  },
];
const Levels = [
  {
    name: 0,
    label: "Nível Iniciante",
  },

  {
    name: 1,
    label: "Meio de carreira",
  },

  {
    name: 2,
    label: "Profissional",
  },
];
const HowToStartCategory = [
  {
    name: "Cybersecurity Intro and Principles",
    label: "Introdução e princípios de cybersecurity",
    code: "CybersecurityIntroandPrinciples",
    description:
      "Se você está interessado em fazer parte da indústria de Cybersecurity, é crucial entender o que é cybersecurity e alguns dos seus princípios básicos.",
  },

  {
    name: "Frameworks and Regulations",
    label: "Estruturas e Regulamentos",
    code: "FrameworksandRegulations",
    description:
      "A cybersecurity (Cybersecurity) é um mundo interessante e complexo. Estruturas e padrões foram definidos por diferentes países e indústrias para compreender e gerenciar os riscos e o ecossistema de cybersecurity.",
  },

  {
    name: "Areas of Specialization in Cybersecurity",
    label: "Áreas de Especialização em Cybersecurity",
    code: "AreasofSpecializationinCybersecurity",
    description:
      "Agora que você decidiu ingressar na força de trabalho em cybersecurity, recomendamos que leia sobre as diferentes áreas nas quais pode se especializar.",
  },
  {
    name: "Professional Organizations",
    label: "Organizaciones profesionales",
    code: "ProfessionalOrganizations",
    description:
      "Networking é uma das melhores maneiras de aprender sobre notícias e atualizações de cybersecurity e identificar o que mais lhe interessa no setor.",
  },
  {
    name: "Degrees in Cybersecurity",
    label: "Graduações em Cybersecurity",
    code: "DegreesinCybersecurity",
    description:
      "A educação é fundamental para esta indústria. Existem alguns diplomas que você pode considerar obter para melhorar seu conhecimento e adquirir habilidades adicionais.",
  },
  {
    name: "Trainings and Certifications",
    label: "Treinamentos e Certificações",
    code: "TrainingsandCertifications",
    description:
      "Treinamentos e certificações são uma ótima maneira de se aprofundar em tópicos específicos e adquirir novas habilidades em cybersecurity. Aqui está uma lista de alguns dos aprendizados mais reconhecidos e básicos para iniciar sua jornada no setor.",
  },
  {
    name: "IT Assets",
    label: "Activos de TI",
    code: "ITAssets",
    description:
      "Sem tecnologia não precisaríamos aprender sobre Cybersecurity. O interesse por tecnologia, tecnologia da informação e tecnologia operacional pode andar de mãos dadas com o interesse pela cybersecurity. Você pode aprender sobre Cybersecurity sem se aprofundar na tecnologia, mas é muito benéfico entender como as tecnologias funcionam e quais ativos de TI existem para compreender os riscos cibernéticos.",
  },
  {
    name: "Stay Informed",
    label: "Mantenha-se informado",
    code: "StayInformed",
    description:
      "Cybersecurity é uma indústria em constante mudança. Mantenha-se atualizado com todas as novidades, tendências e tópicos seguindo ótimos blogs e outras fontes!",
  },
  {
    name: "Is Cybersecurity for me?",
    label: "A Cybersecurity é para mim?",
    code: "IsCybersecurityforme",
    description:
      "Aqui estão algumas fontes que você pode ler para entender se a cybesrecurity é a jornada para você.",
  },
];
const HowToStartCategoryCompany = [
  {
    name: "Cybersecurity Intro and Principles",
    label: "Introdução e princípios de cybersecurity",
    code: "CybersecurityIntroandPrinciples",
    description:
      "Se você está interessado em gerar conscientização sobre cybersecurity na organização, é crucial entender o que é cybersecurity e alguns de seus princípios básicos.",
  },

  {
    name: "Frameworks and Regulations",
    label: "Estruturas e Regulamentos",
    code: "FrameworksandRegulations",
    description:
      "A cybersecurity (Cybersecurity) é um mundo interessante e complexo. Estruturas e padrões foram definidos por diferentes países e indústrias para compreender e gerenciar os riscos e o ecossistema de cybersecurity.",
  },

  {
    name: "Areas of Specialization in Cybersecurity",
    label: "Áreas de Especialização em Cybersecurity",
    code: "AreasofSpecializationinCybersecurity",
    description:
      "Para colaboradores novos na área de cybersecurity, ofereça a oportunidade de explorar diversas áreas especializadas dentro do campo.",
  },
  {
    name: "Professional Organizations",
    label: "Organizaciones profesionales",
    code: "ProfessionalOrganizations",
    description:
      "Networking é uma das melhores maneiras de aprender sobre notícias e atualizações de cybersecurity e identificar o que mais lhe interessa no setor.",
  },
  {
    name: "Degrees in Cybersecurity",
    label: "Graduações em Cybersecurity",
    code: "DegreesinCybersecurity",
    description:
      "A educação é crucial na indústria de cybersecurity. Considere recomendar alguns cursos ou programas educacionais para colaboradores interessados em ingressar na força de trabalho de cybersecurity, a fim de aprimorar seus conhecimentos e adquirir as habilidades necessárias.",
  },
  {
    name: "Trainings and Certifications",
    label: "Treinamentos e Certificações",
    code: "TrainingsandCertifications",
    description:
      "Treinamentos e certificações são uma ótima maneira de se aprofundar em tópicos específicos e adquirir novas habilidades em cybersecurity. Aqui está uma lista de alguns dos aprendizados mais reconhecidos e básicos para compartilhar com suas equipes digitais e iniciar a jornada em cybersecurity.",
  },
  {
    name: "IT Assets",
    label: "Activos de TI",
    code: "ITAssets",
    description:
      "Sem tecnologia não precisaríamos aprender sobre Cybersecurity. O interesse por tecnologia, tecnologia da informação e tecnologia operacional pode andar de mãos dadas com o interesse pela cybersecurity. Você pode aprender sobre Cybersecurity sem se aprofundar na tecnologia, mas é muito benéfico entender como as tecnologias funcionam e quais ativos de TI existem para compreender os riscos cibernéticos.",
  },
  {
    name: "Stay Informed",
    label: "Mantenha-se informado",
    code: "StayInformed",
    description:
      "Cybersecurity é uma indústria em constante mudança. Mantenha-se atualizado com todas as novidades, tendências e tópicos seguindo ótimos blogs e outras fontes!",
  },
  {
    name: "Is Cybersecurity for me?",
    label: "A Cybersecurity é para mim?",
    code: "IsCybersecurityforme",
    description:
      "Aqui estão algumas fontes que você pode ler para entender se a cybesrecurity é a jornada para você.",
  },
];

const CategoriesJobs = [
  {
    name: "Information Security Analyst",
    label: "Analista de Segurança da Informação:",
    code: "InformationSecurityAnalyst",
    description:
      "Protege os sistemas de computadores e redes de uma organização através da análise e implementação de medidas de segurança para se proteger contra ameaças potenciais.",
  },

  {
    name: "Digital Forensic Examiner  Cyber Crime Analyst: ",
    label: "Perito em Forense Digital / Analista de Crimes Cibernéticos:",
    code: "DigitalForensicExaminerCyberCrimeAnalyst: ",
    description:
      "Investiga e analisa evidências digitais para rastrear e prevenir crimes cibernéticos, empregando técnicas forenses para entender e mitigar incidentes cibernéticos.",
  },

  {
    name: "Cybersecurity Specialist",
    label: "Especialista em Cybersecurity:",
    code: "CybersecuritySpecialist",
    description:
      "Profissional especializado que aborda vários aspectos da Cybersecurity, incluindo gerenciamento de riscos, resposta a incidentes e implementação de medidas avançadas de segurança adaptadas às necessidades de uma organização.",
  },
  {
    name: "IT Auditor",
    label: "Auditor de TI:",
    code: "ITAuditor",
    description:
      "Avalia e garante a eficácia dos sistemas de TI de uma organização, examinando controles, políticas e procedimentos para identificar vulnerabilidades e garantir conformidade com padrões da indústria.",
  },
  {
    name: "Penetration Tester",
    label: "Testador de Penetração:",
    code: "PenetrationTester",
    description:
      "Hacker ético simulando ciberataques para avaliar e fortalecer a segurança de sistemas de computadores e redes, identificando e corrigindo vulnerabilidades antes que atores maliciosos possam explorá-las.",
  },
  {
    name: "Secure Software Developer:",
    label: "Desenvolvedor de Software Seguro:",
    code: "SecureSoftwareDeveloper",
    description:
      "Projeta e cria aplicativos de software com foco em práticas de codificação seguras, minimizando vulnerabilidades e implementando medidas para se proteger contra possíveis ameaças de segurança.",
  },
];

const CareerOwner = [
  {
    name: "Planos de Carreira Seccuri",
    label: 0,
  },
  {
    name: "Planos de Carreira Públicos",
    label: 1,
  },
];

export default {
  openToTravelOptions,
  educationLevelOptions,
  genderOptions,
  desideredEmploymentTypeOptions,
  industryOptions,
  employmentTypeOptions,
  languagesOptions,
  numberOfEmployeesOptions,
  revenueRangeOptions,
  jobDurationOptions,
  SecurityLevelOptions,
  SecurityClearanceRenewalOptions,
  CommunicationStatusOptions,
  TrainingProgressStatusOptions,
  peronalityTypesList,
  trainingLevelList,
  typeTrainingList,
  modality,
  Levels,
  HowToStartCategory,
  HowToStartCategoryCompany,
  CategoriesJobs,
  CareerOwner,
};
