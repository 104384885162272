import { config } from "vuex-module-decorators";
import Vuex from "vuex";
import AuthModule from "@/store/modules/AuthModule";
import BodyModule from "@/store/modules/BodyModule";
import BreadcrumbsModule from "@/store/modules/BreadcrumbsModule";
import ConfigModule from "@/store/modules/ConfigModule";

// Configurar Vuex usando el método config de vuex-module-decorators
config.rawError = true;

const store = new Vuex.Store({
  state: {},
  modules: {
    AuthModule,
    BodyModule,
    BreadcrumbsModule,
    ConfigModule,
  },
});

export default store;
