import LayoutConfigTypes from "@/core/config/LayoutConfigTypes";

const config: LayoutConfigTypes = {
  themeName: "Seccuri",
  themeVersion: "8.0.38",
  demo: "demo2",
  main: {
    type: "default",
    primaryColor: "#2B3990",
    logo: {
      dark: "media/logos/logo-1-dark-blue.png",
      light: "media/logos/logo-1.png",
    },
  },
  illustrations: {
    set: "seccuri",
  },
  loader: {
    logo: "media/logos/logo-1-dark-blue.png",
    display: true,
    type: "spinner-message",
  },
  scrollTop: {
    display: true,
  },
  header: {
    display: true,
    menuIcon: "font",
    width: "fixed",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  toolbar: {
    display: true,
    width: "fixed",
    fixed: {
      desktop: true,
      tabletAndMobile: true,
    },
  },
  aside: {
    display: true,
    theme: "light",
    fixed: true,
    menuIcon: "svg",
    minimized: true,
    minimize: true,
    hoverable: true,
    position: "sticky",
  },
  content: {
    width: "fixed",
  },
  footer: {
    width: "fixed",
  },
  limitPostJobsDemo: process.env.VUE_APP_LIMIT_POST_JOB_DEMO || 3,
};

export default config;
