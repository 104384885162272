const openToTravelOptions = [
  {
    name: "0",

    label: "No Especificado",
  },

  {
    name: "1",

    label: "0%",
  },

  {
    name: "2",

    label: "25%",
  },

  {
    name: "3",

    label: "50%",
  },

  {
    name: "4",

    label: "75%",
  },

  {
    name: "5",

    label: "100%",
  },
];

const educationLevelOptions = [
  {
    name: "-1",

    label: "No definido",
  },

  {
    name: "0",

    label: "Educación básica primaria",
  },

  {
    name: "1",

    label: "Diploma de escuela secundaria o su equivalente",
  },

  {
    name: "2",

    label: "Un poco de universidad, sin título",
  },

  {
    name: "3",

    label: "Premio postsecundario, sin título",
  },

  {
    name: "4",

    label: "Título de asociado",
  },

  {
    name: "5",

    label: "Título de pregrado",
  },

  {
    name: "6",

    label: "Título de posgrado",
  },

  {
    name: "7",

    label: "Doctorado",
  },
];

const genderOptions = [
  {
    name: "0",

    label: "Femenino",
  },

  {
    name: "1",

    label: "Masculino",
  },

  {
    name: "3",

    label: "Otro",
  },

  {
    name: "4",

    label: "Prefiero no decirlo",
  },
];

const desideredEmploymentTypeOptions = [
  {
    name: "0",

    label: "No especificado",
  },

  {
    name: "1",

    label: "Práctica profesional",
  },

  {
    name: "2",

    label: "Contrato",
  },

  {
    name: "3",

    label: "Permanente",
  },

  {
    name: "4",

    label: "Contrato a Permanente",
  },

  {
    name: "5",

    label: "Consultor (1099)",
  },

  {
    name: "6",

    label: "Trabajo de entrada",
  },

  {
    name: "7",

    label: "Medio tiempo",
  },

  {
    name: "8",

    label: "Concertado",
  },

  {
    name: "9",

    label: "Temporal (ej. 15 días, dos meses, un año)",
  },

  {
    name: "10",

    label: "Eventual (por servicios, trabajo eventual, reemplazo)",
  },
];

const industryOptions = [
  { name: "Accounting", label: "Finanzas" },

  { name: "Accommodation", label: "Alojamiento" },

  { name: "Administration of Justice", label: "Administración de justicia" },

  {
    name: "Administrative & Support Services",

    label: "Servicios administrativos y de apoyo",
  },

  { name: "Advertising Services", label: "Servicios de publicidad" },

  { name: "Agriculture", label: "Agricultura" },

  {
    name: "Air, Water, & Waste Program Management",

    label: "Gestión de programas de aire, agua y desechos",
  },

  { name: "Airlines/Aviation", label: "Aerolíneas/Aviación" },

  {
    name: "Alternative Dispute Resolution",

    label: "Resolución de conflicto alternativo",
  },

  { name: "Alternative Medicine", label: "Medicina alternativa" },

  { name: "Ambulance Services", label: "Servicios de ambulancia" },

  {
    name: "Amusement Parks & Arcades",

    label: "Parques de atracciones y salas de juegos",
  },

  { name: "Animation", label: "Animación" },

  { name: "Apparel & Fashion", label: "Ropa y moda" },

  { name: "Apparel Manufacturing", label: "Manufactura de ropa" },

  { name: "Architecture & Planning", label: "Arquitectura y planificación" },

  { name: "Armed Forces", label: "Fuerzas armadas" },

  { name: "Arts & Crafts", label: "Arte y artesanía" },

  { name: "Artists & Writers", label: "Artistas y escritores" },

  { name: "Automotive", label: "Automotor" },

  { name: "Aviation & Aerospace", label: "Aviación y aeroespacial" },

  { name: "Banking", label: "Bancario" },

  {
    name: "Baked Goods Manufacturing",

    label: "Fabricación de productos horneados",
  },

  {
    name: "Bars, Taverns, & Nightclubs",

    label: "Bares, tabernas y discotecas",
  },

  {
    name: "Bed-and-Breakfasts, Hostels, Homestays",

    label: "Alojamiento y desayuno, hostales, casas de familia",
  },

  { name: "Beverage Manufacturing", label: "Fabricación de bebidas" },

  {
    name: "Biomass Electric Power Generation",

    label: "Generación de energía eléctrica de biomasa",
  },

  { name: "Biotechnology", label: "Biotecnología" },

  { name: "Blockchain Services", label: "Servicios de cadena de bloques" },

  { name: "Blogs", label: "Blogs" },

  {
    name: "Boilers, Tanks, & Shipping Container Manufacturing",

    label: "Fabricación de calderas, tanques y contenedores de envío",
  },

  {
    name: "Book & Periodical Publishing",

    label: "Edición de libros y periódicos",
  },

  { name: "Breweries", label: "Cervecerías" },

  { name: "Broadcast Media", label: "Medios de difusión" },

  { name: "Building Construction", label: "Construcción de edificio" },

  { name: "Building Materials", label: "Materiales de construcción" },

  {
    name: "Business Consulting & Services",

    label: "Servicios y consultoría de negocios",
  },

  { name: "Business Content", label: "Contenido de empresas" },

  {
    name: "Business Intelligence Platforms",

    label: "Plataformas de inteligencia de negocios",
  },

  {
    name: "Business Supplies & Equipment",

    label: "Suministros y equipos comerciales",
  },

  {
    name: "Cable & Satellite Programming",

    label: "Suministros y equipos comerciales",
  },

  { name: "Capital Markets", label: "Mercados Capitales" },

  { name: "Caterers", label: "Caterings" },

  { name: "Chemicals", label: "Químicos" },

  { name: "Child Day Care Services", label: "Servicios de guardería infantil" },

  { name: "Chiropractors", label: "Quiroprácticos" },

  { name: "Circuses & Magic Shows", label: "Espectáculos de circos y magia" },

  {
    name: "Claims Adjusting, Actuarial Services",

    label: "Ajuste de reclamaciones, servicios actuariales",
  },

  {
    name: "Clay & Refractory Products Manufacturing",

    label: "Fabricación de arcilla y productos refractarios",
  },

  {
    name: "Civic & Social Organization",

    label: "Organización Cívica y Social",
  },

  { name: "Civil Engineering", label: "Ingeniería Civil" },

  { name: "Coal Mining", label: "Minería de carbón" },

  { name: "Collection Agencies", label: "Agencias de cobro" },

  { name: "Comercial Real Estate", label: "Bienes raíces comerciales" },

  {
    name: "Commercial & Industrial Equipment Rental",

    label: "Alquiler de equipos comerciales e industriales",
  },

  {
    name: "Commercial & Industrial Machinery Maintenance",

    label: "Mantenimiento de maquinaria comercial e industrial",
  },

  {
    name: "Community Development & Urban Planning",

    label: "Desarrollo comunitario y planificación urbana",
  },

  { name: "Community Services", label: "Servicios comunitarios" },

  {
    name: "Computer & Network Security",

    label: "Seguridad informática y de redes",
  },

  { name: "Computer Games", label: "Juegos de Computadora" },

  { name: "Computer Hardware", label: "Hardware de computadora" },

  {
    name: "Computer Hardware Manufacturing",

    label: "Fabricación de hardware informático",
  },

  { name: "Computer Networking", label: "Redes de computadoras" },

  {
    name: "Computer Networking Products",

    label: "Productos de redes informáticas",
  },

  { name: "Computer Software", label: "Software de ordenador" },

  {
    name: "Computers & Electronics Manufacturing",

    label: "Fabricación de computadoras y productos electrónicos",
  },

  { name: "Conservation Programs", label: "Programas de conservación" },

  { name: "Construction", label: "Construcción" },

  {
    name: "Construction Hardware Manufacturing",

    label: "Fabricación de hardware de construcción",
  },

  { name: "Consumer Electronics", label: "Electrónica de consumo" },

  { name: "Consumer Goods", label: "Bienes de consumo" },

  { name: "Consumer Services", label: "Servicio al consumidor" },

  { name: "Correctional Institutions", label: "Instituciones correccionales" },

  { name: "Cosmetics", label: "Cosméticos" },

  { name: "Courts of Law", label: "Tribunales de justicia" },

  { name: "Credit Intermediation", label: "Intermediación de crédito" },

  { name: "Dairy", label: "Lácteos" },

  { name: "Dance Companies", label: "empresas de danza" },

  {
    name: "Data Infrastructure & Analytics",

    label: "Infraestructura de datos y análisis",
  },

  {
    name: "Data Security Software Products",

    label: "Productos de software de seguridad de datos",
  },

  { name: "Defense & Space", label: "Defensa y espacio" },

  { name: "Dentists", label: "Dentistas" },

  { name: "Design", label: "Diseño" },

  {
    name: "Desktop Computing Software Products",

    label: "Productos de software de computación de escritorio",
  },

  { name: "Distilleries", label: "Destilerías" },

  { name: "E-learning", label: "Aprendizaje electrónico" },

  { name: "Education Management", label: "Administración de educación" },

  {
    name: "Electrical & Electronic Manufacturing",

    label: "Fabricación eléctrica y electrónica",
  },

  { name: "Entertainment", label: "Entretenimiento" },

  {
    name: "Equipment Rental Services",

    label: "Servicios de alquiler de equipos",
  },

  { name: "Environmental Services", label: "Servicios ambientales" },

  { name: "Events Services", label: "Servicios de eeventos" },

  { name: "Executive Office", label: "Oficina ejecutiva" },

  {
    name: "Executive Search Services",

    label: "Servicios de búsqueda de ejecutivos",
  },

  {
    name: "Fabricated Metal Products",

    label: "Productos metálicos fabricados",
  },

  { name: "Facilities Services", label: "Servicios de Instalaciones" },

  {
    name: "Family Planning Centers",

    label: "Centros de planificación familiar",
  },

  { name: "Farming", label: "Agricultura" },

  {
    name: "Farming, Ranching, Forestry",

    label: "Agricultura, ganadería, silvicultura",
  },

  {
    name: "Fashion Accessories Manufacturing",

    label: "Fabricación de accesorios de moda",
  },

  { name: "Financial Services", label: "Servicios financieros" },

  { name: "Fire Protection", label: "Protección contra incendios" },

  { name: "Fine Art", label: "Arte fino" },

  { name: "Flight Training", label: "Entrenamiento de vuelo" },

  { name: "Fishery", label: "Pesquería" },

  { name: "Food & Beverages", label: "Alimentos y bebidas" },

  { name: "Food Production", label: "Producción de alimentos" },

  { name: "Footwear Manufacturing", label: "Fabricación de calzado" },

  { name: "Forestry & Logging", label: "Silvicultura y tala" },

  {
    name: "Fossil Fuel Electric Power Generation",

    label: "Generación de energía eléctrica con combustibles fósiles",
  },

  {
    name: "Freight & Package Transportation",

    label: "Transporte de carga y paquetería",
  },

  {
    name: "Fruit & Vegetable Preserves Manufacturing",

    label: "Fabricación de conservas de frutas y verduras",
  },

  { name: "Fundraising", label: "Recaudación de fondos" },

  { name: "Funds & Trusts", label: "Fondos y fideicomisos" },

  { name: "Furniture", label: "Muebles" },

  { name: "Gambling & Casinos", label: "Apuestas y casinos" },

  {
    name: "Geothermal Electric Power Generation",

    label: "Generación de energía eléctrica geotérmica",
  },

  { name: "Glass, Ceramics & Concrete", label: "Vidrio, cerámica y concreto" },

  {
    name: "Golf Courses & Country Clubs",

    label: "Campos de golf y clubes recreación",
  },

  { name: "Government Administration", label: "Administración gubernamental" },

  { name: "Government Relations", label: "Relaciones gubernamentales" },

  { name: "Graphic Design", label: "Diseño gráfico" },

  {
    name: "Ground Passenger Transportation",

    label: "Transporte terrestre de pasajeros",
  },

  {
    name: "Health, Wellness & Fitness",

    label: "Salud, bienestar y estado físico",
  },

  { name: "Higher Education", label: "Educación de alta calidad" },

  {
    name: "Highway, Street, & Bridge Construction",

    label: "Construcción de carreteras, calles y puentes",
  },

  { name: "Historical Sites", label: "Lugares históricos" },

  { name: "Holding Companies", label: "Sociedades de cartera" },

  { name: "Horticulture", label: "Horticultura" },

  {
    name: "Hospital & Health Care",

    label: "Atención sanitaria y hospitalaria",
  },

  {
    name: "Hospitality Human Resources",

    label: "Recursos humanos hospitalarios",
  },

  { name: "Hotels & Motels", label: "Hoteles y moteles" },

  { name: "Household Services", label: "Servicios domésticos" },

  {
    name: "Housing & Community Development",

    label: "Vivienda y desarrollo comunitario",
  },

  { name: "Housing Programs", label: "Programas de vivienda" },

  {
    name: "Hydroelectric Power Generation",

    label: "Generación de energía hidroeléctrica",
  },

  { name: "Import & Export", label: "Importación y exportación" },

  {
    name: "Indivvalueual & Family Services",

    label: "Servicios individuales y familiares",
  },

  { name: "Industrial Automation", label: "Automatización industrial" },

  {
    name: "Industrial Machinery Manufacturing",

    label: "Fabricación de maquinaria industrial",
  },

  { name: "Industry Associations", label: "Asociaciones industriales" },

  { name: "Information Services", label: "Servicios de Información" },

  {
    name: "Information Technology & Services",

    label: "Servicios y tecnología de la información",
  },

  { name: "Insurance", label: "Seguros" },

  { name: "Interior Design", label: "Diseño de interiores" },

  { name: "International Affairs", label: "Asuntos Internacionales" },

  {
    name: "International Trade & Development",

    label: "Comercio internacional y desarrollo",
  },

  { name: "Internet", label: "Internet" },

  {
    name: "Interurban & Rural Bus Services",

    label: "Servicios de autobuses interurbanos y rurales",
  },

  { name: "Investment Advice", label: "Consejo de inversión" },

  { name: "Investment Banking", label: "Inversión bancaria" },

  { name: "Investment Management", label: "Gestión de inversiones" },

  {
    name: "IT Services & IT Consulting",

    label: "Servicios de TI y consultoría de TI",
  },

  {
    name: "IT System Custom Software Development",

    label: "Sistema de TI desarrollo de software personalizado",
  },

  {
    name: "IT System Data Services",

    label: "Servicios de datos del sistema de TI",
  },

  {
    name: "IT System Design Services",

    label: "Servicios de diseño de sistemas de TI",
  },

  {
    name: "IT System Installation & Disposal",

    label: "Instalación y eliminación de sistemas de TI",
  },

  {
    name: "IT System Operations & Maintenance",

    label: "Operaciones y mantenimiento del sistema de TI",
  },

  {
    name: "IT System Testing & Evaluation",

    label: "Pruebas y evaluación de sistemas de TI",
  },

  {
    name: "IT System Training & Support",

    label: "entrenamiento y soporte del sistema de TI",
  },

  { name: "Janitorial Services", label: "Servicios de limpieza" },

  { name: "Judiciary", label: "Judicial" },

  { name: "Landscaping Services", label: "Servicios de paisajismo" },

  { name: "Language Schools", label: "Escuelas de idiomas" },

  {
    name: "Laundry & Drycleaning Services",

    label: "Servicios de lavandería y tintorería",
  },

  { name: "Law Enforcement", label: "Cumplimiento de la ley" },

  { name: "Law Practice", label: "Práctica de la ley" },

  {
    name: "Leasing Residential/Non-residential Real Estate",

    label: "Arrendamiento de bienes raíces residenciales/no residenciales",
  },

  { name: "Legal Services", label: "Servicios jurídicos" },

  { name: "Legislative Office", label: "Oficina legislativa" },

  { name: "Leisure, Travel & Tourism", label: "Ocio, viajes y turismo" },

  { name: "Libraries", label: "Bibliotecas" },

  { name: "Loan Brokers", label: "Corredores de préstamos" },

  {
    name: "Logistics & Supply Chain",

    label: "Logística y cadena de suministro",
  },

  { name: "Luxury Goods & Jewelry", label: "Artículos de lujo y joyería" },

  { name: "Machinery", label: "Maquinaria" },

  { name: "Management Consulting", label: "Consultoría de gestión" },

  { name: "Manufacturing", label: "Fabricación" },

  { name: "Maritime", label: "Marítima" },

  { name: "Market Research", label: "Investigación de mercado" },

  { name: "Marketing & Advertising", label: "Marketing y publicidad" },

  {
    name: "Mechanical Or Industrial Engineering",

    label: "Ingeniería industrial o mecánica",
  },

  {
    name: "Media production medical production",

    label: "Producción médica",
  },

  { name: "Media & Telecommunications", label: "Medios y telecomunicaciones" },

  { name: "Medical Device", label: "Dispositivos médicos" },

  { name: "Medical Practice", label: "Práctica médica" },

  {
    name: "Medical & Diagnostic Laboratories",

    label: "Laboratorios médicos y de diagnóstico",
  },

  { name: "Mental Health Care", label: "Cuidado de la salud mental" },

  { name: "Metal Treatments", label: "Tratamientos de metales" },

  {
    name: "Metalworking Machinery Manufacturing",

    label: "Fabricación de maquinaria metalúrgica",
  },

  { name: "Military", label: "Militar" },

  { name: "Mining & Metals", label: "Minería y metales" },

  {
    name: "Mobile Computing Software Products",

    label: "Productos de software de computación móvil",
  },

  { name: "Mobile Food Services", label: "Servicios móviles de alimentos" },

  { name: "Mobile Games", label: "Juegos móviles" },

  { name: "Motion Pictures & Film", label: "Películas" },

  {
    name: "Motor Vehicle Manufacturing",

    label: "Fabricación de vehículos de motor",
  },

  { name: "Museums & Institutions", label: "Museos e instituciones" },

  { name: "Music", label: "Música" },

  { name: "Nanotechnology", label: "Nanotecnología" },

  {
    name: "Natural Gas Extraction & Distribution",

    label: "Extracción y distribución de gas natural",
  },

  { name: "Newspapers", label: "Periódicos" },

  {
    name: "Nonmetallic Mineral Mining",

    label: "Minería de minerales no metálicos",
  },

  {
    name: "Non-profit Organization Management",

    label: "Gestión de organizaciones sin ánimo de lucro",
  },

  {
    name: "Nonresidential Building Construction",

    label: "Construcción de edificios no residenciales",
  },

  {
    name: "Nuclear Electric Power Generation",

    label: "Generación de energía eléctrica nuclear",
  },

  {
    name: "Nursing Homes & Residential Care Facilities",

    label: "Hogares de ancianos e instalaciones de atención residencial",
  },

  { name: "Office Administration", label: "Administración de oficina" },

  {
    name: "Office Furniture & Fixtures Manufacturing",

    label: "Fabricación de muebles y accesorios de oficina",
  },

  { name: "Oil & Energy", label: "Petróleo y energía" },

  { name: "Online Media", label: "Medios en línea" },

  {
    name: "Online & Mail Order Retail",

    label: "Venta minorista en línea y por correo",
  },

  { name: "Operations Consulting", label: "Consultoría de operaciones" },

  { name: "Optometrists", label: "Optometristas" },

  { name: "Outpatient Care Centers", label: "Centros de atención ambulatoria" },

  { name: "Outsourcing/offshoring", label: "Subcontratación/deslocalización" },

  { name: "Package/Freight Delivery", label: "Entrega de paquetes/carga" },

  { name: "Packaging & Containers", label: "Empaques y contenedores" },

  {
    name: "Paint, Coating, & Adhesive Manufacturing",

    label: "Fabricación de pinturas, revestimientos y adhesivos",
  },

  { name: "Paper & Forest Products", label: "Productos de papel y forestales" },

  { name: "Pension Funds", label: "Fondos de la pensión" },

  { name: "Performing Arts", label: "Artes escénicas" },

  { name: "Periodical Publishing", label: "Publicación periódica" },

  {
    name: "Personal & Laundry Services",

    label: "Servicios personales y de lavandería",
  },

  {
    name: "Personal Care Product Manufacturing",

    label: "Fabricación de productos de cuidado personal",
  },

  { name: "Personal Care Services", label: "Servicios de cuidado personal" },

  { name: "Pet Services", label: "Servicios para mascotas" },

  { name: "Pharmaceuticals", label: "Farmacéuticos" },

  { name: "Pharmaceutical Manufacturing", label: "Fabricación farmacéutica" },

  { name: "Philanthropy", label: "Filantropía" },

  { name: "Photography", label: "Fotografía" },

  { name: "Physicians", label: "Médicos" },

  { name: "Pipeline Transportation", label: "Transporte por tubería" },

  { name: "Plastics", label: "Plásticos" },

  { name: "Political Organization", label: "Organización política" },

  { name: "Postal Services", label: "Servicios postales" },

  {
    name: "Primary Metal Manufacturing",

    label: "Fabricación de metales primarios",
  },

  {
    name: "Primary/Secondary Education",

    label: "Educación primaria/secundaria",
  },

  { name: "Printing", label: "Impresión" },

  { name: "Professional Organizations", label: "Organizaciones profesionales" },

  { name: "Professional Services", label: "Servicios profesionales" },

  {
    name: "Professional Training & Coaching",

    label: "Entrenamiento profesional",
  },

  { name: "Program Development", label: "Desarrollo del programa" },

  {
    name: "Public Assistance Programs",

    label: "Programas de asistencia pública",
  },

  { name: "Public Health", label: "Salud pública" },

  { name: "Public Policy", label: "Política pública" },

  {
    name: "Public Relations & Communications",

    label: "Relaciones públicas y comunicaciones",
  },

  { name: "Public Safety", label: "Seguridad pública" },

  { name: "Publishing", label: "Publicación" },

  { name: "Racetracks", label: "Pistas de carreras" },

  {
    name: "Radio & Television Broadcasting",

    label: "Difusión de radio y televisión",
  },

  { name: "Railroad Manufacture", label: "Fabricación de ferrocarriles" },

  { name: "Ranching", label: "Ganadería" },

  { name: "Real Estate", label: "Bienes raíces" },

  {
    name: "Recreational Facilities & Services",

    label: "Instalaciones y servicios recreativos",
  },

  { name: "Religious Institutions", label: "Instituciones religiosas" },

  {
    name: "Renewables & Environment",

    label: "Energía renovable y medio ambiente",
  },

  { name: "Repair & Maintenance", label: "Reparación y mantenimiento" },

  { name: "Research", label: "Investigación" },

  {
    name: "Residential Building Construction",

    label: "Construcción de edificios residenciales",
  },

  { name: "Restaurants", label: "Restaurantes" },

  { name: "Retail", label: "Venta minorista" },

  {
    name: "Rubber Products Manufacturing",

    label: "Fabricación de productos de caucho",
  },

  {
    name: "Satellite Telecommunications",

    label: "Telecomunicaciones satelitales",
  },

  { name: "Savings Institutions", label: "Instituciones de ahorro" },

  {
    name: "Seafood Product Manufacturing",

    label: "Fabricación de productos del mar",
  },

  { name: "Secretarial Schools", label: "Escuelas de secretariado" },

  {
    name: "Securities & Commodity Exchanges",

    label: "Bolsas de valores y productos básicos",
  },

  {
    name: "Security Guards & Patrol Services",

    label: "Guardias de seguridad y servicios de patrulla",
  },

  {
    name: "Security Systems Services",

    label: "Servicios de sistemas de seguridad",
  },

  { name: "Security & investigations", label: "Seguridad e investigaciones" },

  { name: "Semiconductors", label: "Semiconductores" },

  {
    name: "Services for the Elderly & Disabled",

    label: "Servicios para personas mayores y discapacitadas",
  },

  { name: "Shipbuilding", label: "Construcción naval" },

  {
    name: "Shuttles & Special Needs Transportation Services",

    label: "Traslados y servicios de transporte para necesidades especiales",
  },

  {
    name: "Social Networking Platforms",

    label: "Plataformas de redes sociales",
  },

  { name: "Software Development", label: "Desarrollo de software" },

  {
    name: "Solar Electric Power Generation",

    label: "Generación de energía eléctrica solar",
  },

  { name: "Sound Recording", label: "Grabación de sonido" },

  {
    name: "Space Research & Technology",

    label: "Investigación y tecnología espacial",
  },

  {
    name: "Specialty Trade Contractors",

    label: "Contratistas comerciales especializados",
  },

  { name: "Spectator Sports", label: "Deportes de espectador" },

  { name: "Sporting Goods", label: "Artículos deportivos" },

  { name: "Sports", label: "Deportes" },

  {
    name: "Spring & Wire Product Manufacturing",

    label: "Fabricación de productos de resortes y alambres",
  },

  {
    name: "Staffing & Recruiting",

    label: "Dotación de personal y reclutamiento",
  },

  {
    name: "Steam & Air-Conditioning Supply",

    label: "Suministro de vapor y aire acondicionado",
  },

  {
    name: "Strategic Management Services",

    label: "Servicios de gestión estratégica",
  },

  { name: "Supermarkets", label: "Supermercados" },

  {
    name: "Technical & Vocational Training",

    label: "Formación técnica y profesional",
  },

  {
    name: "Technology, Information, Media & Internet",

    label: "Tecnología, información, medios e internet",
  },

  { name: "Telecommunications", label: "Telecomunicaciones" },

  { name: "Telephone Call Centers", label: "Centros de llamadas telefónicas" },

  { name: "Temporary Help Services", label: "Servicios de ayuda temporal" },

  { name: "Textiles", label: "Textiles" },

  { name: "Theater Companies", label: "empresas de teatro" },

  { name: "Think Tanks", label: "Grupos de pensamiento" },

  { name: "Tobacco", label: "Tabaco" },

  { name: "Translation & Localization", label: "Traducción y localización" },

  {
    name: "Transportation/Trucking/Railroad",

    label: "Transporte/camión/ferrocarril",
  },

  { name: "Travel Arrangements", label: "Arreglos de viaje" },

  { name: "Truck Transportation", label: "Transporte de camiones" },

  { name: "Trusts & Estates", label: "Fideicomisos y sucesiones" },

  { name: "Urban Transit Services", label: "Servicios de tránsito urbano" },

  { name: "Utilities", label: "Utilidades" },

  {
    name: "Vehicle Repair & Maintenance",

    label: "Reparación y mantenimiento de vehículos",
  },

  {
    name: "Venture Capital & Private Equity",

    label: "Capital de riesgo y capital privado",
  },

  { name: "Veterinary", label: "Veterinaria" },

  {
    name: "Vocational Rehabilitation Services",

    label: "Servicios de rehabilitación vocacional",
  },

  { name: "Warehousing", label: "Almacenamiento" },

  { name: "Warehousing & Storage", label: "Almacenamiento y almacenaje" },

  {
    name: "Waste Collection, Treatment & Disposal",

    label: "Recolección, tratamiento y eliminación de residuos",
  },

  {
    name: "Water Supply & Irrigation Systems",

    label: "Sistemas de suministro de agua y riego",
  },

  {
    name: "Wellness & Fitness Services",

    label: "Servicios de bienestar y fitness",
  },

  { name: "Wholesale", label: "Venta al por mayor" },

  {
    name: "Wind Electric Power Generation",

    label: "Generación de energía eléctrica eólica",
  },

  { name: "Wine & Spirits", label: "Vinos y licores" },

  { name: "Wireless", label: "Inalámbrico" },

  {
    name: "Wood Product Manufacturing",

    label: "Fabricación de productos de madera",
  },

  { name: "Writing & Editing", label: "Escritura y edición" },

  {
    name: "Zoos & Botanical Gardens",

    label: "Zoológicos y jardines botánicos",
  },

  { name: "Other", label: "Otro" },
];

const employmentTypeOptions = [
  {
    name: "0",

    label: "Tiempo completo",
  },

  {
    name: "1",

    label: "Medio tiempo",
  },

  {
    name: "2",

    label: "Independiente",
  },

  {
    name: "3",

    label: "Freelance",
  },

  {
    name: "4",

    label: "Con contrato",
  },

  {
    name: "5",

    label: "En práctica profesional",
  },

  {
    name: "6",

    label: "Aprendiz",
  },

  {
    name: "7",

    label: "Temporal",
  },
];

const languagesOptions = [
  {
    name: "Afrikaans",

    label: "Africano",
  },

  {
    name: "Albanian",

    label: "Albanés",
  },

  {
    name: "Arabic",

    label: "Árabe",
  },

  {
    name: "Armenian",

    label: "Armenio",
  },

  {
    name: "Basque",

    label: "Vasco",
  },

  {
    name: "Bengali",

    label: "Bengalí",
  },

  {
    name: "Bulgarian",

    label: "Búlgaro",
  },

  {
    name: "Catalan",

    label: "Catalán",
  },

  {
    name: "Cambodian",

    label: "Camboyano",
  },

  {
    name: "Chinese",

    label: "Chino",
  },

  {
    name: "Croatian",

    label: "Croata",
  },

  {
    name: "Czech",

    label: "Checo",
  },

  {
    name: "Danish",

    label: "Danés",
  },

  {
    name: "Dutch",

    label: "Holandés",
  },

  {
    name: "English",

    label: "Inglés",
  },

  {
    name: "Estonian",

    label: "Estonio",
  },

  {
    name: "Fiji",

    label: "Fiyi",
  },

  {
    name: "Finnish",

    label: "Finlandés",
  },

  {
    name: "French",

    label: "Francés",
  },

  {
    name: "Georgian",

    label: "Georgiano",
  },

  {
    name: "German",

    label: "Alemán",
  },

  {
    name: "Greek",

    label: "Griego",
  },

  {
    name: "Gujarati",

    label: "Guyaratí",
  },

  {
    name: "Hebrew",

    label: "Hebreo",
  },

  {
    name: "Hindi",

    label: "Hindi",
  },

  {
    name: "Hungarian",

    label: "Húngaro",
  },

  {
    name: "Icelandic",

    label: "Islandés",
  },

  {
    name: "Indonesian",

    label: "Indonesio",
  },

  {
    name: "Irish",

    label: "Irlandés",
  },

  {
    name: "Italian",

    label: "Italiano",
  },

  {
    name: "Japanese",

    label: "Japonés",
  },

  {
    name: "Javanese",

    label: "Javanés",
  },

  {
    name: "Korean",

    label: "Koreano",
  },

  {
    name: "Latin",

    label: "Latín",
  },

  {
    name: "Latvian",

    label: "Letón",
  },

  {
    name: "Lithuanian",

    label: "Lituano",
  },

  {
    name: "Macedonian",

    label: "Macedonio",
  },

  {
    name: "Malay",

    label: "Malayo",
  },

  {
    name: "Malayalam",

    label: "Malayalam",
  },

  {
    name: "Maltese",

    label: "Maltés",
  },

  {
    name: "Maori",

    label: "maorí",
  },

  {
    name: "Marathi",

    label: "Marathi",
  },

  {
    name: "Mongolian",

    label: "Mongol",
  },

  {
    name: "Nepali",

    label: "Nepalí",
  },

  {
    name: "Norwegian",

    label: "Noruego",
  },

  {
    name: "Persian",

    label: "Persa",
  },

  {
    name: "Polish",

    label: "Polaco",
  },

  {
    name: "Portuguese",

    label: "Portugués",
  },

  {
    name: "Punjabi",

    label: "Punjabi",
  },

  {
    name: "Quechua",

    label: "Quechua",
  },

  {
    name: "Romanian",

    label: "Rumano",
  },

  {
    name: "Russian",

    label: "Ruso",
  },

  {
    name: "Samoan",

    label: "Samoano",
  },

  {
    name: "Serbian",

    label: "Serbio",
  },

  {
    name: "Slovak",

    label: "Eslovaco",
  },

  {
    name: "Slovenian",

    label: "Esloveno",
  },

  {
    name: "Spanish",

    label: "Español",
  },

  {
    name: "Swahili",

    label: "Swahili",
  },

  {
    name: "Swedish",

    label: "Sueco",
  },

  {
    name: "Tamil",

    label: "Tamil",
  },

  {
    name: "Tatar",

    label: "Tátaro",
  },

  {
    name: "Telugu",

    label: "Telugu",
  },

  {
    name: "Thai",

    label: "Tailandés",
  },

  {
    name: "Tibetan",

    label: "Tibetano",
  },

  {
    name: "Tonga",

    label: "Tonga",
  },

  {
    name: "Turkish",

    label: "Turco",
  },

  {
    name: "Ukrainian",

    label: "Ucranio",
  },

  {
    name: "Urdu",

    label: "Urdu",
  },

  {
    name: "Uzbek",

    label: "uzbeco",
  },

  {
    name: "Vietnamese",

    label: "vietnamita",
  },

  {
    name: "Welsh",

    label: "Galés",
  },

  {
    name: "Xhosa",

    label: "Xhosa",
  },
];

const numberOfEmployeesOptions = [
  {
    name: "1",

    label: "0 - 5",
  },

  {
    name: "2",

    label: "6 - 10",
  },

  {
    name: "3",

    label: "11 - 50",
  },

  {
    name: "4",

    label: "51 - 200",
  },

  {
    name: "5",

    label: "201 - 500",
  },

  {
    name: "6",

    label: "501 - 1.000",
  },

  {
    name: "7",

    label: "1.001 - 5.000",
  },

  {
    name: "8",

    label: "5.001 - 10.000",
  },

  {
    name: "9",

    label: "10.001 (+)",
  },
];

const revenueRangeOptions = [
  {
    name: "1",

    label: "$0 USD - $1M USD",
  },

  {
    name: "2",

    label: "$1M USD - $10M USD",
  },

  {
    name: "3",

    label: "$10M USD - $50M USD",
  },

  {
    name: "4",

    label: "$50M USD - $100M USD",
  },

  {
    name: "5",

    label: "$100M USD - $250M USD",
  },

  {
    name: "6",

    label: "$250M USD - $500M USD",
  },

  {
    name: "7",

    label: "$500M USD -$1Bs USD",
  },

  {
    name: "8",

    label: "$1B USD -$10B USD",
  },

  {
    name: "9",

    label: "$10B+ USD",
  },
];

const jobDurationOptions = [
  {
    name: "Days",

    label: "Días",
  },

  {
    name: "Months",

    label: "Meses",
  },

  {
    name: "Years",

    label: "Años",
  },

  {
    name: "Indefinite",

    label: "Indefinido",
  },
];

const peronalityTypesList = [
  {
    label: "Inspector - ISTJ",

    name: "At first glance, ISTJs are intimidating. They appear serious, formal, and proper. They also love traditions and old-school values that uphold patience, hard work, honor, and social and cultural responsibility. They are reserved, calm, quiet, and upright. These traits result from the combination of I, S, T, and J, a personality type that is often misunderstood.",
  },

  {
    label: "Consejero - INFJ",

    name: "INFJs are visionaries and idealists who ooze creative imagination and brilliant ideas. They have a different, and usually more profound, way of looking at the world. They have a substance and depth in the way they think, never taking anything at surface level or accepting things the way they are. Others may sometimes perceive them as weird or amusing because of their different outlook on life.",
  },

  {
    label: "Mente Directora - INTJ",

    name: "INTJs, as introverts, are quiet, reserved, and comfortable being alone. They are usually self-sufficient and would rather work alone than in a group. Socializing drains an introvert's energy, causing them to need to recharge. INTJs are interested in ideas and theories. When observing the world they are always questioning why things happen the way they do. They excel at developing plans and strategies, and don't like uncertainty.",
  },

  {
    label: "El Dador - ENFJ",

    name: "ENFJs are people-focused individuals. They are extroverted, idealistic, charismatic, outspoken, highly principled and ethical, and usually know how to connect with others no matter their background or personality. Mainly relying on intuition and feelings, they tend to live in their imagination rather than in the real world. Instead of focusing on living in the “now” and what is currently happening, ENFJs tend to concentrate on the abstract and what could possibly happen in the future.",
  },

  {
    label: "Artesano - ISTP",

    name: "ISTPs are mysterious people who are usually very rational and logical, but also quite spontaneous and enthusiastic. Their personality traits are less easily recognizable than those of other types, and even people who know them well can't always anticipate their reactions. Deep down, ISTPs are spontaneous, unpredictable individuals, but they hide those traits from the outside world, often very successfully.",
  },

  {
    label: "Proveedor - ESFJ",

    name: "ESFJs are the stereotypical extroverts. They are social butterflies, and their need to interact with others and make people happy usually ends up making them popular. The ESFJ usually tends to be the cheerleader or sports hero in high school and college. Later on in life, they continue to revel in the spotlight, and are primarily focused on organizing social events for their families, friends and communities. ESFJ is a common personality type and one that is liked by many people.",
  },

  {
    label: "Idealista - INFP",

    name: "INFPs, like most introverts, are quiet and reserved. They prefer not to talk about themselves, especially in the first encounter with a new person. They like spending time alone in quiet places where they can make sense of what is happening around them. They love analyzing signs and symbols, and consider them to be metaphors that have deeper meanings related to life. They are lost in their imagination and daydreams, always drowned in the depth of their thoughts, fantasies, and ideas.",
  },

  {
    label: "Ejecutante - ESFP",

    name: "ESFPs have an Extraverted, Observant, Feeling and Perceiving personality, and are commonly seen as Entertainers. Born to be in front of others and to capture the stage, ESFPs love the spotlight. ESFPs are thoughtful explorers who love learning and sharing what they learn with others. ESFPs are “people people” with strong interpersonal skills. They are lively and fun, and enjoy being the center of attention. They are warm, generous, and friendly, sympathetic and concerned for other people's well-being.",
  },

  {
    label: "Campeón - ENFP",

    name: "ENFPs have an Extraverted, Intuitive, Feeling and Perceiving personality. This personality type is highly individualistic and Champions strive toward creating their own methods, looks, actions, habits, and ideas — they do not like cookie cutter people and hate when they are forced to live inside a box. They like to be around other people and have a strong intuitive nature when it comes to themselves and others. They operate from their feelings most of the time, and they are highly perceptive and thoughtful.",
  },

  {
    label: "Hacedor - ESTP",

    name: "ESTPs have an Extraverted, Sensing, Thinking, and Perceptive personality. ESTPs are governed by the need for social interaction, feelings and emotions, logical processes and reasoning, along with a need for freedom. Theory and abstracts don't keep ESTP's interested for long. ESTPs leap before they look, fixing their mistakes as they go, rather than sitting idle or preparing contingency plans.",
  },

  {
    label: "Supervisor - ESTJ",

    name: "ESTJs are organized, honest, dedicated, dignified, traditional, and are great believers of doing what they believe is right and socially acceptable. Though the paths towards “good” and “right” are difficult, they are glad to take their place as the leaders of the pack. They are the epitome of good citizenry. People look to ESTJs for guidance and counsel, and ESTJs are always happy that they are approached for help.",
  },

  {
    label: "Comandante - ENTJ",

    name: "An ENTJ's primary mode of living focuses on external aspects and all things are dealt with rationally and logically. Their secondary mode of operation is internal, where intuition and reasoning take effect. ENTJs are natural born leaders among the 16 personality types and like being in charge. They live in a world of possibilities and they often see challenges and obstacles as great opportunities to push themselves. They seem to have a natural gift for leadership, making decisions, and considering options and ideas quickly yet carefully. They are “take charge” people who do not like to sit still.",
  },

  {
    label: "Pensador - INTP",

    name: "INTPs are well known for their brilliant theories and unrelenting logic, which makes sense since they are arguably the most logical minded of all the personality types. They love patterns, have a keen eye for picking up on discrepancies, and a good ability to read people, making it a bad idea to lie to an INTP. People of this personality type aren't interested in practical, day-to-day activities and maintenance, but when they find an environment where their creative genius and potential can be expressed, there is no limit to the time and energy INTPs will expend in developing an insightful and unbiased solution.",
  },

  {
    label: "Cuidador - ISFJ",

    name: "ISFJs are philanthropists and they are always ready to give back and return generosity with even more generosity. The people and things they believe in will be upheld and supported with enthusiasm and unselfishness. ISFJs are warm and kind-hearted. They value harmony and cooperation, and are likely to be very sensitive to other people's feelings. People value the ISFJ for their consideration and awareness, and their ability to bring out the best in others.",
  },

  {
    label: "Visionario - ENTP",

    name: "Those with the ENTP personality are some of the rarest in the world, which is completely understandable. Although they are extroverts, they don't enjoy small talk and may not thrive in many social situations, especially those that involve people who are too different from the ENTP. ENTPs are intelligent and knowledgeable need to be constantly mentally stimulated. They have the ability to discuss theories and facts in extensive detail. They are logical, rational, and objective in their approach to information and arguments.",
  },

  {
    label: "Compositor - ISFP",

    name: "ISFPs are introverts that do not seem like introverts. It is because even if they have difficulties connecting to other people at first, they become warm, approachable, and friendly eventually. They are fun to be with and very spontaneous, which makes them the perfect friend to tag along in whatever activity, regardless if planned or unplanned. ISFPs want to live their life to the fullest and embrace the present, so they make sure they are always out to explore new things and discover new experiences. It is in experience that they find wisdom, so they do see more value in meeting new people than other introverts.",
  },
];

const TrainingProgressStatusOptions = [
  {
    name: "0",

    label: "Entrenamiento en progreso",
  },

  {
    name: "1",

    label: "Entrenamiento completado",
  },
];

const SecurityLevelOptions = [
  {
    name: "0",

    label: "Confidencial",
  },

  {
    name: "1",

    label: "Secreto",
  },

  {
    name: "2",

    label: "Ultra secreto",
  },
];

const SecurityClearanceRenewalOptions = [
  {
    name: "0",

    label: "<1 año",
  },

  {
    name: "1",

    label: "<= 2 años",
  },

  {
    name: "2",

    label: "<= 3 años",
  },

  {
    name: "2",

    label: "<= 4 años",
  },

  {
    name: "2",

    label: "<= 5 años",
  },
];

const CommunicationStatusOptions = [
  {
    name: "0",

    label: "Contactado por la empresa",
  },

  {
    name: "1",

    label: "Talent postulado a la vacante",
  },

  {
    name: "2",

    label: "Conversación en curso",
  },

  {
    name: "3",

    label: "Contratado",
  },

  {
    name: "4",

    label: "Proceso cerrado",
  },
];
const trainingLevelList = [
  {
    name: "New  to Cyber",

    label: "Nuevo en ciber",
  },
  {
    name: "Foundations",

    label: "Fundamentos",
  },
  {
    name: "Introductory",

    label: "Introducción",
  },
  {
    name: "Intermediate",

    label: "Intermedio",
  },
  {
    name: "Advanced",

    label: "Avanzado",
  },
];

const typeTrainingList = [
  {
    name: "Training",
    label: "Entrenamiento",
  },

  {
    name: "Certification",
    label: "Certificación",
  },

  {
    name: "Awareness",
    label: "Concientización",
  },

  {
    name: "Education",
    label: "Educación",
  },
];
const modality = [
  {
    name: "Remote",
    label: "Remoto",
  },

  {
    name: "Hybrid",
    label: "Híbrido",
  },

  {
    name: "On-site",
    label: "Presencial",
  },
];
const Levels = [
  {
    name: 0,
    label: "Nivel básico",
  },

  {
    name: 1,
    label: "Carrera Intermedia",
  },

  {
    name: 2,
    label: "Profesional",
  },
];
const HowToStartCategory = [
  {
    name: "Cybersecurity Intro and Principles",
    label: "Introducción a la ciberseguridad y principios fundamentales",
    code: "CybersecurityIntroandPrinciples",
    description:
      "Si estás interesado en formar parte de la industria de la ciberseguridad, es crucial comprender qué es la ciberseguridad y algunos de sus principios básicos.",
  },

  {
    name: "Frameworks and Regulations",
    label: "Marcos de trabajo y regulaciones",
    code: "FrameworksandRegulations",
    description:
      "La ciberseguridad es un mundo interesante y complejo. Diferentes países e industrias han definido marcos y estándares para comprender y gestionar los riesgos y el ecosistema de ciberseguridad.",
  },

  {
    name: "Areas of Specialization in Cybersecurity",
    label: "Áreas de especialización en ciberseguridad",
    code: "AreasofSpecializationinCybersecurity",
    description:
      "Ahora que has decidido unirte a la fuerza laboral de ciberseguridad, te recomendamos que te informes sobre las diferentes áreas en las que puedes especializarte.",
  },
  {
    name: "Professional Organizations",
    label: "Organizaciones profesionales",
    code: "ProfessionalOrganizations",
    description:
      "Hacer parte de redes de contactos (networking) es una de las mejores formas de informarse sobre noticias y actualizaciones en ciberseguridad, y para identificar lo que más te interesa en la industria.",
  },
  {
    name: "Degrees in Cybersecurity",
    label: "Títulos en ciberseguridad",
    code: "DegreesinCybersecurity",
    description:
      "La educación es fundamental en esta industria. Hay algunos títulos que podrías considerar obtener para mejorar tus conocimientos y adquirir habilidades adicionales.",
  },
  {
    name: "Trainings and Certifications",
    label: "Entrenamientos y certificaciones",
    code: "TrainingsandCertifications",
    description:
      "Los entrenamientos y certificaciones son una excelente manera de profundizar en temas específicos y adquirir nuevas habilidades en ciberseguridad. Aquí tienes una lista de algunos de los más reconocidos y básicps para comenzar tu trayectoria en la industria.",
  },
  {
    name: "IT Assets",
    label: "Activos de IT",
    code: "ITAssets",
    description:
      "Sin tecnología, no necesitaríamos aprender sobre ciberseguridad. Estar interesado en tecnología, tecnología de la información y tecnología operativa puede ir de la mano con tu interés en ciberseguridad. Puedes aprender sobre ciberseguridad sin sumergirte profundamente en las complejidades de la tecnología, pero es bueno entender cómo funcionan las tecnologías y qué activos de IT existen para comprender los riesgos cibernéticos.",
  },
  {
    name: "Stay Informed",
    label: "Mantente Informado",
    code: "StayInformed",
    description:
      "La ciberseguridad es una industria en constante cambio. ¡Mantente al día con todas las noticias, tendencias y temas siguiendo excelentes blogs y otras fuentes!",
  },
  {
    name: "Is Cybersecurity for me?",
    label: "¿Es la ciberseguridad para mí?",
    code: "IsCybersecurityforme",
    description:
      "Aquí te compartimos algunas fuentes que puedes leer para entender si la ciberseguridad es el campo ideal para ti.",
  },
];
const HowToStartCategoryCompany = [
  {
    name: "Cybersecurity Intro and Principles",
    label: "Introducción y principios de ciberseguridad",
    code: "CybersecurityIntroandPrinciples",
    description:
      "Si estás interesado en generar conciencia organizacional sobre ciberseguridad, es crucial entender qué es la ciberseguridad y algunos de sus principios básicos.",
  },
  {
    name: "Frameworks and Regulations",
    label: "Marcos y normativas",
    code: "FrameworksandRegulations",
    description:
      "La ciberseguridad es un mundo interesante y complejo. Diferentes países e industrias han definido marcos y normas para comprender y gestionar los riesgos y el ecosistema de la ciberseguridad.",
  },
  {
    name: "Areas of Specialization in Cybersecurity",
    label: "Áreas de especialización en ciberseguridad",
    code: "AreasofSpecializationinCybersecurity",
    description:
      "Para colaboradores nuevos en el campo de la ciberseguridad, bríndales la oportunidad de explorar diversas áreas especializadas dentro del campo.",
  },
  {
    name: "Professional Organizations",
    label: "Organizaciones profesionales",
    code: "ProfessionalOrganizations",
    description:
      "Trabajar en red es una de las mejores formas de conocer las noticias y actualizaciones sobre ciberseguridad, y de identificar lo que más te interesa del sector.",
  },
  {
    name: "Degrees in Cybersecurity",
    label: "Títulos en Ciberseguridad",
    code: "DegreesinCybersecurity",
    description:
      "La educación es crucial en la industria de la ciberseguridad. Considera recomendar algunos títulos o programas educativos a aquellos colaboradores interesados en unirse a la fuerza laboral de ciberseguridad para mejorar sus conocimientos y adquirir las habilidades necesarias.",
  },
  {
    name: "Trainings and Certifications",
    label: "Entrenamientos y certificaciones",
    code: "TrainingsandCertifications",
    description:
      "Los entrenamientos y certificaciones son una excelente manera de profundizar en temas específicos y adquirir nuevas habilidades en ciberseguridad. Aquí tienes una lista de algunos de los entrenamientos más reconocidos y básicos para compartir con tus equipos digitales y comenzar su trayectoria en ciberseguridad.",
  },
  {
    name: "IT Assets",
    label: "Activos informáticos",
    code: "ITAssets",
    description:
      "Sin tecnología no necesitaríamos aprender sobre ciberseguridad. Interesarse por la tecnología, la informática y la tecnología operativa puede ir de la mano de interesarse por la ciberseguridad. Se puede aprender sobre ciberseguridad sin estar muy metido en los tecnicismos, pero es muy beneficioso entender cómo funcionan las tecnologías y qué activos informáticos existen para comprender los ciberriesgos.",
  },
  {
    name: "Stay Informed",
    label: "Mantente informado",
    code: "StayInformed",
    description:
      "La ciberseguridad es un sector en constante evolución. Mantente al día de todas las noticias, tendencias y temas siguiendo blogs y otras fuentes.",
  },
  {
    name: "Is Cybersecurity for me?",
    label: "¿Es la ciberseguridad para mí?",
    code: "IsCybersecurityforme",
    description:
      "Aquí te presentamos algunas fuentes que puedes compartir con tus colaboradores para entender si la ciberseguridad como carrera profesional es el camino adecuado para ellos.",
  },
];

const CategoriesJobs = [
  {
    name: "Information Security Analyst",
    label: "Analista de Seguridad de la Información:",
    code: "InformationSecurityAnalyst",
    description:
      "Protege los sistemas informáticos y redes de una organización mediante el análisis e implementación de medidas de seguridad para prevenir posibles amenazas.",
  },

  {
    name: "Digital Forensic Examiner  Cyber Crime Analyst: ",
    label:
      "Investigador de Informática Forense / Analista de Crímenes Cibernéticos:",
    code: "DigitalForensicExaminerCyberCrimeAnalyst: ",
    description:
      "Investiga y analiza evidencia digital para rastrear y prevenir delitos cibernéticos, utilizando técnicas forenses para comprender y mitigar incidentes cibernéticos.",
  },

  {
    name: "Cybersecurity Specialist",
    label: "Especialista en Ciberseguridad:",
    code: "CybersecuritySpecialist",
    description:
      "Profesional especializado que aborda diversos aspectos de la ciberseguridad, incluyendo la gestión de riesgos, la respuesta a incidentes y la implementación de medidas de seguridad avanzadas adaptadas a las necesidades de una organización.",
  },
  {
    name: "IT Auditor",
    label: "Auditor de TI:",
    code: "ITAuditor",
    description:
      "Evalúa y garantiza la efectividad de los sistemas informáticos de una organización, examinando controles, políticas y procedimientos para identificar vulnerabilidades y asegurar el cumplimiento de estándares de la industria.",
  },
  {
    name: "Penetration Tester",
    label: "Penetration Tester:",
    code: "PenetrationTester",
    description:
      "Hacker ético que simula ciberataques para evaluar y fortalecer la seguridad de sistemas informáticos y redes, identificando y corrigiendo vulnerabilidades antes de que actores malintencionados puedan aprovecharlas.",
  },
  {
    name: "Secure Software Developer:",
    label: "Desarrollador de Software Seguro:",
    code: "SecureSoftwareDeveloper",
    description:
      "Diseña y crea aplicaciones de software con un enfoque en prácticas de codificación seguras, minimizando vulnerabilidades e implementando medidas para protegerse contra posibles amenazas de seguridad.",
  },
];
const CareerOwner = [
  {
    name: "Planes de carrera Seccuri",
    label: 0,
  },
  {
    name: "Planes de Carrera públicas",
    label: 1,
  },
];

export default {
  openToTravelOptions,
  educationLevelOptions,
  genderOptions,
  desideredEmploymentTypeOptions,
  industryOptions,
  employmentTypeOptions,
  languagesOptions,
  numberOfEmployeesOptions,
  revenueRangeOptions,
  jobDurationOptions,
  SecurityLevelOptions,
  SecurityClearanceRenewalOptions,
  CommunicationStatusOptions,
  TrainingProgressStatusOptions,
  peronalityTypesList,
  trainingLevelList,
  typeTrainingList,
  modality,
  Levels,
  HowToStartCategory,
  HowToStartCategoryCompany,
  CategoriesJobs,
  CareerOwner,
};
