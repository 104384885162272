const openToTravelOptions = [
  {
    name: "0",

    label: "Not specified",
  },

  {
    name: "1",

    label: "0%",
  },

  {
    name: "2",

    label: "25%",
  },

  {
    name: "3",

    label: "50%",
  },

  {
    name: "4",

    label: "75%",
  },

  {
    name: "5",

    label: "100%",
  },
];

const educationLevelOptions = [
  {
    name: "-1",

    label: "Not defined",
  },

  {
    name: "0",

    label: "Less than high school",
  },

  {
    name: "1",

    label: "High school diploma or equivalent",
  },

  {
    name: "2",

    label: "Some college, no degree",
  },

  {
    name: "3",

    label: "Postsecondary non-degree award",
  },

  {
    name: "4",

    label: "Associate’s degree",
  },

  {
    name: "5",

    label: "Bachelor’s degree",
  },

  {
    name: "6",

    label: "Master’s degree",
  },

  {
    name: "7",

    label: "Doctoral or professional degree",
  },
];

const genderOptions = [
  {
    name: "0",

    label: "Female",
  },

  {
    name: "1",

    label: "Male",
  },

  {
    name: "3",

    label: "Other",
  },

  {
    name: "4",

    label: "Prefer not to say",
  },
];

const CommunicationStatusOptions = [
  {
    name: "0",

    label: "Contacted by Company",
  },

  {
    name: "1",

    label: "Talent Applied to Job",
  },

  {
    name: "2",

    label: "Conversation in Progress",
  },

  {
    name: "3",

    label: "Hired",
  },

  {
    name: "4",

    label: "Process Closed",
  },
];

const TrainingProgressStatusOptions = [
  {
    name: "0",

    label: "Training in Progress",
  },

  {
    name: "1",

    label: "Completed Training",
  },
];

const SecurityLevelOptions = [
  {
    name: "0",

    label: "Confidential",
  },

  {
    name: "1",

    label: "Secret",
  },

  {
    name: "2",

    label: "Top Secret",
  },
];

const SecurityClearanceRenewalOptions = [
  {
    name: "0",

    label: "<1 year",
  },

  {
    name: "1",

    label: "<= 2 years",
  },

  {
    name: "2",

    label: "<= 3 years",
  },

  {
    name: "2",

    label: "<= 4 years",
  },

  {
    name: "2",

    label: "<= 5 years",
  },
];

const desideredEmploymentTypeOptions = [
  {
    name: "0",

    label: "Not specified",
  },

  {
    name: "1",

    label: "Internship",
  },

  {
    name: "2",

    label: "Contract",
  },

  {
    name: "3",

    label: "Permanent",
  },

  {
    name: "4",

    label: "Contract to Permanent",
  },

  {
    name: "5",

    label: "Consultant (1099)",
  },

  {
    name: "6",

    label: "Entry-Level",
  },

  {
    name: "7",

    label: "Part-Time",
  },

  {
    name: "8",

    label: "Gig",
  },

  {
    name: "9",

    label: "Temporary Time (E.G. 15 Days, Two Months, One Year)",
  },

  {
    name: "10",

    label: "Temporary Eventual (Services Or Work, Eventual, Substitution)",
  },
];

const industryOptions = [
  { name: "Accounting", label: "Accounting" },

  { name: "Accommodation", label: "Accommodation" },

  { name: "Administration of Justice", label: "Administration of Justice" },

  {
    name: "Administrative & Support Services",

    label: "Administrative & Support Services",
  },

  { name: "Advertising Services", label: "Advertising Services" },

  { name: "Agriculture", label: "Agriculture" },

  {
    name: "Air, Water, & Waste Program Management",

    label: "Air, Water, & Waste Program Management",
  },

  { name: "Airlines/Aviation", label: "Airlines/Aviation" },

  {
    name: "Alternative Dispute Resolution",

    label: "Alternative Dispute Resolution",
  },

  { name: "Alternative Medicine", label: "Alternative Medicine" },

  { name: "Ambulance Services", label: "Ambulance Services" },

  { name: "Amusement Parks & Arcades", label: "Amusement Parks & Arcades" },

  { name: "Animation", label: "Animation" },

  { name: "Apparel & Fashion", label: "Apparel & Fashion" },

  { name: "Apparel Manufacturing", label: "Apparel Manufacturing" },

  { name: "Architecture & Planning", label: "Architecture & Planning" },

  { name: "Armed Forces", label: "Armed Forces" },

  { name: "Arts & Crafts", label: "Arts & Crafts" },

  { name: "Artists & Writers", label: "Artists & Writers" },

  { name: "Automotive", label: "Automotive" },

  { name: "Aviation & Aerospace", label: "Aviation & Aerospace" },

  { name: "Banking", label: "Banking" },

  { name: "Baked Goods Manufacturing", label: "Baked Goods Manufacturing" },

  { name: "Bars, Taverns, & Nightclubs", label: "Bars, Taverns, & Nightclubs" },

  {
    name: "Bed-and-Breakfasts, Hostels, Homestays",

    label: "Bed-and-Breakfasts, Hostels, Homestays",
  },

  { name: "Beverage Manufacturing", label: "Beverage Manufacturing" },

  {
    name: "Biomass Electric Power Generation",

    label: "Biomass Electric Power Generation",
  },

  { name: "Biotechnology", label: "Biotechnology" },

  { name: "Blockchain Services", label: "Blockchain Services" },

  { name: "Blogs", label: "Blogs" },

  {
    name: "Boilers, Tanks, & Shipping Container Manufacturing",

    label: "Boilers, Tanks, & Shipping Container Manufacturing",
  },

  {
    name: "Book & Periodical Publishing",

    label: "Book & Periodical Publishing",
  },

  { name: "Breweries", label: "Breweries" },

  { name: "Broadcast Media", label: "Broadcast Media" },

  { name: "Building Construction", label: "Building Construction" },

  { name: "Building Materials", label: "Building Materials" },

  {
    name: "Business Consulting & Services",

    label: "Business Consulting & Services",
  },

  { name: "Business Content", label: "Business Content" },

  {
    name: "Business Intelligence Platforms",

    label: "Business Intelligence Platforms",
  },

  {
    name: "Business Supplies & Equipment",

    label: "Business Supplies & Equipment",
  },

  {
    name: "Cable & Satellite Programming",

    label: "Cable & Satellite Programming",
  },

  { name: "Capital Markets", label: "Capital Markets" },

  { name: "Caterers", label: "Caterers" },

  { name: "Chemicals", label: "Chemicals" },

  { name: "Child Day Care Services", label: "Child Day Care Services" },

  { name: "Chiropractors", label: "Chiropractors" },

  { name: "Circuses & Magic Shows", label: "Circuses & Magic Shows" },

  {
    name: "Claims Adjusting, Actuarial Services",

    label: "Claims Adjusting, Actuarial Services",
  },

  {
    name: "Clay & Refractory Products Manufacturing",

    label: "Clay & Refractory Products Manufacturing",
  },

  { name: "Civic & Social Organization", label: "Civic & Social Organization" },

  { name: "Civil Engineering", label: "Civil Engineering" },

  { name: "Coal Mining", label: "Coal Mining" },

  { name: "Collection Agencies", label: "Collection Agencies" },

  { name: "Comercial Real Estate", label: "Comercial Real Estate" },

  {
    name: "Commercial & Industrial Equipment Rental",

    label: "Commercial & Industrial Equipment Rental",
  },

  {
    name: "Commercial & Industrial Machinery Maintenance",

    label: "Commercial & Industrial Machinery Maintenance",
  },

  {
    name: "Community Development & Urban Planning",

    label: "Community Development & Urban Planning",
  },

  { name: "Community Services", label: "Community Services" },

  { name: "Computer & Network Security", label: "Computer & Network Security" },

  { name: "Computer Games", label: "Computer Games" },

  { name: "Computer Hardware", label: "Computer Hardware" },

  {
    name: "Computer Hardware Manufacturing",

    label: "Computer Hardware Manufacturing",
  },

  { name: "Computer Networking", label: "Computer Networking" },

  {
    name: "Computer Networking Products",

    label: "Computer Networking Products",
  },

  { name: "Computer Software", label: "Computer Software" },

  {
    name: "Computers & Electronics Manufacturing",

    label: "Computers & Electronics Manufacturing",
  },

  { name: "Conservation Programs", label: "Conservation Programs" },

  { name: "Construction", label: "Construction" },

  {
    name: "Construction Hardware Manufacturing",

    label: "Construction Hardware Manufacturing",
  },

  { name: "Consumer Electronics", label: "Consumer Electronics" },

  { name: "Consumer Goods", label: "Consumer Goods" },

  { name: "Consumer Services", label: "Consumer Services" },

  { name: "Correctional Institutions", label: "Correctional Institutions" },

  { name: "Cosmetics", label: "Cosmetics" },

  { name: "Courts of Law", label: "Courts of Law" },

  { name: "Credit Intermediation", label: "Credit Intermediation" },

  { name: "Dairy", label: "Dairy" },

  { name: "Dance Companies", label: "Dance Companies" },

  {
    name: "Data Infrastructure & Analytics",

    label: "Data Infrastructure & Analytics",
  },

  {
    name: "Data Security Software Products",

    label: "Data Security Software Products",
  },

  { name: "Defense & Space", label: "Defense & Space" },

  { name: "Dentists", label: "Dentists" },

  { name: "Design", label: "Design" },

  {
    name: "Desktop Computing Software Products",

    label: "Desktop Computing Software Products",
  },

  { name: "Distilleries", label: "Distilleries" },

  { name: "E-learning", label: "E-learning" },

  { name: "Education Management", label: "Education Management" },

  {
    name: "Electrical & Electronic Manufacturing",

    label: "Electrical & Electronic Manufacturing",
  },

  { name: "Entertainment", label: "Entertainment" },

  { name: "Equipment Rental Services", label: "Equipment Rental Services" },

  { name: "Environmental Services", label: "Environmental Services" },

  { name: "Events Services", label: "Events Services" },

  { name: "Executive Office", label: "Executive Office" },

  { name: "Executive Search Services", label: "Executive Search Services" },

  { name: "Fabricated Metal Products", label: "Fabricated Metal Products" },

  { name: "Facilities Services", label: "Facilities Services" },

  { name: "Family Planning Centers", label: "Family Planning Centers" },

  { name: "Farming", label: "Farming" },

  { name: "Farming, Ranching, Forestry", label: "Farming, Ranching, Forestry" },

  {
    name: "Fashion Accessories Manufacturing",

    label: "Fashion Accessories Manufacturing",
  },

  { name: "Financial Services", label: "Financial Services" },

  { name: "Fire Protection", label: "Fire Protection" },

  { name: "Fine Art", label: "Fine Art" },

  { name: "Flight Training", label: "Flight Training" },

  { name: "Fishery", label: "Fishery" },

  { name: "Food & Beverages", label: "Food & Beverages" },

  { name: "Food Production", label: "Food Production" },

  { name: "Footwear Manufacturing", label: "Footwear Manufacturing" },

  { name: "Forestry & Logging", label: "Forestry & Logging" },

  {
    name: "Fossil Fuel Electric Power Generation",

    label: "Fossil Fuel Electric Power Generation",
  },

  {
    name: "Freight & Package Transportation",

    label: "Freight & Package Transportation",
  },

  {
    name: "Fruit & Vegetable Preserves Manufacturing",

    label: "Fruit & Vegetable Preserves Manufacturing",
  },

  { name: "Fundraising", label: "Fundraising" },

  { name: "Funds & Trusts", label: "Funds & Trusts" },

  { name: "Furniture", label: "Furniture" },

  { name: "Gambling & Casinos", label: "Gambling & Casinos" },

  {
    name: "Geothermal Electric Power Generation",

    label: "Geothermal Electric Power Generation",
  },

  { name: "Glass, Ceramics & Concrete", label: "Glass, Ceramics & Concrete" },

  {
    name: "Golf Courses & Country Clubs",

    label: "Golf Courses & Country Clubs",
  },

  { name: "Government Administration", label: "Government Administration" },

  { name: "Government Relations", label: "Government Relations" },

  { name: "Graphic Design", label: "Graphic Design" },

  {
    name: "Ground Passenger Transportation",

    label: "Ground Passenger Transportation",
  },

  { name: "Health, Wellness & Fitness", label: "Health, Wellness & Fitness" },

  { name: "Higher Education", label: "Higher Education" },

  {
    name: "Highway, Street, & Bridge Construction",

    label: "Highway, Street, & Bridge Construction",
  },

  { name: "Historical Sites", label: "Historical Sites" },

  { name: "Holding Companies", label: "Holding Companies" },

  { name: "Horticulture", label: "Horticulture" },

  { name: "Hospital & Health Care", label: "Hospital & Health Care" },

  { name: "Hospitality Human Resources", label: "Hospitality Human Resources" },

  { name: "Hotels & Motels", label: "Hotels & Motels" },

  { name: "Household Services", label: "Household Services" },

  {
    name: "Housing & Community Development",

    label: "Housing & Community Development",
  },

  { name: "Housing Programs", label: "Housing Programs" },

  {
    name: "Hydroelectric Power Generation",

    label: "Hydroelectric Power Generation",
  },

  { name: "Import & Export", label: "Import & Export" },

  {
    name: "Individual & Family Services",

    label: "Individual & Family Services",
  },

  { name: "Industrial Automation", label: "Industrial Automation" },

  {
    name: "Industrial Machinery Manufacturing",

    label: "Industrial Machinery Manufacturing",
  },

  { name: "Industry Associations", label: "Industry Associations" },

  { name: "Information Services", label: "Information Services" },

  {
    name: "Information Technology & Services",

    label: "Information Technology & Services",
  },

  { name: "Insurance", label: "Insurance" },

  { name: "Interior Design", label: "Interior Design" },

  { name: "International Affairs", label: "International Affairs" },

  {
    name: "International Trade & Development",

    label: "International Trade & Development",
  },

  { name: "Internet", label: "Internet" },

  {
    name: "Interurban & Rural Bus Services",

    label: "Interurban & Rural Bus Services",
  },

  { name: "Investment Advice", label: "Investment Advice" },

  { name: "Investment Banking", label: "Investment Banking" },

  { name: "Investment Management", label: "Investment Management" },

  { name: "IT Services & IT Consulting", label: "IT Services & IT Consulting" },

  {
    name: "IT System Custom Software Development",

    label: "IT System Custom Software Development",
  },

  { name: "IT System Data Services", label: "IT System Data Services" },

  { name: "IT System Design Services", label: "IT System Design Services" },

  {
    name: "IT System Installation & Disposal",

    label: "IT System Installation & Disposal",
  },

  {
    name: "IT System Operations & Maintenance",

    label: "IT System Operations & Maintenance",
  },

  {
    name: "IT System Testing & Evaluation",

    label: "IT System Testing & Evaluation",
  },

  {
    name: "IT System Training & Support",

    label: "IT System Training & Support",
  },

  { name: "Janitorial Services", label: "Janitorial Services" },

  { name: "Judiciary", label: "Judiciary" },

  { name: "Landscaping Services", label: "Landscaping Services" },

  { name: "Language Schools", label: "Language Schools" },

  {
    name: "Laundry & Drycleaning Services",

    label: "Laundry & Drycleaning Services",
  },

  { name: "Law Enforcement", label: "Law Enforcement" },

  { name: "Law Practice", label: "Law Practice" },

  {
    name: "Leasing Residential/Non-residential Real Estate",

    label: "Leasing Residential/Non-residential Real Estate",
  },

  { name: "Legal Services", label: "Legal Services" },

  { name: "Legislative Office", label: "Legislative Office" },

  { name: "Leisure, Travel & Tourism", label: "Leisure, Travel & Tourism" },

  { name: "Libraries", label: "Libraries" },

  { name: "Loan Brokers", label: "Loan Brokers" },

  { name: "Logistics & Supply Chain", label: "Logistics & Supply Chain" },

  { name: "Luxury Goods & Jewelry", label: "Luxury Goods & Jewelry" },

  { name: "Machinery", label: "Machinery" },

  { name: "Management Consulting", label: "Management Consulting" },

  { name: "Manufacturing", label: "Manufacturing" },

  { name: "Maritime", label: "Maritime" },

  { name: "Market Research", label: "Market Research" },

  { name: "Marketing & Advertising", label: "Marketing & Advertising" },

  {
    name: "Mechanical Or Industrial Engineering",

    label: "Mechanical Or Industrial Engineering",
  },

  {
    name: "Media Production Medical Production",

    label: "Media Production Medical Production",
  },

  { name: "Media & Telecommunications", label: "Media & Telecommunications" },

  { name: "Medical Device", label: "Medical Device" },

  { name: "Medical Practice", label: "Medical Practice" },

  {
    name: "Medical & Diagnostic Laboratories",

    label: "Medical & Diagnostic Laboratories",
  },

  { name: "Mental Health Care", label: "Mental Health Care" },

  { name: "Metal Treatments", label: "Metal Treatments" },

  {
    name: "Metalworking Machinery Manufacturing",

    label: "Metalworking Machinery Manufacturing",
  },

  { name: "Military", label: "Military" },

  { name: "Mining & Metals", label: "Mining & Metals" },

  {
    name: "Mobile Computing Software Products",

    label: "Mobile Computing Software Products",
  },

  { name: "Mobile Food Services", label: "Mobile Food Services" },

  { name: "Mobile Games", label: "Mobile Games" },

  { name: "Motion Pictures & Film", label: "Motion Pictures & Film" },

  { name: "Motor Vehicle Manufacturing", label: "Motor Vehicle Manufacturing" },

  { name: "Museums & Institutions", label: "Museums & Institutions" },

  { name: "Music", label: "Music" },

  { name: "Nanotechnology", label: "Nanotechnology" },

  {
    name: "Natural Gas Extraction & Distribution",

    label: "Natural Gas Extraction & Distribution",
  },

  { name: "Newspapers", label: "Newspapers" },

  { name: "Nonmetallic Mineral Mining", label: "Nonmetallic Mineral Mining" },

  {
    name: "Non-profit Organization Management",

    label: "Non-profit Organization Management",
  },

  {
    name: "Nonresidential Building Construction",

    label: "Nonresidential Building Construction",
  },

  {
    name: "Nuclear Electric Power Generation",

    label: "Nuclear Electric Power Generation",
  },

  {
    name: "Nursing Homes & Residential Care Facilities",

    label: "Nursing Homes & Residential Care Facilities",
  },

  { name: "Office Administration", label: "Office Administration" },

  {
    name: "Office Furniture & Fixtures Manufacturing",

    label: "Office Furniture & Fixtures Manufacturing",
  },

  { name: "Oil & Energy", label: "Oil & Energy" },

  { name: "Online Media", label: "Online Media" },

  { name: "Online & Mail Order Retail", label: "Online & Mail Order Retail" },

  { name: "Operations Consulting", label: "Operations Consulting" },

  { name: "Optometrists", label: "Optometrists" },

  { name: "Outpatient Care Centers", label: "Outpatient Care Centers" },

  { name: "Outsourcing/offshoring", label: "Outsourcing/offshoring" },

  { name: "Package/Freight Delivery", label: "Package/Freight Delivery" },

  { name: "Packaging & Containers", label: "Packaging & Containers" },

  {
    name: "Paint, Coating, & Adhesive Manufacturing",

    label: "Paint, Coating, & Adhesive Manufacturing",
  },

  { name: "Paper & Forest Products", label: "Paper & Forest Products" },

  { name: "Pension Funds", label: "Pension Funds" },

  { name: "Performing Arts", label: "Performing Arts" },

  { name: "Periodical Publishing", label: "Periodical Publishing" },

  { name: "Personal & Laundry Services", label: "Personal & Laundry Services" },

  {
    name: "Personal Care Product Manufacturing",

    label: "Personal Care Product Manufacturing",
  },

  { name: "Personal Care Services", label: "Personal Care Services" },

  { name: "Pet Services", label: "Pet Services" },

  { name: "Pharmaceuticals", label: "Pharmaceuticals" },

  {
    name: "Pharmaceutical Manufacturing",

    label: "Pharmaceutical Manufacturing",
  },

  { name: "Philanthropy", label: "Philanthropy" },

  { name: "Photography", label: "Photography" },

  { name: "Physicians", label: "Physicians" },

  { name: "Pipeline Transportation", label: "Pipeline Transportation" },

  { name: "Plastics", label: "Plastics" },

  { name: "Political Organization", label: "Political Organization" },

  { name: "Postal Services", label: "Postal Services" },

  { name: "Primary Metal Manufacturing", label: "Primary Metal Manufacturing" },

  { name: "Primary/Secondary Education", label: "Primary/Secondary Education" },

  { name: "Printing", label: "Printing" },

  { name: "Professional Organizations", label: "Professional Organizations" },

  { name: "Professional Services", label: "Professional Services" },

  {
    name: "Professional Training & Coaching",

    label: "Professional Training & Coaching",
  },

  { name: "Program Development", label: "Program Development" },

  { name: "Public Assistance Programs", label: "Public Assistance Programs" },

  { name: "Public Health", label: "Public Health" },

  { name: "Public Policy", label: "Public Policy" },

  {
    name: "Public Relations & Communications",

    label: "Public Relations & Communications",
  },

  { name: "Public Safety", label: "Public Safety" },

  { name: "Publishing", label: "Publishing" },

  { name: "Racetracks", label: "Racetracks" },

  {
    name: "Radio & Television Broadcasting",

    label: "Radio & Television Broadcasting",
  },

  { name: "Railroad Manufacture", label: "Railroad Manufacture" },

  { name: "Ranching", label: "Ranching" },

  { name: "Real Estate", label: "Real Estate" },

  {
    name: "Recreational Facilities & Services",

    label: "Recreational Facilities & Services",
  },

  { name: "Religious Institutions", label: "Religious Institutions" },

  { name: "Renewables & Environment", label: "Renewables & Environment" },

  { name: "Repair & Maintenance", label: "Repair & Maintenance" },

  { name: "Research", label: "Research" },

  {
    name: "Residential Building Construction",

    label: "Residential Building Construction",
  },

  { name: "Restaurants", label: "Restaurants" },

  { name: "Retail", label: "Retail" },

  {
    name: "Rubber Products Manufacturing",

    label: "Rubber Products Manufacturing",
  },

  {
    name: "Satellite Telecommunications",

    label: "Satellite Telecommunications",
  },

  { name: "Savings Institutions", label: "Savings Institutions" },

  {
    name: "Seafood Product Manufacturing",

    label: "Seafood Product Manufacturing",
  },

  { name: "Secretarial Schools", label: "Secretarial Schools" },

  {
    name: "Securities & Commodity Exchanges",

    label: "Securities & Commodity Exchanges",
  },

  {
    name: "Security Guards & Patrol Services",

    label: "Security Guards & Patrol Services",
  },

  { name: "Security Systems Services", label: "Security Systems Services" },

  { name: "Security & investigations", label: "Security & investigations" },

  { name: "Semiconductors", label: "Semiconductors" },

  {
    name: "Services for the Elderly & Disabled",

    label: "Services for the Elderly & Disabled",
  },

  { name: "Shipbuilding", label: "Shipbuilding" },

  {
    name: "Shuttles & Special Needs Transportation Services",

    label: "Shuttles & Special Needs Transportation Services",
  },

  { name: "Social Networking Platforms", label: "Social Networking Platforms" },

  { name: "Software Development", label: "Software Development" },

  {
    name: "Solar Electric Power Generation",

    label: "Solar Electric Power Generation",
  },

  { name: "Sound Recording", label: "Sound Recording" },

  { name: "Space Research & Technology", label: "Space Research & Technology" },

  { name: "Specialty Trade Contractors", label: "Specialty Trade Contractors" },

  { name: "Spectator Sports", label: "Spectator Sports" },

  { name: "Sporting Goods", label: "Sporting Goods" },

  { name: "Sports", label: "Sports" },

  {
    name: "Spring & Wire Product Manufacturing",

    label: "Spring & Wire Product Manufacturing",
  },

  { name: "Staffing & Recruiting", label: "Staffing & Recruiting" },

  {
    name: "Steam & Air-Conditioning Supply",

    label: "Steam & Air-Conditioning Supply",
  },

  {
    name: "Strategic Management Services",

    label: "Strategic Management Services",
  },

  { name: "Supermarkets", label: "Supermarkets" },

  {
    name: "Technical & Vocational Training",

    label: "Technical & Vocational Training",
  },

  {
    name: "Technology, Information, Media & Internet",

    label: "Technology, Information, Media & Internet",
  },

  { name: "Telecommunications", label: "Telecommunications" },

  { name: "Telephone Call Centers", label: "Telephone Call Centers" },

  { name: "Temporary Help Services", label: "Temporary Help Services" },

  { name: "Textiles", label: "Textiles" },

  { name: "Theater Companies", label: "Theater Companies" },

  { name: "Think Tanks", label: "Think Tanks" },

  { name: "Tobacco", label: "Tobacco" },

  { name: "Translation & Localization", label: "Translation & Localization" },

  {
    name: "Transportation/Trucking/Railroad",

    label: "Transportation/Trucking/Railroad",
  },

  { name: "Travel Arrangements", label: "Travel Arrangements" },

  { name: "Truck Transportation", label: "Truck Transportation" },

  { name: "Trusts & Estates", label: "Trusts & Estates" },

  { name: "Urban Transit Services", label: "Urban Transit Services" },

  { name: "Utilities", label: "Utilities" },

  {
    name: "Vehicle Repair & Maintenance",

    label: "Vehicle Repair & Maintenance",
  },

  {
    name: "Venture Capital & Private Equity",

    label: "Venture Capital & Private Equity",
  },

  { name: "Veterinary", label: "Veterinary" },

  {
    name: "Vocational Rehabilitation Services",

    label: "Vocational Rehabilitation Services",
  },

  { name: "Warehousing", label: "Warehousing" },

  { name: "Warehousing & Storage", label: "Warehousing & Storage" },

  {
    name: "Waste Collection, Treatment & Disposal",

    label: "Waste Collection, Treatment & Disposal",
  },

  {
    name: "Water Supply & Irrigation Systems",

    label: "Water Supply & Irrigation Systems",
  },

  { name: "Wellness & Fitness Services", label: "Wellness & Fitness Services" },

  { name: "Wholesale", label: "Wholesale" },

  {
    name: "Wind Electric Power Generation",

    label: "Wind Electric Power Generation",
  },

  { name: "Wine & Spirits", label: "Wine & Spirits" },

  { name: "Wireless", label: "Wireless" },

  { name: "Wood Product Manufacturing", label: "Wood Product Manufacturing" },

  { name: "Writing & Editing", label: "Writing & Editing" },

  { name: "Zoos & Botanical Gardens", label: "Zoos & Botanical Gardens" },

  { name: "Other", label: "Other" },
];

const employmentTypeOptions = [
  {
    name: "0",

    label: "Full-Time",
  },

  {
    name: "1",

    label: "Part-Time",
  },

  {
    name: "2",

    label: "Self-Employed",
  },

  {
    name: "3",

    label: "Freelance",
  },

  {
    name: "4",

    label: "Contract",
  },

  {
    name: "5",

    label: "Internship",
  },

  {
    name: "6",

    label: "Apprenticeship",
  },

  {
    name: "7",

    label: "Seasonal",
  },
];

const languagesOptions = [
  {
    name: "Afrikaans",

    label: "Afrikaans",
  },

  {
    name: "Albanian",

    label: "Albanian",
  },

  {
    name: "Arabic",

    label: "Arabic",
  },

  {
    name: "Armenian",

    label: "Armenian",
  },

  {
    name: "Basque",

    label: "Basque",
  },

  {
    name: "Bengali",

    label: "Bengali",
  },

  {
    name: "Bulgarian",

    label: "Bulgarian",
  },

  {
    name: "Catalan",

    label: "Catalan",
  },

  {
    name: "Cambodian",

    label: "Cambodian",
  },

  {
    name: "Chinese",

    label: "Chinese",
  },

  {
    name: "Croatian",

    label: "Croatian",
  },

  {
    name: "Czech",

    label: "Czech",
  },

  {
    name: "Danish",

    label: "Danish",
  },

  {
    name: "Dutch",

    label: "Dutch",
  },

  {
    name: "English",

    label: "English",
  },

  {
    name: "Estonian",

    label: "Estonian",
  },

  {
    name: "Fiji",

    label: "Fiji",
  },

  {
    name: "Finnish",

    label: "Finnish",
  },

  {
    name: "French",

    label: "French",
  },

  {
    name: "Georgian",

    label: "Georgian",
  },

  {
    name: "German",

    label: "German",
  },

  {
    name: "Greek",

    label: "Greek",
  },

  {
    name: "Gujarati",

    label: "Gujarati",
  },

  {
    name: "Hebrew",

    label: "Hebrew",
  },

  {
    name: "Hindi",

    label: "Hindi",
  },

  {
    name: "Hungarian",

    label: "Hungarian",
  },

  {
    name: "Icelandic",

    label: "Icelandic",
  },

  {
    name: "Indonesian",

    label: "Indonesian",
  },

  {
    name: "Irish",

    label: "Irish",
  },

  {
    name: "Italian",

    label: "Italian",
  },

  {
    name: "Japanese",

    label: "Japanese",
  },

  {
    name: "Javanese",

    label: "Javanese",
  },

  {
    name: "Korean",

    label: "Korean",
  },

  {
    name: "Latin",

    label: "Latin",
  },

  {
    name: "Latvian",

    label: "Latvian",
  },

  {
    name: "Lithuanian",

    label: "Lithuanian",
  },

  {
    name: "Macedonian",

    label: "Macedonian",
  },

  {
    name: "Malay",

    label: "Malay",
  },

  {
    name: "Malayalam",

    label: "Malayalam",
  },

  {
    name: "Maltese",

    label: "Maltese",
  },

  {
    name: "Māori",

    label: "Māori",
  },

  {
    name: "Marathi",

    label: "Marathi",
  },

  {
    name: "Mongolian",

    label: "Mongolian",
  },

  {
    name: "Nepali",

    label: "Nepali",
  },

  {
    name: "Norwegian",

    label: "Norwegian",
  },

  {
    name: "Persian",

    label: "Persian",
  },

  {
    name: "Polish",

    label: "Polish",
  },

  {
    name: "Portuguese",

    label: "Portuguese",
  },

  {
    name: "Punjabi",

    label: "Punjabi",
  },

  {
    name: "Quechua",

    label: "Quechua",
  },

  {
    name: "Romanian",

    label: "Romanian",
  },

  {
    name: "Russian",

    label: "Russian",
  },

  {
    name: "Samoan",

    label: "Samoan",
  },

  {
    name: "Serbian",

    label: "Serbian",
  },

  {
    name: "Slovak",

    label: "Slovak",
  },

  {
    name: "Slovenian",

    label: "Slovenian",
  },

  {
    name: "Spanish",

    label: "Spanish",
  },

  {
    name: "Swahili",

    label: "Swahili",
  },

  {
    name: "Swedish",

    label: "Swedish",
  },

  {
    name: "Tamil",

    label: "Tamil",
  },

  {
    name: "Tatar",

    label: "Tatar",
  },

  {
    name: "Telugu",

    label: "Telugu",
  },

  {
    name: "Thai",

    label: "Thai",
  },

  {
    name: "Tibetan",

    label: "Tibetan",
  },

  {
    name: "Tonga",

    label: "Tonga",
  },

  {
    name: "Turkish",

    label: "Turkish",
  },

  {
    name: "Ukrainian",

    label: "Ukrainian",
  },

  {
    name: "Urdu",

    label: "Urdu",
  },

  {
    name: "Uzbek",

    label: "Uzbek",
  },

  {
    name: "Vietnamese",

    label: "Vietnamese",
  },

  {
    name: "Welsh",

    label: "Welsh",
  },

  {
    name: "Xhosa",

    label: "Xhosa",
  },
];

const numberOfEmployeesOptions = [
  {
    name: "1",

    label: "0 - 5",
  },

  {
    name: "2",

    label: "6 - 10",
  },

  {
    name: "3",

    label: "11 - 50",
  },

  {
    name: "4",

    label: "51 - 200",
  },

  {
    name: "5",

    label: "201 - 500",
  },

  {
    name: "6",

    label: "501 - 1.000",
  },

  {
    name: "7",

    label: "1.001 - 5.000",
  },

  {
    name: "8",

    label: "5.001 - 10.000",
  },

  {
    name: "9",

    label: "10.001 (+)",
  },
];

const revenueRangeOptions = [
  {
    name: "1",

    label: "$0-$1M",
  },

  {
    name: "2",

    label: "$1M-$10M",
  },

  {
    name: "3",

    label: "$10M-$50M",
  },

  {
    name: "4",

    label: "$50M-$100M",
  },

  {
    name: "5",

    label: "$100M-$250M",
  },

  {
    name: "6",

    label: "$250M-$500M",
  },

  {
    name: "7",

    label: "$500M-$1Bs",
  },

  {
    name: "8",

    label: "$1B-$10B",
  },

  {
    name: "9",

    label: "$10B+",
  },
];

const jobDurationOptions = [
  {
    name: "Days",

    label: "Days",
  },

  {
    name: "Months",

    label: "Months",
  },

  {
    name: "Years",

    label: "Years",
  },

  {
    name: "Indefinite",

    label: "Indefinite",
  },
];

const peronalityTypesList = [
  {
    label: "Inspector - ISTJ",

    name: "At first glance, ISTJs are intimidating. They appear serious, formal, and proper. They also love traditions and old-school values that uphold patience, hard work, honor, and social and cultural responsibility. They are reserved, calm, quiet, and upright. These traits result from the combination of I, S, T, and J, a personality type that is often misunderstood.",
  },

  {
    label: "Counselor - INFJ",

    name: "INFJs are visionaries and idealists who ooze creative imagination and brilliant ideas. They have a different, and usually more profound, way of looking at the world. They have a substance and depth in the way they think, never taking anything at surface level or accepting things the way they are. Others may sometimes perceive them as weird or amusing because of their different outlook on life.",
  },

  {
    label: "Mastermind - INTJ",

    name: "INTJs, as introverts, are quiet, reserved, and comfortable being alone. They are usually self-sufficient and would rather work alone than in a group. Socializing drains an introvert's energy, causing them to need to recharge. INTJs are interested in ideas and theories. When observing the world, they are always questioning why things happen the way they do. They excel at developing plans and strategies, and don't like uncertainty.",
  },

  {
    label: "Giver - ENFJ",

    name: "ENFJs are people-focused individuals. They are extroverted, idealistic, charismatic, outspoken, highly principled, and ethical, and usually know how to connect with others no matter their background or personality. Mainly relying on intuition and feelings, they tend to live in their imagination rather than in the real world. Instead of focusing on living in the “now” and what is currently happening, ENFJs tend to concentrate on the abstract and what could possibly happen in the future.",
  },

  {
    label: "Craftsman - ISTP",

    name: "ISTPs are mysterious people who are usually very rational and logical, but also quite spontaneous and enthusiastic. Their personality traits are less easily recognizable than those of other types, and even people who know them well can't always anticipate their reactions. Deep down, ISTPs are spontaneous, unpredictable individuals, but they hide those traits from the outside world, often very successfully.",
  },

  {
    label: "Provider - ESFJ",

    name: "ESFJs are stereotypical extroverts. They are social butterflies, and their need to interact with others and make people happy usually ends up making them popular. The ESFJ usually tends to be the cheerleader or sports hero in high school and college. Later in life, they continue to revel in the spotlight and are focused on organizing social events for their families, friends, and communities. ESFJ is a common personality type and one that is liked by many people.",
  },

  {
    label: "Idealist - INFP",

    name: "INFPs, like most introverts, are quiet and reserved. They prefer not to talk about themselves, especially in the first encounter with a new person. They like spending time alone in quiet places where they can make sense of what is happening around them. They love analyzing signs and symbols and consider them to be metaphors that have deeper meanings related to life. They are lost in their imagination and daydreams, always drowned in the depth of their thoughts, fantasies, and ideas.",
  },

  {
    label: "Performer - ESFP",

    name: "ESFPs have an Extraverted, Observant, Feeling and Perceiving personality, and are commonly seen as Entertainers. Born to be in front of others and to capture the stage, ESFPs love the spotlight. ESFPs are thoughtful explorers who love learning and sharing what they learn with others. ESFPs are “people” with strong interpersonal skills. They are lively, fun and enjoy being the center of attention. They are warm, generous, and friendly, sympathetic, and concerned for other people's well-being.",
  },

  {
    label: "Champion - ENFP",

    name: "ENFPs have an Extraverted, Intuitive, Feeling and Perceiving personality. This personality type is highly individualistic, and Champions strive toward creating their own methods, looks, actions, habits, and ideas — they do not like cookie cutter people and hate when they are forced to live inside a box. They like to be around other people and have a strong intuitive nature when it comes to themselves and others. They operate from their feelings most of the time, and they are highly perceptive and thoughtful.",
  },

  {
    label: "Doer - ESTP",

    name: "ESTPs have an Extraverted, Sensing, Thinking, and Perceptive personality. ESTPs are governed by the need for social interaction, feelings and emotions, logical processes, and reasoning, along with a need for freedom. Theory and abstracts don't keep ESTP's interested in long. ESTPs leap before they look, fixing their mistakes as they go, rather than sitting idle or preparing contingency plans.",
  },

  {
    label: "Supervisor - ESTJ",

    name: "ESTJs are organized, honest, dedicated, dignified, traditional, and are great believers of doing what they believe is right and socially acceptable. Though the paths towards “good” and “right” are difficult, they are glad to take their place as the leaders of the pack. They are the epitome of good citizenry. People look to ESTJs for guidance and counsel, and ESTJs are always happy that they are approached for help.",
  },

  {
    label: "Commander - ENTJ",

    name: "An ENTJ's primary mode of living focuses on external aspects and all things are dealt with rationally and logically. Their secondary mode of operation is internal, where intuition and reasoning take effect. ENTJs are natural born leaders among the 16 personality types and like being in charge. They live in a world of possibilities, and they often see challenges and obstacles as great opportunities to push themselves. They seem to have a natural gift for leadership, making decisions, and considering options and ideas quickly yet carefully. They are “take charge” people who do not like to sit still.",
  },

  {
    label: "Thinker - INTP",

    name: "INTPs are well known for their brilliant theories and unrelenting logic, which makes sense since they are arguably the most logical minded of all the personality types. They love patterns, have a keen eye for noticing discrepancies, and a good ability to read people, making it a bad idea to lie to an INTP. People of this personality type aren't interested in practical, day-to-day activities and maintenance, but when they find an environment where their creative genius and potential can be expressed, there is no limit to the time and energy INTPs will expend in developing an insightful and unbiased solution.",
  },

  {
    label: "Nurturer - ISFJ",

    name: "ISFJs are philanthropists, and they are always ready to give back and return generosity with even more generosity. The people and things they believe in will be upheld and supported with enthusiasm and unselfishness. ISFJs are warm and kind-hearted. They value harmony and cooperation and are likely to be extremely sensitive to other people's feelings. People value the ISFJ for their consideration and awareness, and their ability to bring out the best in others.",
  },

  {
    label: "Visionary - ENTP",

    name: "Those with the ENTP personality are some of the rarest in the world, which is completely understandable. Although they are extroverts, they don't enjoy small talk and may not thrive in many social situations, especially those that involve people who are too different from the ENTP. ENTPs are intelligent and knowledgeable and need to be constantly mentally stimulated. They have the ability to discuss theories and facts in extensive detail. They are logical, rational, and objective in their approach to information and arguments.",
  },

  {
    label: "Composer - ISFP",

    name: "ISFPs are introverts that do not seem like introverts. It is because even if they have difficulties connecting to other people at first, they become warm, approachable, and friendly eventually. They are fun to be with and very spontaneous, which makes them the perfect friend to tag along in whatever activity, regardless of if planned or unplanned. ISFPs want to live their life to the fullest and embrace the present, so they make sure they are always out to explore new things and discover new experiences. It is in experience that they find wisdom, so they do see more value in meeting new people than other introverts.",
  },
];
const trainingLevelList = [
  {
    name: "New to Cyber",

    label: "New To Cyber",
  },
  {
    name: "Foundations",

    label: "Foundations",
  },
  {
    name: "Introductory",

    label: "Introductory",
  },
  {
    name: "Intermediate",

    label: "Intermediate",
  },
  {
    name: "Advanced",

    label: "Advanced",
  },
];
const typeTrainingList = [
  {
    name: "Training",
    label: "Training",
  },

  {
    name: "Certification",
    label: "Certification",
  },

  {
    name: "Awareness",
    label: "Awareness",
  },

  {
    name: "Education",
    label: "Education",
  },
];
const modality = [
  {
    name: "Remote",
    label: "Remote",
  },

  {
    name: "Hybrid",
    label: "Hybrid",
  },

  {
    name: "On-site",
    label: "On-site",
  },
];
const Levels = [
  {
    name: 0,
    label: "Entry-Level",
  },

  {
    name: 1,
    label: "Mid-Career",
  },

  {
    name: 2,
    label: "Professional",
  },
];

const HowToStartCategory = [
  {
    name: "Cybersecurity Intro and Principles",
    label: "Cybersecurity Introduction and Principles",
    code: "CybersecurityIntroandPrinciples",
    description:
      "If you are interested in being part of the cybersecurity industry, it is crucial to understand what cybersecurity is and a few of its basic principles.",
  },

  {
    name: "Frameworks and Regulations",
    label: "Frameworks and Regulations",
    code: "FrameworksandRegulations",
    description:
      "Cybersecurity is an interesting and complex world. Frameworks and Standards have been defined by different countries and industries to understand and manage cybersecurity risks and ecosystem.",
  },

  {
    name: "Areas of Specialization in Cybersecurity",
    label: "Areas of Specialization in Cybersecurity",
    code: "AreasofSpecializationinCybersecurity",
    description:
      "Now that you have decided to join the cybersecurity workforce, we recommend you read up on the different areas you can specialize in.",
  },
  {
    name: "Professional Organizations",
    label: "Professional Organizations",
    code: "ProfessionalOrganizations",
    description:
      "Networking is one of the best ways to learn about cybersecurity news, updates, and identify what you are most interested in in the industry.",
  },
  {
    name: "Degrees in Cybersecurity",
    label: "Degrees in Cybersecurity",
    code: "DegreesinCybersecurity",
    description:
      "Education is key for this industry. There are some degrees you can consider earning to improve your knowledge and gain additional skills.",
  },
  {
    name: "Trainings and Certifications",
    label: "Trainings and Certifications",
    code: "TrainingsandCertifications",
    description:
      "Trainings and certifications are a great way to deep dive into specific topics and gain new cybersecurity skills. Here is a list of some of the most recognized and basic learning to start your journey in the industry.",
  },
  {
    name: "IT Assets",
    label: "IT Assets",
    code: "ITAssets",
    description:
      "Without technology we wouldn't need to learn about cybersecurity. Being  interested in technology, information technology and operational technology can go hand in hand with being interested in cybersecurity. You can learn about cybersecurity without being deep in the weeves of technology, yet it is very beneficial to understand how technologies work and what IT assets exist to understand cyber risks.",
  },
  {
    name: "Stay Informed",
    label: "Stay Informed",
    code: "StayInformed",
    description:
      "Cybersecurity is an ever-changing industry. Keep up to date with all news, trends, and topics by following great blogs and other sources!",
  },
  {
    name: "Is Cybersecurity for me?",
    label: "Is Cybersecurity for me?",
    code: "IsCybersecurityforme",
    description:
      "Here are a few sources you can read to understand if cybersecurity is the journey for you.",
  },
];

const HowToStartCategoryCompany = [
  {
    name: "Cybersecurity Intro and Principles",
    label: "Cybersecurity Introduction and Principles",
    code: "CybersecurityIntroandPrinciples",
    description:
      "If you are interested in generating organizational cybersecurity awareness, it is crucial to understand what cybersecurity is and a few of its basic principles.",
  },

  {
    name: "Frameworks and Regulations",
    label: "Frameworks and Regulations",
    code: "FrameworksandRegulations",
    description:
      "Cybersecurity is an interesting and complex world. Frameworks and Standards have been defined by different countries and industries to understand and manage cybersecurity risks and ecosystem.",
  },

  {
    name: "Areas of Specialization in Cybersecurity",
    label: "Areas of Specialization in Cybersecurity",
    code: "AreasofSpecializationinCybersecurity",
    description:
      "For collaborators new to the cybersecurity workforce, provide them with the opportunity to explore various specialized areas within the field.",
  },
  {
    name: "Professional Organizations",
    label: "Professional Organizations",
    code: "ProfessionalOrganizations",
    description:
      "Networking is one of the best ways to learn about cybersecurity news, updates, and identify what you are most interested in in the industry.",
  },
  {
    name: "Degrees in Cybersecurity",
    label: "Degrees in Cybersecurity",
    code: "DegreesinCybersecurity",
    description:
      "Education is crucial in the cybersecurity industry. Consider recommending certain degrees to¡ collaborators interested on joining the cybersecurity workforce to enhance their knowledge and acquire additional skills.",
  },
  {
    name: "Trainings and Certifications",
    label: "Trainings and Certifications",
    code: "TrainingsandCertifications",
    description:
      "Trainings and certifications are a great way to deep dive into specific topics and gain new cybersecurity skills. Here is a list of some of the most recognized and basic learning to share with your digital teams to start their cybersecurity journey. ",
  },
  {
    name: "IT Assets",
    label: "IT Assets",
    code: "ITAssets",
    description:
      "Without technology we wouldn't need to learn about cybersecurity. Being  interested in technology, information technology and operational technology can go hand in hand with being interested in cybersecurity. You can learn about cybersecurity without being deep in the weeves of technology, yet it is very beneficial to understand how technologies work and what IT assets exist to understand cyber risks.",
  },
  {
    name: "Stay Informed",
    label: "Stay Informed",
    code: "StayInformed",
    description:
      "Cybersecurity is an ever-changing industry. Keep up to date with all news, trends, and topics by following great blogs and other sources!",
  },
  {
    name: "Is Cybersecurity for me?",
    label: "Is Cybersecurity for me?",
    code: "IsCybersecurityforme",
    description:
      "Here are a few sources you and your collaborators can read to understand if cybersecurity is the right journey for them.",
  },
];

const CategoriesJobs = [
  {
    name: "Information Security Analyst",
    label: "Information Security Analyst: ",
    code: "InformationSecurityAnalyst",
    description:
      "Protects an organization's computer systems and networks by analyzing and implementing security measures to protect against potential threats.",
  },

  {
    name: "Digital Forensic Examiner  Cyber Crime Analyst: ",
    label: "Digital Forensic Examiner / Cyber Crime Analyst: ",
    code: "DigitalForensicExaminerCyberCrimeAnalyst: ",
    description:
      "Investigates and analyzes digital evidence to trace and prevent cybercrime, employing forensic techniques to understand and mitigate cyber incidents.",
  },

  {
    name: "Cybersecurity Specialist",
    label: "Cybersecurity Specialist: ",
    code: "CybersecuritySpecialist",
    description:
      "Specialized professional addressing various aspects of cybersecurity, including risk management, incident response, and implementing advanced security measures tailored to an organization's needs.",
  },
  {
    name: "IT Auditor",
    label: "IT Auditor: ",
    code: "ITAuditor",
    description:
      "Evaluates and ensures the effectiveness of an organization's IT systems, examining controls, policies, and procedures to identify vulnerabilities and compliance with industry standards.",
  },
  {
    name: "Penetration Tester",
    label: "Penetration Tester:",
    code: "PenetrationTester",
    description:
      "Ethical hacker simulating cyberattacks to assess and strengthen the security of computer systems and networks by identifying and addressing vulnerabilities before malicious actors can exploit them.",
  },
  {
    name: "Secure Software Developer:",
    label: "Secure Software Developer:",
    code: "SecureSoftwareDeveloper",
    description:
      "Designs and creates software applications with a focus on building secure coding practices, minimizing vulnerabilities, and implementing measures to protect against potential security threats.",
  },
];
const CareerOwner = [
  {
    name: "Seccuri Career Paths",
    label: 0,
  },
  {
    name: "Public Career Paths",
    label: 1,
  },
];

export default {
  openToTravelOptions,
  educationLevelOptions,
  genderOptions,
  desideredEmploymentTypeOptions,
  industryOptions,
  employmentTypeOptions,
  languagesOptions,
  numberOfEmployeesOptions,
  revenueRangeOptions,
  jobDurationOptions,
  SecurityLevelOptions,
  SecurityClearanceRenewalOptions,
  CommunicationStatusOptions,
  TrainingProgressStatusOptions,
  peronalityTypesList,
  trainingLevelList,
  typeTrainingList,
  modality,
  Levels,
  HowToStartCategory,
  HowToStartCategoryCompany,
  CategoriesJobs,
  CareerOwner,
};
