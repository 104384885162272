/* eslint-disable @typescript-eslint/no-explicit-any */
import ApiService from "@/core/services/ApiService";
import JwtService from "@/core/services/JwtService";
import { Actions, Mutations } from "@/store/enums/StoreEnums";
import { Module, Action, Mutation, VuexModule } from "vuex-module-decorators";
import jwt_decode from "jwt-decode";
import masterDataEn from "@/core/data/master-data";
import masterDataEs from "@/core/data/master-data-es";
import masterDataPr from "@/core/data/master-data-pr";
export interface Job {
  skip: number;
  take: number;
  filters: [
    {
      minSalary: number;
      maxSalary: number;
      location: number;
      educationLevels: [];
    },
  ];
}
export interface Area {
  errors: unknown;
  areas: Area;
}

export interface User {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [x: string]: any;
  name: string;
  surname: string;
  email: string;
  password: string | null;
  api_token: string | null;
}

export interface UserAuthInfo {
  errors: unknown;
  user: User;
  isAuthenticated: boolean;
}
export interface JobPositions {
  errors: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jobs: Array<any>;
  isAuthenticated: boolean;
}

export interface Category {
  errors: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categories: any;
}

export interface Speciality {
  errors: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  specialities: any;
}

export interface Task {
  errors: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tasks: any;
}

export interface Role {
  errors: unknown;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roles: any;
}

@Module
export default class AuthModule
  extends VuexModule
  implements UserAuthInfo, JobPositions, Category, Speciality, Task, Role
{
  errors = {};
  user = {} as User;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  job = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jobs = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  savedJobs = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  savedTrainings = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  savedCareers = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  comunnicationPreference = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ConnectedAccounts = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trainingWithProgress = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  AppliedJobSelected = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appliedTalentListJobCompany = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  appliedJobs = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  blogsSeccuri = [] as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  awardNominees = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  categories = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  improveTextAI = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  specialities = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tasks = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  roles = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  careers = [] as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  completeCareerPath = [] as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newtoCyberTrainingList = [] as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  newtoCyberJobsList = [] as any;
  isAuthenticated = !!JwtService.getToken();
  userRole = null;
  userSubscription = null;
  currentJobId = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentNominee = [] as Array<any>;
  currentEmployeeId = null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  topTalent = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  talentList = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  CyberTeamTalentList = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  EmployeeCareerPath = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentTalentDetails = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trainingsList = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  careersList = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trainingDetails = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  careerPathSelected = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  currentSubscriptions = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trainingListMeta = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trainingListProvider = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trainingListLevel = {} as any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  trainingListFilters = [] as Array<any>;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  jobListMeta = 0;
  awardListMeta = 0;
  trainingCurrentPage = 0;
  subscriptionSelected = null;
  jobCurrentPage = 0;
  awardCurrentPage = 0;
  UpdatedPasswordAlert = true;
  masterData =
    localStorage.getItem("lang") == "en"
      ? masterDataEn
      : localStorage.getItem("lang") == "es"
        ? masterDataEs
        : localStorage.getItem("lang") == "pr"
          ? masterDataPr
          : masterDataEn;
  languageCode =
    localStorage.getItem("lang") == "en"
      ? "en"
      : localStorage.getItem("lang") == "es"
        ? "es"
        : localStorage.getItem("lang") == "pr"
          ? "pr"
          : "en";
  featureSubcription = {} as any;

  /**
   * Get current user object
   * @returns User
   */
  get currentUser(): User {
    return this.user;
  }
  /**
   * Get current user object
   * @returns User
   */
  get currentFeatureSubscription(): any {
    return this.featureSubcription;
  }
  /**
   * Get current masterData object
   * @returns masterData
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentMasterData(): any {
    return this.masterData;
  }
  /**
   * Get current masterData object
   * @returns masterData
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get LanguageCode(): any {
    return this.languageCode;
  }

  /**
   * Get currentRoleUser object
   * @returns currentRoleUser
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentUserRole(): any {
    return this.userRole || window.localStorage.getItem("userRole");
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentUserSubscription(): any {
    return (
      this.userSubscription || window.localStorage.getItem("userSubscription")
    );
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentUserSubscriptionIsDemo(): any {
    return (
      this.currentUserRole &&
      this.currentUser &&
      this.currentUserRole !== "Talent" &&
      (!this.currentUserSubscription ||
        this.currentUserSubscription === "Demo" ||
        this.currentUserSubscription === "")
    );
  }
  /**
   * Get current Job object
   * @returns Job
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get CurrentJobs(): Array<any> {
    return this.jobs;
  }
  get CurrentJobsCount(): number {
    return this.jobs ? this.jobs.length : 0;
  }
  /**
   * Get current comunnication Preference object
   * @returns comunnication Preference
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get CurrentComunnicationPreference(): Array<any> {
    return this.comunnicationPreference;
  }
  /**
   * Get current comunnication Preference object
   * @returns comunnication Preference
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get CurrentConnectedAccounts(): Array<any> {
    return this.ConnectedAccounts;
  }
  /**
   * Get current Saved Jobs object
   * @returns SavedJob
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentSavedJobs(): Array<any> {
    return this.savedJobs;
  }
  /**
   * Get current Saved Jobs object
   * @returns savedTrainings
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentSavedTrainings(): Array<any> {
    return this.savedTrainings;
  }
  /**
   * Get current Saved Jobs object
   * @returns savedCareers
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentSavedCareers(): Array<any> {
    return this.savedCareers;
  }
  /**
   * Get current Saved Jobs object
   * @returns trainingWithProgress
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentTrainingProgress(): Array<any> {
    return this.trainingWithProgress;
  }
  /**
   * Get current Saved Jobs object
   * @returns SavedJob
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentAppliedJobSelected(): Array<any> {
    return this.AppliedJobSelected;
  }
  /**
   * Get current Saved Jobs object
   * @returns appliedTalentListJobCompany
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentappliedTalentListJobCompany(): Array<any> {
    return this.appliedTalentListJobCompany;
  }
  /**
   * Get current Saved Jobs object
   * @returns ApliedJob
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentAppliedJobs(): Array<any> {
    return this.appliedJobs;
  }
  /**
   * Get current Saved Jobs object
   * @returns SavedJob
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get BlogsSeccuri(): Array<any> {
    return this.blogsSeccuri;
  }
  /**
   * Get current Award Nominees object
   * @returns  awardNominees
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentAwardNominees(): Array<any> {
    return this.awardNominees;
  }
  /**
   * Get current Award Nominees object
   * @returns  ImproveTextAI
   */
  //contains all information about all jobs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentImproveTextAI(): Array<any> {
    return this.improveTextAI;
  }
  /**
   * Get current topTalent object
   * @returns topTalent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getTopTalent(): Array<any> {
    return this.topTalent;
  }
  /**
   * Get current topTalent object
   * @returns topTalent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getTalents(): Array<any> {
    return this.talentList;
  }
  /**
   * Get current topTalent object
   * @returns topTalent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getCyberTeamTalentList(): Array<any> {
    return this.CyberTeamTalentList;
  }
  /**
   * Get current topTalent object
   * @returns topTalent
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getEmployeeCareerPath(): Array<any> {
    return this.EmployeeCareerPath;
  }
  /**
   * Get current Job object
   * @returns Job
   */
  //contains all information about a specified job
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get jobDetails(): any {
    return this.job;
  }
  /**
   * Get current Job object
   * @returns Job
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentJob(): any {
    return this.currentJobId;
  }
  /**
   * Get current Job object no se usa en el momento
   * @returns Job
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get awardsNominated(): Array<any> {
    return this.currentNominee;
  }
  /**
   * Get current employee object
   * @returns user
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentEmployee(): any {
    return this.currentEmployeeId;
  }
  /**
   * Get current Talent Details object
   * @returns user
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentTalentInfo(): any {
    return this.currentTalentDetails;
  }
  /**
   * Get current trainings  object
   * @returns training
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentTrainingsList(): any {
    return this.trainingsList;
  }
  /**
   * Get current careers  object
   * @returns career
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentCareersList(): any {
    return this.careersList;
  }

  /**
   * Get current training Details  object
   * @returns training
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentTrainingDetails(): any {
    return this.trainingDetails;
  }
  /**
   * Get current available subscriptions
   * @returns subscriptions
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get availableSubscriptions(): any {
    return this.currentSubscriptions;
  }
  /**
   * Get current categories object
   * @returns categories
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentCategories(): Array<any> {
    return this.categories;
  }
  /**
   * Get current specialities object
   * @returns specialities
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentSpecialities(): Array<any> {
    return this.specialities;
  }
  /**
   * Get current tasks object
   * @returns tasks
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentTasks(): Array<any> {
    return this.tasks;
  }
  /**
   * Get current roles object
   * @returns roles
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentRoles(): Array<any> {
    return this.roles;
  }
  /**
   * Get current careerPathSelected object
   * @returns careerPathSelected
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentCareerPath(): any {
    return this.careerPathSelected;
  }
  /**
   * Get current careers object
   * @returns careers
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get currentCareers(): any {
    return this.careers;
  }
  /**
   * Get current completeCareerPath object
   * @returns completeCareerPath
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get completeCareersByUser(): any {
    return this.completeCareerPath;
  }
  /**
   * Get current completeCareerPath object
   * @returns completeCareerPath
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get NewtoCyberTrainingList(): any {
    return this.newtoCyberTrainingList;
  }
  /**
   * Get current completeCareerPath object
   * @returns completeCareerPath
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get NewtoCyberJobsList(): any {
    return this.newtoCyberJobsList;
  }

  /**
   * Verify user authentication
   * @returns boolean
   */
  get isUserAuthenticated(): boolean {
    return this.isAuthenticated;
  }
  /**
   * training list pagination
   * @returns any
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getTrainingListMeta(): any {
    return this.trainingListMeta;
  }
  /**
   * training list provider
   * @returns any
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getTrainingListProvider(): any {
    return this.trainingListProvider;
  }
  /**
   * training list Levels
   * @returns any
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getTrainingListLevel(): any {
    return this.trainingListLevel;
  }
  /**
   * training list filter
   * @returns any
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getTrainingListFilter(): any {
    return this.trainingListFilters || [];
  }
  /**
   * training page
   * @returns number page
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getTrainingCurrentPage(): any {
    return this.trainingCurrentPage;
  }
  /**
   * training page
   * @returns number page
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getSubscriptionSelected(): any {
    return this.subscriptionSelected;
  }

  /**
   * pagination Job
   * @returns any
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getJobListMeta(): any {
    return this.jobListMeta;
  }
  /**
   * pagination Job
   * @returns any
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getAwardListMeta(): any {
    return this.awardListMeta;
  }

  /**
   * job page
   * @returns number page
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getJobCurrentPage(): any {
    return this.jobCurrentPage;
  }

  /**
   * job page
   * @returns number page
   */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  get getAwardCurrentPage(): any {
    return this.awardCurrentPage;
  }

  /**
   * Get authentification errors
   * @returns array
   */
  get getErrors() {
    return this.errors || [];
  }
  /**
   * Get authentification errors
   * @returns array
   */
  get getAlertUpdatePassword() {
    return this.UpdatedPasswordAlert;
  }

  @Mutation
  [Mutations.SET_ERROR](error) {
    this.errors = error;
  }
  @Mutation
  [Mutations.UPDATE_CURRENT_SUBSCRIPTION](user) {
    this.userSubscription = user.activeSubscription
      ? user.activeSubscription?.name
      : null;
    window.localStorage.setItem(
      "userSubscription",
      this.userSubscription || "",
    );
  }

  @Mutation
  [Mutations.UPDATE_CURRENT_ROLE](token) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const tokenDecode: any = jwt_decode(token);
    this.userRole = tokenDecode ? tokenDecode.role : null;
    window.localStorage.setItem("userRole", this.userRole || "");
  }

  @Mutation
  [Mutations.UPDATE_CURRENT_JOB](id) {
    this.currentJobId = id;
  }
  @Mutation
  [Mutations.UPDATE_CURRENT_ROLES]() {
    this.roles = [];
  }
  @Mutation
  [Mutations.VIEW_NOMINEE_PROFILE](data) {
    this.currentNominee = data;
  }
  @Mutation
  [Mutations.CURRRENT_MASTER_DATA](language) {
    this.masterData = language;
  }
  @Mutation
  [Mutations.Current_languageCode](language) {
    this.languageCode = language;
  }
  @Mutation
  [Mutations.CurrentFeatureSubcription](data) {
    this.featureSubcription = data;
  }
  @Mutation
  [Mutations.VIEW_APPLIED_TALENTS_JOB_MUTATION](talentList) {
    this.appliedTalentListJobCompany = talentList;
  }
  @Mutation
  [Mutations.UPDATE_APPLIED_JOB_SELECTED](jobData) {
    this.AppliedJobSelected = jobData;
  }

  @Mutation
  [Mutations.SET_STORE_MUTATION]() {
    this.job = {};
  }
  @Mutation
  [Mutations.UPDATE_CURRENT_EMPLOYEE](id) {
    this.currentEmployeeId = id;
  }
  @Mutation
  [Mutations.UPDATE_CURRENT_TALENT_DETAILS](talent) {
    this.currentTalentDetails = talent;
  }
  @Mutation
  [Mutations.SET_CAREER_PATH_TALENT]() {
    this.careerPathSelected = {};
    this.currentTalentDetails = {};
    this.roles = [];
  }
  @Mutation
  [Mutations.SET_SEARCH_AWARDS](searchText) {
    this.awardNominees = this.awardNominees.filter(
      (item) =>
        item.firstName.toLowerCase().includes(searchText.split(" ")[0]) ||
        item.lastName.toLowerCase().includes(searchText.split(" ")[1]) ||
        item.lastName.toLowerCase().includes(searchText.split(" ")[0]) ||
        item.firstName.toLowerCase().includes(searchText.split(" ")[1]),
    );
  }

  @Mutation
  [Mutations.SET_UPDATED_PASSWORD_ALERT_MUTATION](showAlert) {
    this.UpdatedPasswordAlert = showAlert;
  }

  @Mutation
  [Mutations.GET_TRAININGS_LIST_MUTATION](payload) {
    this.trainingsList = payload.trainings.data;
    this.trainingListMeta = payload.trainings.totalNumberOfRecords;
    this.trainingCurrentPage = payload.page;
  }
  @Mutation
  [Mutations.GET_NEW_TO_CYBER_TRAININGS_LIST_MUTATION](payload) {
    this.newtoCyberTrainingList = payload.trainings.data;
  }

  @Mutation
  [Mutations.SUBSCRIPTION_SELECTED](title) {
    this.subscriptionSelected = title;
  }

  @Mutation
  [Mutations.GET_TRAININGS_LIST_FILTER_MUTATION](payload) {
    const dataTrainings = JSON.parse(payload.trainings);
    this.trainingListFilters = dataTrainings.data;
  }

  @Mutation
  [Mutations.GET_TRAININGS_LIST_FILTER_PROVIDER_MUTATION](payload) {
    const dataTrainings = JSON.parse(payload.trainings);
    this.trainingListProvider = dataTrainings.data;
  }
  @Mutation
  [Mutations.GET_TRAININGS_LIST_FILTER_LEVEL_MUTATION](payload) {
    const dataTrainings = JSON.parse(payload.trainings);
    this.trainingListLevel = dataTrainings.data;
  }

  @Mutation
  [Mutations.GET_TRAINING_DETAILS_MUTATION](training) {
    this.trainingDetails = training.data.data;

    const index = this.trainingsList.findIndex(
      (item) => item.id === this.trainingDetails.id,
    );
    if (index === -1) {
      this.trainingsList.push(this.trainingDetails);
    } else {
      this.trainingsList[index] = {
        ...this.trainingsList[index],
        description: this.trainingDetails.description,
        price: this.trainingDetails.price,
        title: this.trainingDetails.title,
        provider: this.trainingDetails.provider,
        type: this.trainingDetails.type,
        level: this.trainingDetails.level,
        required_for: this.trainingDetails.required_for,
        currency: this.trainingDetails.currency,
        author: this.trainingDetails.author,
        author_link: this.trainingDetails.author_link,
        author_role: this.trainingDetails.author_role,
        price_seccuri: this.trainingDetails.price_seccuri,
        provider_link: this.trainingDetails.provider_link,
        link: this.trainingDetails.link,
        duration: this.trainingDetails.duration,
      };
    }
  }

  @Mutation
  [Mutations.SET_TRAINING_DETAILS_MUTATION]() {
    this.trainingDetails = {};
  }

  @Mutation
  [Mutations.GET_CURRENT_SUBSCRIPTION_MUTATION](subscriptions) {
    this.currentSubscriptions = subscriptions;
  }
  @Mutation
  [Mutations.GET_SUBSCRIPTION_URL_MUTATION](url) {
    console.log(url);
  }

  @Mutation
  [Mutations.GET_TOP_TALENT_JOB_MUTATION](topTalentInformation) {
    this.topTalent = topTalentInformation ? topTalentInformation.data : [];
  }
  @Mutation
  [Mutations.GET_TALENT_LIST_MUTATION](talents) {
    this.talentList = talents ? talents.data : [];
  }

  @Mutation
  [Mutations.UPDATE_WORK_EXPERIENCE_ITEM_MUTATION](experienceData) {
    if (!this.user.experiences) {
      this.user.experiences = [];
    }
    if (experienceData.id > 0) {
      // update
      const currentItemIndex = this.user.experiences.findIndex(
        (item) => item.id === experienceData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.experiences[currentItemIndex] = { ...experienceData };
    } else {
      delete experienceData["id"];
      this.user.experiences.push(experienceData);
    }
  }

  @Mutation
  [Mutations.DELETE_WORK_EXPERIENCE_ITEM_MUTATION](experienceData) {
    if (!this.user.experiences) {
      this.user.experiences = [];
    }
    if (experienceData.id > 0) {
      // update
      const currentItemIndex = this.user.experiences.findIndex(
        (item) => item.id === experienceData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.experiences[currentItemIndex] = { ...experienceData };
      this.user.experiences.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.UPDATE_EDUCATION_ITEM_MUTATION](educationData) {
    if (!this.user.educations) {
      this.user.educations = [];
    }
    if (educationData.id > 0) {
      // update
      const currentItemIndex = this.user.educations.findIndex(
        (item) => item.id === educationData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.educations[currentItemIndex] = { ...educationData };
    } else {
      // is new only append
      delete educationData["id"];
      this.user.educations.push(educationData);
    }
  }
  @Mutation
  [Mutations.DELETE_EDUCATION_ITEM_MUTATION](educationData) {
    if (!this.user.educations) {
      this.user.educations = [];
    }
    if (educationData.id > 0) {
      // update
      const currentItemIndex = this.user.educations.findIndex(
        (item) => item.id === educationData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.educations[currentItemIndex] = { ...educationData };
      this.user.educations.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }

  @Mutation
  [Mutations.UPDATE_CERTIFICATION_ITEM_MUTATION](certificationData) {
    if (!this.user.certifications) {
      this.user.certifications = [];
    }
    if (certificationData.id > 0) {
      // update
      const currentItemIndex = this.user.certifications.findIndex(
        (item) => item.id === certificationData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.certifications[currentItemIndex] = { ...certificationData };
    } else {
      // is new only append
      delete certificationData["id"];
      this.user.certifications.push(certificationData);
    }
  }
  @Mutation
  [Mutations.DELETE_CERTIFICATION_ITEM_MUTATION](certificationData) {
    if (!this.user.certifications) {
      this.user.certifications = [];
    }
    if (certificationData.id > 0) {
      // update
      const currentItemIndex = this.user.certifications.findIndex(
        (item) => item.id === certificationData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.certifications[currentItemIndex] = { ...certificationData };
      this.user.certifications.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  @Mutation
  [Mutations.DELETE_JOB_MUTATION](jobData) {
    if (!this.jobs) {
      this.jobs = [];
    }
    if (jobData != null) {
      // update
      const currentItemIndex = this.jobs.findIndex(
        (item) => item.id == jobData,
      );
      if (currentItemIndex === -1) {
        return false;
      } else {
        this.jobs[currentItemIndex] = { ...jobData };
        this.jobs.splice(currentItemIndex, 1);
      }
      const currentItemIndexAppliedJob = this.appliedJobs.findIndex(
        (item) => item.id === jobData,
      );
      if (currentItemIndexAppliedJob === -1) {
        return false;
      } else {
        this.appliedJobs[currentItemIndexAppliedJob] = { ...jobData };
        this.appliedJobs.splice(currentItemIndexAppliedJob, 1);
      }
    }
  }
  @Mutation
  [Mutations.DELETE_COURSE_ITEM_MUTATION](courseData) {
    if (!this.user.courses) {
      this.user.courses = [];
    }
    if (courseData.id > 0) {
      // update
      const currentItemIndex = this.user.courses.findIndex(
        (item) => item.id === courseData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.courses[currentItemIndex] = { ...courseData };
      this.user.courses.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }

  @Mutation
  [Mutations.UPDATE_COURSE_ITEM_MUTATION](courseData) {
    if (!this.user.courses) {
      this.user.courses = [];
    }
    if (courseData.id > 0) {
      // update
      const currentItemIndex = this.user.courses.findIndex(
        (item) => item.id === courseData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.courses[currentItemIndex] = { ...courseData };
    } else {
      // is new only append
      delete courseData["id"];
      this.user.courses.push(courseData);
    }
  }
  @Mutation
  [Mutations.UPDATE_LANGUAGE_ITEM_MUTATION](languageData) {
    if (!this.user.languages) {
      this.user.languages = [];
    }
    if (languageData.id > 0) {
      // update
      const currentItemIndex = this.user.languages.findIndex(
        (item) => item.id === languageData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.languages[currentItemIndex] = { ...languageData };
    } else {
      // is new only append
      delete languageData["id"];
      this.user.languages.push(languageData);
    }
  }

  @Mutation
  [Mutations.DELETE_LANGUAGE_ITEM_MUTATION](languageData) {
    if (!this.user.languages) {
      this.user.languages = [];
    }
    if (languageData.id > 0) {
      // update
      const currentItemIndex = this.user.languages.findIndex(
        (item) => item.id === languageData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.languages[currentItemIndex] = { ...languageData };
      this.user.languages.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.DELETE_RECOMMENDATION_ITEM_MUTATION](recommendationData) {
    if (!this.user.recommendations) {
      this.user.recommendations = [];
    }
    if (recommendationData.email != null) {
      // update
      const currentItemIndex = recommendationData.index;
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.recommendations[currentItemIndex] = recommendationData.email;
      this.user.recommendations.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.UPDATE_RECOMMENDATION_ITEM_MUTATION](recommendationData) {
    if (!this.user.recommendations) {
      this.user.recommendations = [];
    }
    if (recommendationData.email != null) {
      // update
      const currentItemIndex = recommendationData.index;
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.recommendations[currentItemIndex] = recommendationData.email;
    } else {
      // is new only append
      this.user.recommendations.push(recommendationData.email);
    }
  }
  @Mutation
  [Mutations.DELETE_JOB_CERTIFICATION_ITEM_MUTATION](certificationData) {
    if (!this.job.certificates) {
      this.job.certificates = [];
    }
    if (certificationData.name != null) {
      // update
      const currentItemIndex = certificationData.index;
      if (currentItemIndex === -1) {
        return false;
      }
      this.job.certificates[currentItemIndex] = certificationData.name;
      this.job.certificates.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.ADD_JOB_CERTIFICATION_ITEM_MUTATION](certificationData) {
    if (!this.job.certificates) {
      this.job.certificates = [];
    }
    if (certificationData.name != null) {
      // update
      const currentItemIndex = certificationData.index;
      if (currentItemIndex === -1) {
        return false;
      }
      this.job.certificates[currentItemIndex] = certificationData.name;
    } else {
      // is new only append
      this.job.certificates.push(certificationData.name);
    }
  }

  @Mutation
  [Mutations.UPDATE_PROFILE_MUTATION](user) {
    //this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
  }
  @Mutation
  [Mutations.UPDATE_PDF_MUTATION](user) {
    debugger;
    //this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
  }

  @Mutation
  [Mutations.CREATE_JOB_MUTATION](job) {
    //this.isAuthenticated = true;
    this.job = job;
    this.errors = {};

    if (!this.jobs) {
      this.jobs = [];
    }
    if (job.id != null) {
      // update
      const currentItemIndex = this.jobs.findIndex(
        (item) => item.id === job.id,
      );
      if (currentItemIndex === -1) {
        this.jobs.push(job);
      } else {
        this.jobs[currentItemIndex] = job;
      }
      const currentItemIndexAppliedJob = this.appliedJobs.findIndex(
        (item) => item.id === job.id,
      );
      if (currentItemIndexAppliedJob !== -1) {
        this.appliedJobs[currentItemIndexAppliedJob] = {
          ...this.appliedJobs[currentItemIndexAppliedJob],
          status: job.status,
        };
      }
    } else {
      return false;
    }
  }

  @Mutation
  [Mutations.CLEAR_CURRENT_JOB]() {
    this.job = {};
  }
  @Mutation
  [Mutations.ADD_JOB_LANGUAGE_ITEM_MUTATION](languageData) {
    if (!this.job.languages) {
      this.job.languages = [];
    }
    if (languageData.id > 0) {
      // update
      const currentItemIndex = this.job.languages.findIndex(
        (item) => item.id === languageData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.job.languages[currentItemIndex] = { ...languageData };
    } else {
      // is new only append
      delete languageData["id"];
      this.job.languages.push(languageData);
    }
  }

  @Mutation
  [Mutations.DELETE_JOB_LANGUAGE_ITEM_MUTATION](languageData) {
    if (!this.job.languages) {
      this.job.languages = [];
    }
    if (languageData.id > 0) {
      // update
      const currentItemIndex = this.job.languages.findIndex(
        (item) => item.id === languageData.id,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.job.languages[currentItemIndex] = { ...languageData };
      this.job.languages.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
    }
  }
  @Mutation
  [Mutations.UPDATE_COMPANY_USERS](employee) {
    if (!this.user.users) {
      this.user.users = [];
    }
    if (employee.id != null) {
      this.user.users.push(employee);
      this.user.numberOfUsers++;
    }
    this.errors = {};
  }
  @Mutation
  [Mutations.UPDATE_COMPANY_TALENT_EMPLOYEE](employee) {
    if (!this.CyberTeamTalentList) {
      this.CyberTeamTalentList = [];
    }
    if (employee.id != null) {
      const currentItemIndex = this.CyberTeamTalentList.findIndex(
        (item) => item.employeeId === employee.id,
      );
      if (currentItemIndex === -1) {
        this.CyberTeamTalentList.push(employee);
        this.user.numberOfUsers++;
      } else {
        this.CyberTeamTalentList[currentItemIndex] = {
          ...this.CyberTeamTalentList[currentItemIndex],
          publicEmployee: employee.publicEmployee,
        };
      }
    }
    this.errors = {};
  }
  @Mutation
  [Mutations.DELETE_COMPANY_USERS_MUTATION](employee) {
    if (!this.user.users) {
      this.user.users = [];
    }
    if (employee != null) {
      // update
      const currentItemIndex = this.user.users.findIndex(
        (item) => item.id === employee,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.user.users[currentItemIndex] = { ...employee };
      this.user.users.splice([currentItemIndex], 1);
      // experienceData.splice([currentItemIndex], 1);
      this.user.numberOfUsers--;
    }
    this.errors = {};
    this.currentEmployeeId = null;
  }
  @Mutation
  [Mutations.DELETE_COMPANY_TALENT_MUTATION](employee) {
    if (!this.CyberTeamTalentList) {
      this.CyberTeamTalentList = [];
    }
    if (employee != null) {
      // update
      const currentItemIndex = this.CyberTeamTalentList.findIndex(
        (item) => item.employeeId === employee,
      );
      if (currentItemIndex === -1) {
        return false;
      }
      this.CyberTeamTalentList[currentItemIndex] = { ...employee };
      this.CyberTeamTalentList.splice(currentItemIndex, 1);
      // experienceData.splice([currentItemIndex], 1);
      this.user.numberOfUsers--;
    }
    this.errors = {};
    this.currentEmployeeId = null;
  }
  @Mutation
  [Mutations.DELETE_COMPANY_RELATED_TALENT_MUTATION](employeeId) {
    if (!this.user.employer) {
      this.user.employer = [];
    }
    if (employeeId != null) {
      // update
      this.user.employer = [];
    }
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_AUTH](user) {
    this.isAuthenticated = true;
    this.user = user;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_JOBS](jobs) {
    this.jobs = jobs.data;
    this.jobListMeta = jobs.totalNumberOfRecords;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_NEW_TO_CYBER_JOBS](jobs) {
    this.newtoCyberJobsList = jobs.data;
    this.errors = {};
  }

  @Mutation
  [Mutations.GET_SAVED_JOBS_MUTATION](jobs) {
    this.savedJobs = jobs;
    this.savedJobs.forEach((item) => {
      const index = this.jobs.findIndex((job) => job.id === item.id);
      if (index > -1) {
        this.jobs[index] = {
          ...this.jobs[index],
          savedDate: item.savedDate,
        };
      } else {
        this.jobs.push(item);
      }
    });
    this.errors = {};
  }
  @Mutation
  [Mutations.GET_COMMUNICATION](preference) {
    this.comunnicationPreference = preference;
    this.errors = {};
  }
  @Mutation
  [Mutations.GET_CONNECTED_ACCOUNTS_MUTATION](accounts) {
    this.ConnectedAccounts = accounts;
    this.errors = {};
  }
  @Mutation
  [Mutations.RELOAD_JOBS_MUTATION]() {
    if (this.jobs.length > 0) {
      this.savedJobs.forEach((item) => {
        const index = this.jobs.findIndex((job) => job.id === item.id);
        if (index > -1) {
          this.jobs[index] = {
            ...this.jobs[index],
            savedDate: item.savedDate,
          };
        } else {
          this.jobs.push(item);
        }
      });
      this.appliedJobs.forEach((item) => {
        const index = this.jobs.findIndex((job) => job.id === item.id);
        if (index > -1) {
          this.jobs[index] = {
            ...this.jobs[index],
            appliedDate: item.appliedDate,
          };
        } else {
          this.jobs.push(item);
        }
      });
    }
    this.errors = {};
  }
  @Mutation
  [Mutations.RELOAD_TRAININGS_MUTATION]() {
    if (this.trainingsList.length > 0) {
      this.savedTrainings.forEach((item) => {
        const index = this.trainingsList.findIndex(
          (training) => training.id === item.id,
        );
        if (index > -1) {
          this.trainingsList[index] = {
            ...this.trainingsList[index],
            savedDate: item.savedDate,
          };
        } else {
          this.trainingsList.push(item);
        }
      });
      this.trainingWithProgress.forEach((item) => {
        const index = this.trainingsList.findIndex(
          (training) => training.id === item.id,
        );
        if (index > -1) {
          this.trainingsList[index] = {
            ...this.trainingsList[index],
            progressStatus: item.progressStatus,
          };
        } else {
          this.trainingsList.push(item);
        }
      });
    }
    this.errors = {};
  }
  @Mutation
  [Mutations.GET_SAVED_TRAINING_MUTATION](trainings) {
    this.savedTrainings = trainings;
    this.savedTrainings.forEach((item) => {
      const index = this.trainingsList.findIndex(
        (training) => training.id === item.id,
      );
      if (index > -1) {
        this.trainingsList[index] = {
          ...this.trainingsList[index],
          savedDate: item.savedDate,
        };
      } else {
        this.trainingsList.push(item);
      }
    });
    this.errors = {};
  }
  @Mutation
  [Mutations.GET_SAVED_CAREER_MUTATION](data) {
    debugger;
    this.savedCareers = data;
    this.savedCareers.forEach((item) => {
      const index = this.careers.findIndex((career) => career.id === item.id);
      if (index > -1) {
        this.careers[index] = {
          ...this.careers[index],
          savedDate: item.savedDate,
        };
      } else {
        this.careers.push(item);
      }
    });
    this.errors = {};
  }
  @Mutation
  [Mutations.CREATE_CAREER_PATH_MUTATION](data) {
    this.savedCareers.push(data);
    this.savedCareers.forEach((item) => {
      const index = this.careers.findIndex((career) => career.id === item.id);
      if (index > -1) {
        this.careers[index] = {
          ...this.careers[index],
          savedDate: item.savedDate,
        };
      } else {
        this.careers.push(item);
      }
    });
    this.errors = {};
  }

  @Mutation
  [Mutations.CREATE_CAREER_PATH_COMPANY_MUTATION](data) {
    const index = this.careers.findIndex((career) => career.id === data.id);
    if (index > -1) {
      this.careers[index] = {
        ...this.careers[index],
      };
    } else {
      this.careers.push(data);
    }

    this.errors = {};
  }

  @Mutation
  [Mutations.GET_TRAININGS_WITH_PROGRESS_MUTATION](trainings) {
    this.trainingWithProgress = trainings;
    this.trainingWithProgress.forEach((item) => {
      const index = this.trainingsList.findIndex(
        (training) => training.id === item.id,
      );
      if (index > -1) {
        this.trainingsList[index] = {
          ...this.trainingsList[index],
          progressStatus: item.progressStatus,
        };
      } else {
        this.trainingsList.push(item);
      }
    });
    this.errors = {};
  }
  @Mutation
  [Mutations.GET_APPLIED_JOBS_MUTATION](jobs) {
    this.appliedJobs = jobs;
    this.appliedJobs.forEach((item) => {
      const index = this.jobs.findIndex((job) => job.id === item.id);
      if (index > -1) {
        this.jobs[index] = {
          ...this.jobs[index],
          appliedDate: item.appliedDate,
        };
      } else {
        this.jobs.push(item);
      }
    });
    this.errors = {};
  }
  @Mutation
  [Mutations.GET_AWARDS_NOMINATED_MUTATION](awardsNominees) {
    // this.awardNominees = awardsNominees.data;
    // this.awardListMeta = awardsNominees.totalNumberOfRecords;
    this.awardNominees = awardsNominees;
    this.awardNominees.sort(function (a, b) {
      if (a.votesQuantity > b.votesQuantity) {
        return -1;
      }
      if (a.votesQuantity < b.votesQuantity) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
  }

  @Mutation
  [Mutations.GET_APPLIED_JOBS_COMPANY_MUTATION](jobs) {
    this.appliedJobs = jobs;
    this.errors = {};
  }

  @Mutation
  [Mutations.GET_BLOGS_CMS_MUTATION](payload) {
    const dataBlogs = JSON.parse(payload.trainings);
    this.blogsSeccuri = dataBlogs.data;
    console.log("this.blogsSeccuri", this.blogsSeccuri);
    this.errors = {};
  }

  @Mutation
  [Mutations.GET_JOBS_META_MUTATION](payload) {
    this.jobCurrentPage = payload.page;
    this.errors = {};
  }
  @Mutation
  [Mutations.GET_AWARD_META_MUTATION](payload) {
    this.awardCurrentPage = payload.page;
    this.errors = {};
  }

  @Mutation
  [Mutations.GET_TRAININGS_META_MUTATION](payload) {
    this.trainingCurrentPage = payload.page;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_WORK_CATEGORY](categories) {
    this.categories = categories;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_WORK_TASKS](tasks) {
    this.tasks = tasks;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_WORK_SPECIALTY](specialties) {
    this.specialities = specialties;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_WORK_ROLES](roles) {
    this.roles = roles;
    this.errors = {};
  }
  //all Talent Careers and  mapped to general careers
  @Mutation
  [Mutations.SET_CAREER_PATH](dataCareerPath) {
    debugger;
    //careers que tiene la persona con informacion y entrenamientos
    this.careerPathSelected = dataCareerPath.data;
    if (this.careers.length > 0) {
      this.careerPathSelected.forEach((item) => {
        const index = this.careers.findIndex((career) => career.id === item.id);

        if (index != -1) {
          this.careers[index] = {
            ...item,
          };
        } else {
          this.careers.push(item);
        }
      });
    } else {
      this.careerPathSelected.forEach((item) => {
        this.careers.push(item);
      });
    }
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_CAREER_PATH_EMPLOYEE](dataCareerPath) {
    //trae todos los career progress que tenga un empleado especifico.
    //careers que tiene la persona con informacion y entrenamientos
    const currentItemIndex = this.CyberTeamTalentList.findIndex(
      (item) => item.profileId === dataCareerPath.data[0].profileId,
    );
    if (currentItemIndex > -1) {
      this.CyberTeamTalentList[currentItemIndex] = {
        ...this.CyberTeamTalentList[currentItemIndex],
        careerPathScore: dataCareerPath.data,
      };
    }
  }

  //all careers empty
  @Mutation
  [Mutations.SET_CAREERS](Careers) {
    debugger;
    this.careers = Careers.data;
    this.errors = {};
  }
  @Mutation
  [Mutations.SET_LEVEL_CAREER_TRAINING]() {
    debugger;
    this.completeCareerPath.trainings?.forEach((data) => {
      const trainingIdx = this.trainingsList.findIndex(
        (_) => _.id === data.trainingId,
      );

      const careerIndex = this.careers.findIndex(
        (item) => item.id === data.careerId,
      );
      const careerIndexTraining = this.careers[
        careerIndex
      ].trainings?.findIndex((_) => _.trainingId === data.trainingId);
      if (trainingIdx > -1) {
        this.trainingsList[trainingIdx] = {
          ...this.trainingsList[trainingIdx],
          levelCareerTraining: data.levelCareerTraining,
        };
      } else {
        this.trainingsList.push({
          ...this.careers[careerIndex].trainings[careerIndexTraining],
          levelCareerTraining: data.levelCareerTraining,
          id: data.trainingId,
        });
      }

      this.careers[careerIndex].trainings[careerIndexTraining] = {
        ...this.careers[careerIndex].trainings[careerIndexTraining],
        levelCareerTraining: data.levelCareerTraining,
      };
    });
  }
  @Mutation
  [Mutations.SET_LEVEL_CAREER_TRAINING_COMPANY]() {
    debugger;
    if (this.trainingsList.length > 0) {
      this.careerPathSelected.trainings?.forEach((item) => {
        const index = this.trainingsList.findIndex(
          (training) => training.id === item.id,
        );
        if (index > -1) {
          this.trainingsList[index] = {
            ...this.trainingsList[index],
            levelCareerTraining: item.levelCareerTraining,
          };
        }
      });
    }
  }

  @Mutation
  [Mutations.CAREER_PATH_COMPLETE](careerId) {
    debugger;
    const currentItemIndex = this.careerPathSelected.findIndex(
      (item) => item.id === careerId,
    );
    if (currentItemIndex === -1) {
      const index = this.careers.findIndex((item) => item.id === careerId);
      this.completeCareerPath = {
        ...this.careers[index],
        trainings: [],
        level: "EntryLevel",
        percentageString: 0,
        percentage: 0,
      };
    } else {
      this.completeCareerPath = this.careerPathSelected.find(
        (x) => x.id == careerId,
      );
    }
    console.log("completeCareerPath", this.completeCareerPath);
  }

  @Mutation
  [Mutations.CAREER_PATH_DETAILS_COMPANY](career) {
    this.careerPathSelected = career;
  }

  @Mutation
  [Mutations.TRAININGS_CAREER_PATH_COMPLETE](careerTrainings) {
    // const completeCareerPath = this.careerPathSelected.concact(this.careers);
    const currentItemIndex = this.careers.findIndex(
      (item) => item.id === careerTrainings.careerId,
    );
    if (currentItemIndex != -1) {
      this.careers[currentItemIndex] = {
        ...this.careers[currentItemIndex],
        trainings: careerTrainings.trainings,
      };
    }
    this.completeCareerPath = {
      ...this.completeCareerPath,
      trainings: careerTrainings.trainings,
    };
    if (this.userRole != "Talent") {
      this.careerPathSelected = this.careers[currentItemIndex];
    }
  }
  @Mutation
  [Mutations.CONFIRM_JOIN_TALENT_COMPANY_MUTATION]() {
    this.errors = {};
  }
  @Mutation
  [Mutations.CONFIRM_JOIN_AWARD_MUTATION]() {
    this.errors = {};
  }

  @Mutation
  [Mutations.CYBERTEAM_COMPANY_TALENTS_MUTATION](talents) {
    this.CyberTeamTalentList = talents.data;
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_AUTH_TOKEN](token) {
    JwtService.saveToken(token);
    this.isAuthenticated = true;
  }

  @Mutation
  [Mutations.SET_APPLY_JOB](data) {
    const jobIdx = this.jobs.findIndex((_) => _.id === data.id);
    if (jobIdx > -1) {
      this.jobs[jobIdx] = {
        ...this.jobs[jobIdx],
        appliedDate: data.appliedDate,
      };
    }
    this.errors = {};
  }
  @Mutation
  [Mutations.SAVE_VOTE_AWARDSNOMINEES_MUTATION](data) {
    const nomineeId = this.awardNominees.findIndex((_) => _.id === data.id);
    if (nomineeId > -1) {
      if (!this.awardNominees[nomineeId].voteTalentId) {
        this.awardNominees[nomineeId].voteTalentId = [];
      }
      this.awardNominees[nomineeId].votesQuantity = data.votesQuantity;
      this.awardNominees[nomineeId].voteTalentId.push(data.talentId);
    }
    this.awardNominees.sort(function (a, b) {
      if (a.votesQuantity > b.votesQuantity) {
        return -1;
      }
      if (a.votesQuantity < b.votesQuantity) {
        return 1;
      }
      // a must be equal to b
      return 0;
    });
    this.errors = {};
  }

  @Mutation
  [Mutations.SET_SAVE_JOB](data) {
    const jobIdx = this.jobs.findIndex((_) => _.id === data.id);
    if (jobIdx > -1) {
      this.jobs[jobIdx] = {
        ...this.jobs[jobIdx],
        savedDate: data.savedDate,
      };
    } else {
      const job = {
        id: data.id,
        savedDate: data.savedDate,
      };
      this.jobs.push(job);
    }
  }
  @Mutation
  [Mutations.SET_SAVE_NOMINATED](data) {
    if (data.status != "Pending") {
      if (!this.awardNominees) {
        this.awardNominees = [];
      }
      const nomineeIdx = this.awardNominees.findIndex((_) => _.id === data.id);
      if (nomineeIdx > -1) {
        this.awardNominees[nomineeIdx] = data;
      } else {
        this.awardNominees.push(data);
      }
    }
  }
  @Mutation
  [Mutations.SET_IMPROVED_TEXT](data) {
    debugger;
    if (data != null) {
      if (!this.improveTextAI) {
        this.improveTextAI = [];
      }
      this.improveTextAI.push(data);
    }
  }

  @Mutation
  [Mutations.SET_SAVE_TRAINING](data) {
    const trainingIdx = this.trainingsList.findIndex((_) => _.id === data.id);
    if (trainingIdx > -1) {
      this.trainingsList[trainingIdx] = {
        ...this.trainingsList[trainingIdx],
        savedDate: data.savedDate,
      };
    } else {
      const training = {
        id: data.id,
        savedDate: data.savedDate,
      };
      this.trainingsList.push(training);
    }
  }
  @Mutation
  [Mutations.RECOMMEND_CAREER_MUTATION](data) {
    const currentItemIndex = this.CyberTeamTalentList.findIndex(
      (item) => item.id === data.assignedToTalent,
    );
    if (currentItemIndex > -1) {
      if (data.recommendedDate != null) {
        if (
          this.CyberTeamTalentList[currentItemIndex].recommendedCareers.length >
          0
        ) {
          this.CyberTeamTalentList[currentItemIndex].recommendedCareers.push(
            data,
          );
        } else {
          this.CyberTeamTalentList[currentItemIndex].recommendedCareers = [];
          this.CyberTeamTalentList[currentItemIndex].recommendedCareers.push(
            data,
          );
        }
      } else {
        const index = this.CyberTeamTalentList[
          currentItemIndex
        ].recommendedCareers.findIndex(
          (item) => item.id === data.assignedToTalent,
        );
        this.CyberTeamTalentList[currentItemIndex].recommendedCareers.splice(
          [index],
          1,
        );
      }
    }
  }

  @Mutation
  [Mutations.SET_SAVE_CAREER](data) {
    const careerIdx = this.careers.findIndex((_) => _.id === data.id);
    if (careerIdx > -1) {
      this.careers[careerIdx] = {
        ...this.careers[careerIdx],
        savedDate: data.savedDate,
      };
    }
    console.log("SAVED CAREER", this.careers);
  }

  @Mutation
  [Mutations.DELETE_CAREER_MUTATION](data) {
    const careerIdx = this.careers.findIndex((_) => _.id === data.id);
    if (careerIdx > -1) {
      this.careers.splice([careerIdx], 1);
    }
  }

  @Mutation
  [Mutations.SET_COMPLETE_TRAINING_MUTATION](data) {
    const trainingIdx = this.trainingsList.findIndex((_) => _.id === data.id);
    if (trainingIdx > -1) {
      this.trainingsList[trainingIdx] = {
        ...this.trainingsList[trainingIdx],
        progressStatus: data.progressStatus,
      };
    }
  }
  @Mutation
  [Mutations.EDIT_TRAINING_LEVEL_CAREER_PATH_MUTATION](data) {
    debugger;
    const trainingIdx = this.trainingsList.findIndex(
      (_) => _.id === data.trainingId,
    );

    const careerIndex = this.careers.findIndex(
      (item) => item.id === data.careerId,
    );
    const careerIndexTraining = this.careers[careerIndex].trainings.findIndex(
      (_) => _.trainingId === data.trainingId,
    );

    const completeCareer = this.completeCareerPath.trainings.findIndex(
      (_) => _.trainingId === data.trainingId,
    );
    if (trainingIdx > -1) {
      this.trainingsList[trainingIdx] = {
        ...this.trainingsList[trainingIdx],
        levelCareerTraining: data.levelCareerTraining,
      };
    } else {
      if (completeCareer > -1) {
        this.trainingsList.push({
          ...this.completeCareerPath.trainings[
            this.completeCareerPath.trainings.findIndex(
              (_) => _.trainingId === data.trainingId,
            )
          ],
          id: data.trainingId,
        });
      } else {
        this.trainingsList.push({
          ...this.careers[careerIndex].trainings[careerIndexTraining],
          levelCareerTraining: data.levelCareerTraining,
          id: data.trainingId,
        });
      }
    }

    if (careerIndexTraining > -1) {
      this.careers[careerIndex].trainings[careerIndexTraining] = {
        ...this.careers[careerIndex].trainings[careerIndexTraining],
        levelCareerTraining: data.levelCareerTraining,
      };
    } else {
      if (completeCareer > -1) {
        this.careers[careerIndex].trainings.push({
          ...this.completeCareerPath.trainings[
            this.completeCareerPath.training.findIndex(
              (_) => _.trainingId === data.trainingId,
            )
          ],
          levelCareerTraining: data.levelCareerTraining,
          careerId: data.careerId,
        });
      } else {
        this.careers[careerIndex].trainings.push({
          ...this.trainingsList[trainingIdx],
          levelCareerTraining: data.levelCareerTraining,
          careerId: data.careerId,
          trainingId: data.trainingId,
        });
      }
    }
    if (data.levelCareerTraining == null) {
      this.careers[careerIndex].trainings.splice([careerIndexTraining], 1);
    }
  }

  @Mutation
  [Mutations.EDIT_TRAINING_LEVEL_CAREER_PATH_COMPANY_MUTATION](data) {
    debugger;
    const trainingIdx = this.trainingsList.findIndex(
      (_) => _.id === data.trainingId,
    );

    const careerIndexTraining = this.careerPathSelected.trainings.findIndex(
      (_) => _.id === data.trainingId,
    );

    if (data.levelCareerTraining == null) {
      this.careerPathSelected.trainings.splice([careerIndexTraining], 1);
    } else {
      if (trainingIdx > -1) {
        this.trainingsList[trainingIdx] = {
          ...this.trainingsList[trainingIdx],
          levelCareerTraining: data.levelCareerTraining,
        };
      }

      if (careerIndexTraining > -1) {
        this.careerPathSelected.trainings[careerIndexTraining] = {
          ...this.careerPathSelected.trainings[careerIndexTraining],
          levelCareerTraining: data.levelCareerTraining,
        };
      } else {
        this.careerPathSelected.trainings.push({
          ...this.trainingsList[trainingIdx],
          levelCareerTraining: data.levelCareerTraining,
          id: data.trainingId,
        });
      }
    }
  }

  @Mutation
  [Mutations.SET_USER](user) {
    this.user = {
      name: user.firstName,
      surname: `${user.middleName} ${user.lastName}`,
      email: user.contactInformation
        ? user.contactInformation.primaryEmail
        : null,
      password: null,
      api_token: JwtService.getToken(),
    };
  }

  @Mutation
  [Mutations.SET_PASSWORD](password) {
    this.user.password = password;
  }

  @Mutation
  [Mutations.PURGE_AUTH]() {
    this.isAuthenticated = false;
    this.user = {} as User;
    this.jobs = [];
    this.savedJobs = [];
    this.savedTrainings = [];
    this.savedCareers = [];
    this.trainingWithProgress = [];
    this.AppliedJobSelected = [];
    this.appliedTalentListJobCompany = [];
    this.appliedJobs = [];
    this.blogsSeccuri = [];
    this.job = {};
    this.categories = [];
    this.specialities = [];
    this.tasks = [];
    this.roles = [];
    this.careers = [];
    this.isAuthenticated = false;
    this.userRole = null;
    this.currentJobId = null;
    this.topTalent = [];
    this.errors = [];
    this.userRole = null;
    this.userSubscription = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.currentNominee = [] as Array<any>;
    this.currentEmployeeId = null;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.topTalent = [] as Array<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.talentList = [] as Array<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.CyberTeamTalentList = [] as Array<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.EmployeeCareerPath = [] as Array<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.currentTalentDetails = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.trainingsList = [] as Array<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.careersList = [] as Array<any>;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.trainingDetails = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.careerPathSelected = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.currentSubscriptions = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.trainingListMeta = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.trainingListProvider = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.trainingListLevel = {} as any;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    this.trainingListFilters = [] as Array<any>;
    this.jobListMeta = 0;
    this.awardListMeta = 0;
    this.trainingCurrentPage = 0;
    this.jobCurrentPage = 0;
    this.awardCurrentPage = 0;
    this.UpdatedPasswordAlert = true;
    this.subscriptionSelected = null;
    JwtService.destroyToken();
    ApiService.setHeader();
  }

  @Action
  [Actions.LOGIN](credentials) {
    return ApiService.post("/authentication/login", credentials)
      .then(({ data }) => {
        if (data) {
          this.context.commit(Mutations.SET_AUTH_TOKEN, data.data);
          this.context.commit(Mutations.UPDATE_CURRENT_ROLE, data.data);
        } else {
          this.context.commit(Mutations.SET_ERROR, [
            {
              error: "Error autenticando usuario: Error de conexión.",
            },
          ]);
        }
      })
      .catch(({ response }) => {
        if (response && response.data) {
          this.context.commit(Mutations.SET_ERROR, response.data.errors);
          this.context.commit(Mutations.SET_MESSAGE, response.data.message);
        } else {
          this.context.commit(Mutations.SET_ERROR, [
            {
              error: "Error autenticando usuario: Error de conexión.",
            },
          ]);
        }
      });
  }

  @Action
  [Actions.LOGOUT]() {
    this.context.commit(Mutations.PURGE_AUTH);
  }

  @Action
  [Actions.REGISTER](credentials) {
    return ApiService.post("/users", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.REGISTER_COMPANY](credentials) {
    return ApiService.post("/companies", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_AUTH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.REGISTER_EMPLOYEE](credentials) {
    return ApiService.post("/employees", credentials)
      .then(({ data }) => {
        this.context.commit(Mutations.UPDATE_COMPANY_USERS, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.JOIN_TALENT_EMPLOYEE](credentials) {
    return ApiService.post("/employees/jointTalent", credentials)
      .then(({ data }) => {
        this.context.commit(
          Mutations.UPDATE_COMPANY_TALENT_EMPLOYEE,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.UPDATE_PUBLIC_COMPANY_TALENT_PROFILE](payload) {
    return ApiService.post(
      "/employees/UpdatePublicCompanyTalentProfile",
      payload,
    )
      .then(({ data }) => {
        this.context.commit(
          Mutations.UPDATE_COMPANY_TALENT_EMPLOYEE,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.FORGOT_PASSWORD](payload) {
    return ApiService.post("/password/recover", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.ACTIVATION_ACCOUNT](payload) {
    return ApiService.post(`/users/confirm/${payload.token}`, payload)
      .then((data) => {
        const token = data?.data?.data;
        if (token && token !== "") {
          this.context.commit(Mutations.SET_AUTH_TOKEN, token);
          this.context.commit(Mutations.UPDATE_CURRENT_ROLE, token);
        }
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.RESET_PASSWORD](payload) {
    return ApiService.post("/password/reset", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.RESET_EMAIL_TOKEN](payload) {
    return ApiService.post("/users/confirm/change/email", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CHANGE_PASSWORD](data) {
    return ApiService.post("users/reset/password", data)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CHANGE_EMAIL](data) {
    return ApiService.post("users/reset/email", data)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.DEACTIVATE_ACCOUNT](deactivate) {
    return ApiService.delete2("/users/user", deactivate)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_ERROR, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.VERIFY_AUTH]() {
    if (JwtService.getToken()) {
      ApiService.setHeader();
      ApiService.get(
        this.currentUserRole === "Talent" ? "/profile" : "/companies/company",
      )
        .then(({ data }) => {
          this.context.commit(Mutations.SET_AUTH, data.data);
          this.context.commit(Mutations.UPDATE_CURRENT_SUBSCRIPTION, data.data);
        })
        .catch(() => {
          this.context.commit(Mutations.SET_ERROR, "Not authenticated");
          this.context.commit(Mutations.PURGE_AUTH);
        });
    } else {
      this.context.commit(Mutations.PURGE_AUTH);
    }
  }

  @Action
  [Actions.GET_JOBS](request) {
    ApiService.setHeader();
    return ApiService.post("positions/fetch", request)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_JOBS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_NEW_TO_CYBER_JOBS](request) {
    ApiService.setHeader();
    return ApiService.post("positions/fetch", request)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_NEW_TO_CYBER_JOBS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_JOBS_META](payload) {
    return ApiService.get(
      `/marketplace/items/Position`,
      `?page=${payload.page}&meta=*&limit=${payload.limit}`,
    )
      .then((data) => {
        this.context.commit(Mutations.GET_JOBS_META_MUTATION, {
          jobs: data.data,
          page: payload.page,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_AWARD_META](payload) {
    return ApiService.get(
      `/marketplace/items/Position`,
      `?page=${payload.page}&meta=*&limit=${payload.limit}`,
    )
      .then((data) => {
        this.context.commit(Mutations.GET_AWARD_META_MUTATION, {
          jobs: data.data,
          page: payload.page,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  //UPDATE API TO ACCEPT "positions/company?skip=" + request.skip + "&take=" + request.take
  @Action
  [Actions.GET_COMPANY_JOBS](request) {
    ApiService.setHeader();
    return ApiService.post("positions/company", request)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_JOBS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.APPLY_JOB](jobData) {
    return ApiService.post("/talent/apply", jobData)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_APPLY_JOB, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.SAVE_JOB](jobData) {
    return ApiService.post("/talent/savePosition", jobData)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SAVE_JOB, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.CHANGE_COMMUNICATION_STATUS_APPLIED_TALENT](payload) {
    return ApiService.post(
      "/positions/UpdatedCommunicationStatusAppliedTalent",
      payload,
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SAVE_JOB, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.SAVE_TRAINING](trainingData) {
    return ApiService.post("/talent/saveTraining", trainingData)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SAVE_TRAINING, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.SAVE_CAREER](careerData) {
    return ApiService.post("/talent/saveCareer", careerData)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SAVE_CAREER, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.RECOMMEND_CAREER](careerData) {
    return ApiService.post("/companies/recommendedCareer/", careerData)
      .then(({ data }) => {
        this.context.commit(Mutations.RECOMMEND_CAREER_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.DELETECAREERPATH](careerData) {
    return ApiService.post("/talent/DeleteCareer", careerData)
      .then(({ data }) => {
        this.context.commit(Mutations.DELETE_CAREER_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.CHANGE_TRAINING_PROGRESS](trainingData) {
    return ApiService.post("/talent/changeTrainingProgress", trainingData)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SET_COMPLETE_TRAINING_MUTATION,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.UPDATE_COMMUNICATION_PREFERENCES](preferences) {
    return ApiService.post("/users/CommunicationPreferences", preferences)
      .then(({ data }) => {
        this.context.commit(Mutations.GET_COMMUNICATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.GET_COMMUNICATION_PREFERENCES]() {
    return ApiService.get("/users/Preferences")
      .then(({ data }) => {
        this.context.commit(Mutations.GET_COMMUNICATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.GET_CONNECTED_ACCOUNTS]() {
    return ApiService.get("/users/ConnectedAccounts")
      .then(({ data }) => {
        this.context.commit(
          Mutations.GET_CONNECTED_ACCOUNTS_MUTATION,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.GET_SAVED_JOBS](jobData) {
    return ApiService.get("/talent/favsPositions", jobData)
      .then(({ data }) => {
        this.context.commit(Mutations.GET_SAVED_JOBS_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.GET_SAVED_TRAININGS]() {
    return ApiService.get("/talent/favsTrainings")
      .then(({ data }) => {
        this.context.commit(Mutations.GET_SAVED_TRAINING_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.GET_SAVED_CAREERS]() {
    return ApiService.get("/talent/favsCareer")
      .then(({ data }) => {
        this.context.commit(Mutations.GET_SAVED_CAREER_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.GET_TRAININGS_WITH_PROGRESS]() {
    return ApiService.get("/talent/trainingProgress")
      .then(({ data }) => {
        this.context.commit(
          Mutations.GET_TRAININGS_WITH_PROGRESS_MUTATION,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.GET_APPLIED_JOBS](jobData) {
    return ApiService.get("/talent/appliedPositions", jobData)
      .then(({ data }) => {
        this.context.commit(Mutations.GET_APPLIED_JOBS_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.GET_AWARDS_NOMINATED](awardName) {
    return ApiService.get("/marketplace/awardNominees", awardName)
      .then(({ data }) => {
        this.context.commit(Mutations.GET_AWARDS_NOMINATED_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.SAVE_VOTE_AWARDSNOMINEES](data) {
    return ApiService.post("/marketplace/voteAwardNominee", data)
      .then(({ data }) => {
        this.context.commit(
          Mutations.SAVE_VOTE_AWARDSNOMINEES_MUTATION,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.EDIT_TRAINING_LEVEL_CAREER_PATH](trainingData) {
    return ApiService.post(
      "/marketplace/EditTrainingLevelCareerPath",
      trainingData,
    )
      .then(({ data }) => {
        debugger;
        this.context.commit(
          Mutations.EDIT_TRAINING_LEVEL_CAREER_PATH_MUTATION,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }
  @Action
  [Actions.EDIT_TRAINING_LEVEL_CAREER_PATH_COMPANY](trainingData) {
    return ApiService.post(
      "/marketplace/EditTrainingLevelCareerPath",
      trainingData,
    )
      .then(({ data }) => {
        debugger;
        this.context.commit(
          Mutations.EDIT_TRAINING_LEVEL_CAREER_PATH_COMPANY_MUTATION,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.CREATE_CAREER_PATH](trainingData) {
    return ApiService.post("/marketplace/createCareerPath", trainingData)
      .then(({ data }) => {
        debugger;
        this.context.commit(Mutations.CREATE_CAREER_PATH_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.CREATE_CAREER_PATH_COMPANY](trainingData) {
    return ApiService.post("/marketplace/createCareerPath", trainingData)
      .then(({ data }) => {
        debugger;
        this.context.commit(
          Mutations.CREATE_CAREER_PATH_COMPANY_MUTATION,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.GET_APPLIED_JOBS_COMPANY](companyId) {
    return ApiService.get(`positions/appliedPositions/${companyId}`)
      .then(({ data }) => {
        this.context.commit(
          Mutations.GET_APPLIED_JOBS_COMPANY_MUTATION,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data);
      });
  }

  @Action
  [Actions.GET_APPLIED_TALENT_JOBS_COMPANY](appliedTalents) {
    return ApiService.post("positions/appliedTalentPositions", appliedTalents)
      .then((data) => {
        this.context.commit(
          Mutations.VIEW_APPLIED_TALENTS_JOB_MUTATION,
          data.data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.TAKE_TRAINING_REQUEST](trainingData) {
    return ApiService.post("/marketplace/takeTraining", trainingData)
      .then()
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.TAKE_SUBSCRIPTION_REQUEST](Subscription) {
    return ApiService.post("/companies/takeSubscription", Subscription)
      .then()
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_JOB_DETAILS](positionId) {
    return ApiService.get(`positions/${positionId}`)
      .then(({ data }) => {
        this.context.commit(Mutations.CREATE_JOB_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CREATE_JOB](job) {
    return ApiService.post("/positions", job)
      .then(({ data }) => {
        this.context.commit(Mutations.CREATE_JOB_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.WORK_CATEGORY]() {
    return ApiService.get("/workforceframework/categories")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WORK_CATEGORY, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.WORK_SPECIALITY](categories) {
    return ApiService.post(
      "/workforceframework/categories/specialties",
      categories,
    )
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WORK_SPECIALTY, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.WORK_TASK](specialties) {
    return ApiService.post("/workforceframework/specialties/tasks", specialties)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WORK_TASKS, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.WORK_ROLE](tasks) {
    return ApiService.post("/workforceframework/tasks/roles", tasks)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_WORK_ROLES, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.SCORE_CAREER_PATH](data) {
    return ApiService.post("/marketplace/careerscore", data)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CAREER_PATH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.TALENT_SCORE_CAREER_PATH](data) {
    return ApiService.post("/marketplace/talentCareerscore", data)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CAREER_PATH, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.SCORE_CAREER_PATH_EMPLOYEE](data) {
    return ApiService.post("companies/employeeScoreByCareer/", data)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CAREER_PATH_EMPLOYEE, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_CAREER_TRAININGS](careerId) {
    return ApiService.get(`marketplace/careers/${careerId.careerId}`)
      .then(({ data }) => {
        this.context.commit(
          Mutations.TRAININGS_CAREER_PATH_COMPLETE,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CAREERS](payload) {
    debugger;
    return ApiService.post("/marketplace/careers", payload)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_CAREERS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_PICTURE_PROFILE](picture) {
    return ApiService.post("/azurefile/uploadfile", picture)
      .then(({ data }) => {
        this.context.commit(Mutations.UPDATE_PROFILE_MUTATION, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_PROFILE](profile) {
    return ApiService.put("/profile", profile)
      .then(({ data }) => {
        this.context.commit(Mutations.UPDATE_PROFILE_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.UPDATE_PROFILE_PDF](picture) {
    return ApiService.post("/azurefile/uploadfilepdf", picture)
      .then(({ data }) => {
        debugger;
        this.context.commit(Mutations.UPDATE_PDF_MUTATION, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_COMPANY](company) {
    return ApiService.put("/companies", company)
      .then(({ data }) => {
        this.context.commit(Mutations.UPDATE_PROFILE_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_WORK_EXPERIENCE_ITEM](experienceData) {
    // update the state
    this.context.commit(
      Mutations.UPDATE_WORK_EXPERIENCE_ITEM_MUTATION,
      experienceData,
    );
    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Action
  [Actions.DELETE_WORK_EXPERIENCE_ITEM](experienceData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_WORK_EXPERIENCE_ITEM_MUTATION,
      experienceData,
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Action
  [Actions.DELETE_COMPANY_USERS](employeeId) {
    return ApiService.delete(`employees/${employeeId}`)
      .then(() => {
        this.context.commit(
          Mutations.DELETE_COMPANY_USERS_MUTATION,
          employeeId,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.DELETE_COMPANY_TALENTS](employeeId) {
    return ApiService.delete(`employees/talent/${employeeId}`)
      .then(() => {
        this.context.commit(
          Mutations.DELETE_COMPANY_TALENT_MUTATION,
          employeeId,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.DELETE_COMPANY_RELATED_TALENTS](employeeId) {
    return ApiService.delete(`employees/unlinkCompany/${employeeId}`)
      .then(() => {
        this.context.commit(
          Mutations.DELETE_COMPANY_RELATED_TALENT_MUTATION,
          employeeId,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.CONFIRM_JOIN_TALENT_COMPANY](payload) {
    return ApiService.post("employees/confirmTalentLink", payload)
      .then((data) => {
        this.context.commit(
          Mutations.CONFIRM_JOIN_TALENT_COMPANY_MUTATION,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.CONFIRM_JOIN_AWARD](payload) {
    return ApiService.post("/marketplace/confirmAwardLink", payload)
      .then((data) => {
        this.context.commit(Mutations.CONFIRM_JOIN_AWARD_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.CONFIRM_JOIN_NEW_TALENT_COMPANY](payload) {
    return ApiService.post("employees/confirmTalentLink", payload)
      .then(() => {
        this.context.commit(Mutations.SET_ERROR, {});
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.UPDATE_EDUCATION_ITEM](educationData) {
    // update the state
    this.context.commit(
      Mutations.UPDATE_EDUCATION_ITEM_MUTATION,
      educationData,
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.DELETE_EDUCATION_ITEM](educationData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_EDUCATION_ITEM_MUTATION,
      educationData,
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Action
  [Actions.UPDATE_CERTIFICATION_ITEM](certificationData) {
    // update the state
    this.context.commit(
      Mutations.UPDATE_CERTIFICATION_ITEM_MUTATION,
      certificationData,
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Action
  [Actions.DELETE_CERTIFICATION_ITEM](certificationData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_CERTIFICATION_ITEM_MUTATION,
      certificationData,
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Action
  [Actions.DELETE_JOB](positionId) {
    return ApiService.delete(`positions/${positionId}`)
      .then(() => {
        this.context.commit(Mutations.DELETE_JOB_MUTATION, positionId);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.UPDATE_COURSE_ITEM](courseData) {
    // update the state
    this.context.commit(Mutations.UPDATE_COURSE_ITEM_MUTATION, courseData);

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.DELETE_COURSE_ITEM](courseData) {
    // update the state
    this.context.commit(Mutations.DELETE_COURSE_ITEM_MUTATION, courseData);

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.UPDATE_LANGUAGE_ITEM](languageData) {
    // update the state
    this.context.commit(Mutations.UPDATE_LANGUAGE_ITEM_MUTATION, languageData);

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.DELETE_LANGUAGE_ITEM](languageData) {
    // update the state
    this.context.commit(Mutations.DELETE_LANGUAGE_ITEM_MUTATION, languageData);

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.UPDATE_RECOMMENDATION_ITEM](recommendationData) {
    // update the state
    this.context.commit(
      Mutations.UPDATE_RECOMMENDATION_ITEM_MUTATION,
      recommendationData,
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.DELETE_RECOMMENDATION_ITEM](recommendationData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_RECOMMENDATION_ITEM_MUTATION,
      recommendationData,
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }
  @Action
  [Actions.UPDATE_JOB_NIST_NICE](tasks) {
    this.job.workTasks = tasks;
    this.context.dispatch(Actions.UPDATE_JOB, this.job);
  }
  @Action
  [Actions.UPDATE_PROFILE_NIST_NICE](tasks) {
    this.user.workSpecialties = this.specialities
      .filter((item) => item.selected)
      .map((item) => item.id);
    this.user.workCategories = this.categories
      .filter((item) => item.selected)
      .map((item) => item.id);
    this.user.workTasks = tasks;
    this.user.isBegginer = false;
    this.context.dispatch(Actions.UPDATE_PROFILE, this.user);
  }

  @Mutation
  [Mutations.UPDATE_NISTNICE_SPECIALITY](item) {
    console.debug("UPDATE_NISTNICE_SPECIALITY:", item, this.specialities);
    const idx = this.specialities.findIndex((entity) => entity.id === item.id);
    if (idx > -1) {
      this.specialities[idx] = { ...item };
    }
  }

  @Mutation
  [Mutations.UPDATE_NISTNICE_TASK](item) {
    console.debug("UPDATE_NISTNICE_TASK:", item, this.tasks);
    const idx = this.tasks.findIndex((entity) => entity.id === item.id);
    if (idx > -1) {
      this.tasks[idx] = { ...item };
    }
  }

  @Mutation
  [Mutations.MREGE_NIST_NICE](payload) {
    console.debug("MREGE_NIST_NICE:", payload);
    if (payload.data && payload.data.length > 0) {
      payload.data.forEach((id) => {
        const idx = this[payload.stateName].findIndex(
          (entity) => entity.id === id,
        );
        if (idx > -1) {
          this[payload.stateName][idx].selected = true;
        }
      });
    }
  }

  @Action
  [Actions.DELETE_PICTURE_PROFILE]() {
    return ApiService.delete("/azurefile/deletefile").catch(() => {
      this.context.commit(Mutations.SET_ERROR, "Not task");
    });
  }
  @Action
  [Actions.UPDATE_JOB](job) {
    return ApiService.put("/positions", job)
      .then(({ data }) => {
        this.context.commit(Mutations.CREATE_JOB_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.ADD_JOB_CERTIFICATION_ITEM](certificationData) {
    // update the state
    this.context.commit(
      Mutations.ADD_JOB_CERTIFICATION_ITEM_MUTATION,
      certificationData,
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_JOB, this.job);
  }

  @Action
  [Actions.DELETE_JOB_CERTIFICATION_ITEM](certificationData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_JOB_CERTIFICATION_ITEM_MUTATION,
      certificationData,
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_JOB, this.job);
  }
  @Action
  [Actions.ADD_JOB_LANGUAGE_ITEM](languageData) {
    // update the state
    this.context.commit(Mutations.ADD_JOB_LANGUAGE_ITEM_MUTATION, languageData);

    // update on API
    this.context.dispatch(Actions.UPDATE_JOB, this.job);
  }
  @Action
  [Actions.DELETE_JOB_LANGUAGE_ITEM](languageData) {
    // update the state
    this.context.commit(
      Mutations.DELETE_JOB_LANGUAGE_ITEM_MUTATION,
      languageData,
    );

    // update on API
    this.context.dispatch(Actions.UPDATE_JOB, this.job);
  }
  @Action
  [Actions.GET_TOP_TALENT_JOB](payload) {
    debugger;
    // update the state
    return ApiService.post("positions/company/toptalent", payload)
      .then((data) => {
        this.context.commit(Mutations.GET_TOP_TALENT_JOB_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.CYBERTEAM_COMPANY_TALENTS](companyId) {
    // update the state
    return ApiService.get(`companies/companyTalents/${companyId}`)
      .then((data) => {
        this.context.commit(
          Mutations.CYBERTEAM_COMPANY_TALENTS_MUTATION,
          data.data,
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_TOP_TALENT_JOB_FILTER](payload) {
    // update the state
    return ApiService.post("positions/company/TopTalentFilter", payload)
      .then((data) => {
        this.context.commit(Mutations.GET_TOP_TALENT_JOB_MUTATION, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_TALENT_LIST](filters) {
    return ApiService.post("/talent/fetch", filters)
      .then((data) => {
        this.context.commit(Mutations.GET_TALENT_LIST_MUTATION, data.data);
        return data;
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response);
        return response;
      });
  }
  @Action
  [Actions.CONTACT_TALENT](message) {
    return ApiService.post("/companies/contact/talent", message).catch(
      ({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      },
    );
  }
  @Action
  [Actions.GET_CURRENT_TALENT_DETAILS](talentId) {
    return ApiService.get(`talent/${talentId}`)
      .then((data) => {
        this.context.commit(
          Mutations.UPDATE_CURRENT_TALENT_DETAILS,
          data.data?.data,
        );
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response);
      });
  }
  @Action
  [Actions.GET_CURRENT_SUBSCRIPTION]() {
    return ApiService.get("/subscription")
      .then((data) => {
        this.context.commit(
          Mutations.GET_CURRENT_SUBSCRIPTION_MUTATION,
          data.data.data,
        );
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response);
      });
  }
  @Action
  [Actions.GET_SUBSCRIPTION_URL](data) {
    return ApiService.post("/webhook/checkout", data)
      .then((data) => {
        this.context.commit(Mutations.GET_SUBSCRIPTION_URL_MUTATION, data.data);
        return data.data;
      })
      .catch((response) => {
        this.context.commit(Mutations.SET_ERROR, response);
        return response;
      });
  }

  @Action
  [Actions.GET_TRAININGS_LIST](payload) {
    return ApiService.post("/marketplace/trainings", payload)
      .then((data) => {
        this.context.commit(Mutations.GET_TRAININGS_LIST_MUTATION, {
          trainings: data.data,
          page: payload.page,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_NEW_TO_CYBER_TRAININGS_LIST](payload) {
    return ApiService.post("/marketplace/trainings", payload)
      .then((data) => {
        this.context.commit(
          Mutations.GET_NEW_TO_CYBER_TRAININGS_LIST_MUTATION,
          {
            trainings: data.data,
            page: payload.page,
          },
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_TRAININGS_LIST_FILTER](payload) {
    const searchFilter = {
      _and: [
        {
          _or: [
            {
              title: {
                _icontains: payload.searchText,
              },
            },
            {
              description: {
                _icontains: payload.searchText,
              },
            },
          ],
        },
      ],
    };
    const providerFilter = {
      _and: [
        {
          provider: {
            _icontains: payload.providerText,
          },
        },
      ],
    };
    const levelFilter = {
      _and: [
        {
          Level: {
            _icontains: payload.levelText,
          },
        },
      ],
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const typeFilterOptions = [] as any;
    payload.typeSelected.forEach((item) => {
      typeFilterOptions.push({
        type: {
          _eq: item,
        },
      });
    });
    const typeFilter = {
      _and: [
        {
          _or: typeFilterOptions,
        },
      ],
    };
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const trainingFilterOptions = [] as any;
    if (payload.searchText && payload.searchText.trim() !== "") {
      trainingFilterOptions.push(searchFilter);
    }
    if (payload.providerText && payload.providerText.trim() !== "") {
      trainingFilterOptions.push(providerFilter);
    }

    if (payload.levelText && payload.levelText.trim() !== "") {
      trainingFilterOptions.push(levelFilter);
    }
    if (payload.typeSelected && payload.typeSelected.length > 0) {
      trainingFilterOptions.push(typeFilter);
    }
    const trainingFilter = {
      _and: [
        {
          _and: trainingFilterOptions,
        },
      ],
    };
    return ApiService.get(
      `/marketplace/items/training`,
      `?page=${payload.page}&meta=*&limit=${
        payload.limit
      }&filter=${JSON.stringify(trainingFilter)}&groupBy=${
        payload.groupBy
      }&aggregate=${payload.aggregate}`,
    )
      .then((data) => {
        this.context.commit(Mutations.GET_TRAININGS_LIST_FILTER_MUTATION, {
          trainings: data.data,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_BLOG_LIST_FILTER](payload) {
    let SearchCategoryID = {};
    if (payload.tag == "Seccuri Sessions") {
      SearchCategoryID = {
        status: "published",
        post_tags: { PostTags_id: 6 },
        post_category_id: payload.searchCategory,
      };
    } else {
      SearchCategoryID = {
        post_category_id: payload.searchCategory,
        reference: { _nempty: true },
      };
    }
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return ApiService.get(
      `/marketplace/items/Posts`,
      `?page=${payload.page}&meta=*&limit=${
        payload.limit
      }&filter=${JSON.stringify(SearchCategoryID)}&groupBy=${
        payload.groupBy
      }&aggregate=${payload.aggregate}&sort=${payload.sort}&field=${payload.field}`,
    )
      .then((data) => {
        this.context.commit(Mutations.GET_BLOGS_CMS_MUTATION, {
          trainings: data.data,
        });
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.GET_TRAININGS_LIST_FILTER_PROVIDER](payload) {
    return ApiService.get(
      `/marketplace/items/training`,
      `?groupBy=${payload.groupBy}`,
    )
      .then((data) => {
        this.context.commit(
          Mutations.GET_TRAININGS_LIST_FILTER_PROVIDER_MUTATION,
          {
            trainings: data.data,
          },
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_TRAININGS_LIST_FILTER_LEVEL](payload) {
    return ApiService.get(
      `/marketplace/items/training`,
      `?groupBy=${payload.groupBy}`,
    )
      .then((data) => {
        this.context.commit(
          Mutations.GET_TRAININGS_LIST_FILTER_LEVEL_MUTATION,
          {
            trainings: data.data,
          },
        );
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_TRAINING_DETAILS](parameters) {
    return ApiService.get(`/marketplace/trainings/${parameters.trainingId}`)
      .then((data) => {
        this.context.commit(Mutations.GET_TRAINING_DETAILS_MUTATION, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.GET_COURSE_DETAILS](courseId) {
    return ApiService.get(`/marketplace/items/training_courses/${courseId}`)
      .then((data) => {
        this.context.commit(Mutations.GET_COURSE_DETAILS_MUTATION, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.UPDATE_TOPTALENT](payload) {
    return ApiService.put("/positions/company/UpdateTopTalent", payload)
      .then()
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.SAVE_NOMINATED](nominatedData) {
    return ApiService.post("/talent/saveNominated", nominatedData)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_SAVE_NOMINATED, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
  @Action
  [Actions.loginMicrosoft]() {
    ApiService.setHeader();

    return ApiService.get("ExternalAuthentication")
      .then(({ data }) => {
        this.context.commit(Mutations.SET_JOBS, data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }

  @Action
  [Actions.IMPROVE_DESCRIPTION](query) {
    return ApiService.post("/seccuriAI/ImproveJobDescription", query)
      .then(({ data }) => {
        this.context.commit(Mutations.SET_IMPROVED_TEXT, data.data);
      })
      .catch(({ response }) => {
        this.context.commit(Mutations.SET_ERROR, response.data.errors);
      });
  }
}
